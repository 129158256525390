import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const configLoopback = {
  name: 'loopback',
  title: 'Disponibilité de stock',
  typeFrame: 'list',
  fields: [
    {
      name: 'articleName',
      title: 'Article',
    },
    {
      name: 'articleFamily',
      title: 'Famille article',
    },
    {
      name: 'departement',
    },
    {
      name: 'initialQty',
      title: 'Stock initial',
    },
    {
      name: 'cump',
      type: 'money',
      title: 'Prix unitaire',
    },
    {
      name: 'initialValue',
      title: 'Valeur stock initial',
    },
    {
      name: 'entryQty',
      title: 'Qte entrée',
    },
    {
      name: 'entryValue',
      title: 'Valeur entrée',
    },
    {
      name: 'outputQty',
      title: 'Stock sortie',
    },
    {
      name: 'outputValue',
      title: 'Valeur sortie',
    },
    {
      name: 'inStock',
      title: 'En stock',
    },
  ],
  actions: [],
  filterOptions: [
    {
      name: 'articleId',
      title: 'article',
      type: 'select',
      optionsTarget: 'articles',
    },
    {
      name: 'articleFamilyId',
      title: "Famille d'article",
      type: 'select',
      optionsTarget: 'articlefamillies',
    },
    {
      name: 'departementId',
      title: 'département',
      type: 'select',
      optionsTarget: 'departements',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (configLoopback);
