import {  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import 
  DateRangePickerValue
 from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import mainTheme from '../../../configs/mainTheme';

export default function RenderLoopbackcashflow (props) {
  let renderConfigs = props.renderFrameConfigs;

  const changeDateValue = (e, value = null) => {
    renderConfigs.handleSearchOption (e, value, 'date');
  };


  const data = renderConfigs?.fetchData || [];


  return (
    <div className="">
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />
      <div className="my-2 p-2">
        <DateRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>

        <div className="table-responsive">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead sx={{ bgcolor:mainTheme.color }}>
                      <TableRow>
                          <TableCell  sx={{ fontWeight:"bold" }}>
                              Moyen de paiement
                          </TableCell>  
                          <TableCell  sx={{ fontWeight:"bold" }}>
                              Solde initial
                          </TableCell>  
                          <TableCell  sx={{ fontWeight:"bold" }}>
                             Encaissement
                          </TableCell>  
                          <TableCell  sx={{ fontWeight:"bold" }}>
                             Décaissement
                          </TableCell> 
                          <TableCell  sx={{ fontWeight:"bold" }}>
                              Solde Final
                          </TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          {
                              data?.length > 0 ?  
                              data?.map(
                                (row) => {
                                  return <TableRow
                                                key={row?.payementType}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                              <TableCell component="td" scope={"paymentType"}>
                                                {row?.payementType}
                                              </TableCell>
                                              <TableCell component="td" scope={"soldeInitial"}>
                                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                                
                                                  }).format(  row?.soldeInitial)}
                                              </TableCell>
                                              <TableCell component="td" scope={"encaissement"}>
                                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                                
                                                  }).format(  row?.encaissement)}
                                              </TableCell>
                                              <TableCell component="td" scope={"decaissement"}>
                                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                  currency: 'XOF',
                                                  
                                                    }).format(  row?.decaissement)}
                                               
                                              </TableCell>
                                              <TableCell component="td" scope={"soldeFinal"}>
                                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                  currency: 'XOF',
                                                  
                                                    }).format(  row?.soldeFinal)}
                                              </TableCell>
                                          </TableRow>
                                   }
                              )
                            
                            :   <TableRow>
                                    <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                        Aucune ligne trouvée :)
                                    </TableCell>
                                </TableRow>
                          }
                         
                  </TableBody>
                </Table>
            </TableContainer>
        </div>
       
     
    </div>
  );
}
