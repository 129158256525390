import BaseBackComponent from '../base-back-component';

export default class ProductionsComponent extends BaseBackComponent {
  name = 'Productions';

  options = [
    {
      title: 'finishedProductDepots',
      source: 'depots',
      keys: ['name'],
      method: 'finishedProductDepots',
    },
    {
      title: 'rawMaterialDepots',
      keys: ['name'],
      source: 'depots',
      method: 'rawMaterialDepots',
    },
    {
      title: 'analytiqueplans',
      keys: ['analyticalCode', 'libelle'],
    },
    {
      title: 'products',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      productId: null,
      familyId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment (name, value) {
    if (name === 'product') {

      let products = this.state.originalOptionsData?.products;

      let currentProduct  = products.find(product => product.id === value);

      let disableState = true;
      
      currentProduct.recipeList.forEach(element => {
        if(element.rawMaterial?.articleFamily?.name?.toLowerCase()?.includes("produit fini")){
          disableState = false;
        }
      });

      await this.setState (state => ({
        formPayload: {
          ...state.formPayload,
          secondFinishProductDepot: {
            ...state.formPayload.secondFinishProductDepot,
            disable: disableState,
            value : null
          },
        },
      }));
    }
  }
}
