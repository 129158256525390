import SingleOrder from '../../../Clientorders/_layouts/single';
import BaseBackScreen from './../../../base-back-screen';
import config from './config';

export default class CustomerOrderScreen extends BaseBackScreen {
  name = 'customer-order';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleOrder,
  };
}
