import BaseBackComponent from '../base-back-component';
import ArticlesService from './../../../external-communication/services/articles.service';

export default class ArticlesComponent extends BaseBackComponent {
  name = 'Articles';

  options = [  
    {
      title: 'articlesubfamillies',
      keys: ['name'],
    },
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
    {
      title: 'units',
      keys: ['libelle'],
    },
    {
      title: 'departements',
      keys: ['name'],
    },
    {
      title: 'depots',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      code : null,
      name : null,
      subFamilyId : null,
      familyId : null,
      departementId : null,
    }
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      unit : {value : data?.unit?.value?.id},
      articleFamilyId : {value : data?.articleFamily?.value?.id},
      articleSubFamilyId : {value : data?.articleSubFamily?.value?.id},
      departementId : {value : data?.departement?.value?.id}
    };
    console.log(data)
    return data;
  }

  constructor (props) {
    super (props);
    this.loadArticleDepots = this.loadArticleDepots.bind (this);
    this.articleClosed = this.articleClosed.bind(this);
    this.stockAddFormHandleField = this.stockAddFormHandleField.bind(this);
    this.stockAddFormSubmit = this.stockAddFormSubmit.bind(this);
    this.resetAddStockFormPayload = this.resetAddStockFormPayload.bind(this);
    this.loadDepots = this.loadDepots.bind(this);


    this.state = {
      ...this.state,
      loadArticleDepots : this.loadArticleDepots,
      articleClosed : this.articleClosed,
      closedArticles : [],
      depots : [],
      articleName : null,
      articleUnit : null,
      stockAddForm : {
        payLoad : {},
        handleForm : this.stockAddFormHandleField,
        submitForm : this.stockAddFormSubmit,
      },
      loadDepots : this.loadDepots
    }
  }

  async loadArticleDepots(articleId){
    const articlesResponse = await new ArticlesService().getInventory(articleId);

    if(articlesResponse.success){
      await this.setState({
        depots : articlesResponse?.data?.articleStock,
        articleName : articlesResponse?.data?.articleName,
        articleUnit : articlesResponse?.data?.articleUnit,
      })
    }
  }

  async articleClosed(articleId){
    const articlesClosedResponse = await new ArticlesService().getClosedInventory(articleId);

    if(articlesClosedResponse.success){
      await this.setState({
        closedArticles : articlesClosedResponse?.data
      })
    }
  }

  async stockAddFormHandleField(e, name = null, value = null){
      const targetName = name || e.target.name;

      await this.setState({
        stockAddForm : {
          ...this.state.stockAddForm,
          payLoad : {
            ...this.state.stockAddForm.payLoad,
            [targetName] : {
              ...this.state.stockAddForm.payLoad?.[targetName],
              value : value || e.target.value
            }
          }
        }
      })
  }

  async resetAddStockFormPayload(){
    await this.setState({
      stockAddForm :  {
        payLoad : {}
      }
    });
  }

  async stockAddFormSubmit(e,articleId){
    let credentials = this.state.stockAddForm.payLoad;
    credentials.articleId = {
      value : articleId
    };
    const requestAddStock = await new ArticlesService().createArticleStock(credentials);

    if(requestAddStock?.success){
      await this.setState({closeDialog : !this.state.closeDialog});
      await this.loadFormMessage('success',"Succès création de stock");
      await this.resetAddStockFormPayload();
    }else{
      await this.loadFormMessage('error',requestAddStock?.message || 'Echec création de stock',requestAddStock.errors);
    }
  }

  loadDepots(){
    return  this.state.options.find(option => option.title === "depots").options;
  }

  async otherHandleFieldTreatment (name, value) {
    
    if (name === 'articleFamilyId') {

      let families = this.state.originalOptionsData?.articlefamillies;

      let currentFamily  = families.find(family => family.id === value);

      let disableState = true;
      
      if(currentFamily?.name?.toLowerCase() === 'produit fini'){
        disableState = false;
      }

      await this.setState (state => ({
        formPayload: {
          ...state.formPayload,
          qtyToDestock: {
            ...state.formPayload.qtyToDestock,
            disable: disableState,
            value : 1
          },
        },
      }));
    }
  }

  async initPayload () { 
    await this.setState ({
      formPayload: {
        weight:{value : "0"},
        qtyToDestock:{value : "1",disable: true},
      },
    });
  }

  async resetPayload () {
    await this.setState ({
      singleData: {},
    });
    await this.initPayload ();
  }
}
