import {Fragment} from 'react';
import ChartBuilder from '../../../../../../vendor/builders/Chart';
import  DateTimeRangePickerValue  from '../../../../../../vendor/builders/Frame/_layouts/search/_layouts/dates/date-time';
import { defaultFormatChartValue } from '../../../../../../bootstrap/Helpers/utils';

export default function CAStatsBySubfamilyRender (props) {

  let renderConfigs = props.data;

  const changeDateValue = (e, value = null) => {
    renderConfigs?.screenActions.handleSearch (e, value, 'date');
  };

  const chartValue = defaultFormatChartValue(renderConfigs?.data);

  return (
    <Fragment>
      <div className="title text-white p-2">
        CHIFFRE D'AFFAIRE PAR SOUS FAMILLE D'ARTICLE
      </div>
      <div className="my-2 p-2">
        <DateTimeRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <ChartBuilder 
              dataset={chartValue?.dataset}
              series={[{ data : chartValue?.series }]} 
              xAxis={chartValue?.xAxis} 
              chartType="bar" 
          />
      </div>
    </Fragment>
  );
}
