import {
    Autocomplete,
    TextField,
} from '@mui/material';
import './style.css';

import SingleCardProduct from './single-product';
import React  from 'react';



class  Products extends React.Component{
    constructor(props){
      super(props);

      this.filterProducts = this.filterProducts.bind(this);
      this.buildSubfamiliesOptions = this.buildSubfamiliesOptions.bind(this);
      this.addToWishList = this.addToWishList.bind(this);

      this.state = {
        filteredProducts : [],
        subFamilies : [], 
        products : [],
        searchValues : {},
        size : 0,
        label : "",
        groupTitle : null
      } 
    }

    async componentDidMount(){
        const subFamilies = this.buildSubfamiliesOptions(this.props.products);
        await this.setState({
          filteredProducts : this.props.products,
          products : this.props.products,
          subFamilies : subFamilies,
          label : "",
          groupTitle : null
        })
    }

    async componentDidUpdate(prevProps, prevState){
      if(this.props.products.length !== this.state.size){
        if(this.props.products?.length > 0){
          const subFamilies = this.buildSubfamiliesOptions(this.props.products);
          await this.setState({
            filteredProducts : this.props.products,
            products : this.props.products,
            subFamilies : subFamilies,
            size : this.props.products.length,
            label : "",
            groupTitle : null
          })
        }
      }
        
    }

    filterProducts = (searchOptions) => {
      let searchValues = {
        ...this.state.searchValues,
        [searchOptions.key] : searchOptions.value
      };

      
      if(searchOptions.key === "groupTitle"){
        const indexFound = this.state.subFamilies.findIndex(item => item.value === searchOptions.value)
        
        if(indexFound !== -1){
          this.setState({
            [searchOptions.key] : this.state.subFamilies[indexFound]
          })
        }
      }else{
        this.setState({
          [searchOptions.key] : searchOptions.value
        })
      }
      
      

      let filterProducts = this.state.products;

      if(searchValues?.groupTitle  && searchValues?.label && searchValues?.label !== ""){
        filterProducts = filterProducts.filter(
          product => product.groupTitle.toLowerCase() === searchValues?.groupTitle.toLowerCase() 
            && product.label.toLowerCase().includes(searchValues.label.toLowerCase())
        );
      }else if(searchValues?.groupTitle){
        filterProducts = filterProducts.filter(
          product => product.groupTitle.toLowerCase() === searchValues?.groupTitle.toLowerCase() 
        );
      }else if(searchValues.label && searchValues?.label !== ""){
        filterProducts = filterProducts.filter(
          product => product.label.toLowerCase().includes(searchValues.label.toLowerCase())
        );
      }

      this.setState({
        searchValues : searchValues,
        filteredProducts : filterProducts
      })
    }

    buildSubfamiliesOptions = (products) => {
      let subFamilies = [];
      products.forEach(product => {
        if(subFamilies.findIndex(subFamily => subFamily.value.toUpperCase() === product.groupTitle.toUpperCase()) === -1){
          subFamilies.push({label : product.groupTitle.toUpperCase(), value : product.groupTitle.toUpperCase()})
        }
      });
      return subFamilies;
    }

    addToWishList =  (value) => {
      this.props.addToWishList(value);
      this.setState({
        label : "",
        groupTitle : null
      })      
    }

    render(){

      return (
        <div className="p-3">
          <div className='border-3 border-black border-bottom border-start border-end p-2'>
            <div className="title text-white p-3 text-start">
              SELECTIONNEZ LES PRODUITS
            </div>
            <div className='border-5 border-black'>
              <div className="col-12 d-flex justify-content-start my-3">
                    <Autocomplete
                        id="subFamily"
                        options={this.state.subFamilies}
                        onChange={(e,selectedOption) => {
                          this.filterProducts({key : "groupTitle", value : selectedOption?.value});
                        }}
                        getOptionLabel={option => option.label}
                        size="small"
                        fullWidth
                        renderInput={(params) => <TextField {...params} label={`Sous famille`} />}
                        value={this.state.groupTitle}
                    />
                    <TextField
                        size="small"
                        id="product"
                        label="Rechercher produit"
                        fullWidth
                        sx={{ ml : 2 }}
                        onChange={(e) => { 
                            this.filterProducts({key : "label", value : e.target.value,operator : "include"});
                          }
                        }
                        value={this.state.label}
                    />
              </div>
              <div className="overflow-auto">
                <div className='d-flex'>
                  {
                      this.state.filteredProducts.map (product => {
                          return <div key={product.value} className="mx-2 col-8 bg-secondary">
                                      <SingleCardProduct addToWishList={this.addToWishList} product={product}/>
                                  </div>
                      })
                  }
                
        
                </div>
              </div>
            </div>
          </div>     
        </div>
      );
    }
}


export default Products;
