import {Autocomplete, TextField} from '@mui/material';
import { useState, Fragment } from 'react';
import ChartBuilder from './../../../vendor/builders/Chart/index';

const currentYear = new Date ().getFullYear ();

const years = () => {
  let yearsCollection = [];
  for (let i = 2010; i <= currentYear; i++) {
    yearsCollection.push ({value: `${i}`,label : `${i}`});
  }
  return yearsCollection;
};

export default function RenderChargeevolution (props) {
  let renderConfigs = props.renderFrameConfigs;


  const elementIndex = years().findIndex(item => item.value  === currentYear)

  const [currentExercise,setCurrentExercise] = useState(elementIndex !== - 1 ? years()[elementIndex] : years()[years().length - 1]);


  const departments = renderConfigs?.options?.find(option => option.title === "departements")?.options || [];
 // const chargesCategories = renderConfigs?.options?.find(option => option.title === "charges")?.options || [];


  const changeYear = (e, value = null) => {
    if(value){
        renderConfigs.handleSearchOption(e,value?.value,"year");
    }else{
        renderConfigs.handleSearchOption(e,null,"year");
    }
    setCurrentExercise(value);
  }

  const changeDepartment = (e,value = null) => {
    if(value){
      renderConfigs.handleSearchOption(e,value?.value,"departementId");
    }else{
        renderConfigs.handleSearchOption(e,null,"departementId");
    }
    setDepartment(value);
  }


  const [department,setDepartment] = useState();


  let  chartValue =  defaultFormatChartValue(renderConfigs.fetchData)
  

  return (

    <Fragment>
    <div className="title text-white p-2">
    {renderConfigs?.title}
    </div>
    <div className="d-flex my-2 p-1">
          <Autocomplete
                id="year"
                options={years()}
                onChange={(e,value) => {
                    changeYear(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === currentExercise?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Exercice`} />}
                value={currentExercise}
            />
            <Autocomplete
                id="departementId"
                options={departments}
                onChange={(e,value) => {
                    changeDepartment(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === department?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Departement`} />}
                value={department}
            />
    </div>
    <div className='col-12 d-flex justify-content-center'>
      
          <ChartBuilder 
              dataset={chartValue?.dataset}
              series={[{ data : chartValue?.series }]} 
              xAxis={chartValue?.xAxis} 
              chartType="bar" 
          />
    </div>
   
  </Fragment>
  );
}


export function defaultFormatChartValue (donnees) {
  let series = [];
  let dataset = [];
  let xAxis = [];
  if(donnees){
    donnees.forEach(evolution => {
      let value = evolution.chargeAmount;
      xAxis.push(evolution.month);
      series.push (
        value
      )
    })
  }
  

  return {xAxis, series, dataset};
}