import BaseBackComponent from '../../../base-back-component';
import TransferScreen from './screen';
import WithdrawsService
  from './../../../../../external-communication/services/withdraws.service';

export default class TransferComponent extends BaseBackComponent {
  name = 'transfer';

  options = [
    {
      title: 'initialDepots',
      keys: ['name'],
      source: 'depots',
    },
    {
      title: 'finalDepots',
      keys: ['name'],
      source: 'depots',
    },
  ];

  state = {
    ...this.state,
    screen: TransferScreen,
    searchOptions: {
      ...this.state.searchOptions,
      transferNumber: null,
      date: null,
      initialDepotId: null,
      finalDepotId: null,
      state: null,
    },
    depotArticles: [],
  };

  async componentDidMount () {
    let status = '';

    let state = this.props.params.state;

    if (state === 'in wait') {
      status = 0;
    } else if (state === 'cancel') {
      status = 2;
    }

    await this.setState ({
      service: new WithdrawsService (),
      searchOptions: {
        ...this.state.searchOptions,
        state: status,
      },
    });
    this.initDataLoading ();
    this.loadOptions ();
  }
}
