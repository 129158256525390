import React from 'react';
import CustomerOrderComponent from '../customer-order';
import CustomerDeliverComponent from '../customer-deliver';
import { Button } from '@mui/material';
import SupplierOrderComponent from '../supplier-order';
import SupplierDeliverComponent from '../supplier-deliver';
import ProductionComponent from '../production';
import TransferComponent from '../transfer';
import SalesComponent from '../sales';

export default function DashboardDetail (props) {
    const detailData = props.detailData;


    let Component = null;
    
    switch(detailData?.componentName){
        case "customer-order" :  
                Component = CustomerOrderComponent;
            break;
        case "customer-deliver" : 
                Component = CustomerDeliverComponent;
              break;
        case "supplier-order" :  
                Component = SupplierOrderComponent;
            break;
        case "supplier-deliver" :  
                Component = SupplierDeliverComponent;
            break;
        case "production" :  
                Component = ProductionComponent;

                break;
        case "transfer" : 
                Component = TransferComponent;
        break;
        case "sales" :  
                Component = SalesComponent;
            break;

        default : break;
    }

    if(Component){
        return  (<div className="d-flex flex-column col-12">
                    <div className="d-flex justify-content-start col-12">
                        <Button onClick={props.handleCloseDialog} variant='contained' color="error">
                            RETOUR TABLEAU DE BORD
                        </Button>
                    </div>
                    <div className="col-12 d-flex flex-column justify-content-center card my-2 p-1">
                            <Component params={
                                {
                                    state : detailData?.status,
                                    date: detailData?.period
                                }
                            }/>
                    </div>
                </div>)
    }
    return null;

 
}
