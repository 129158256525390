import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './style.css';
import React from 'react';
import DisableInputBuilder
  from './../../../../../../vendor/builders/Form/DisableInput/index';
import defaultSale from '../../../../../../assets/images/sale.png';
import { Autocomplete, TextField } from '@mui/material';




export default class WishList extends React.Component{

    constructor(props){
      super(props);

      this.state = {
        wishList : [],
        products : [],
        customer : null
      }

      this.handleQtyChange = this.handleQtyChange.bind(this);
      this.handleClientChange = this.handleClientChange.bind(this);
      this.findDefaultClient = this.findDefaultClient.bind(this);
      this.putWishInWaitMode = this.putWishInWaitMode.bind(this);
      this.validWish = this.validWish.bind(this);
    }

    async componentDidMount(){
      const findDefaultClient = this.findDefaultClient(this.props.clients)
      await this.setState({
        customer : findDefaultClient
      })
    }


    async componentDidUpdate(prevProps){
      if(this.props.clients !== prevProps.clients){
        const findDefaultClient = this.findDefaultClient(this.props.clients)
          this.setState({
            customer : findDefaultClient
          })
      }
     
    }

    findDefaultClient(clients){
      let client = clients.find(clt => clt.label.trim().toLowerCase() === "divers");
      return client;
    }

    handleClientChange = async value => {
        await this.setState({
          customer : value
        })
    }
    

    handleQtyChange = (index, value) => {
      this.props.changeWishProductQty(index,value);
    }
  
    putWishInWaitMode(){
      const localCustomer = this.state.customer || this.findDefaultClient(this.props.clients);
      this.props.putWishInWaitMode({
        customer : {value : localCustomer.value}
      });
    }

    validWish(){
      const localCustomer = this.state.customer || this.findDefaultClient(this.props.clients);
      this.props.valid({
        customer : localCustomer
      });
    }


    render(){
      
      return    <div className="p-3">
                  <div className="border-3 border-black border-bottom border-start border-end p-1">
                  <div className="title text-white p-3 my-1">
                      <div className='row'>
                        <div className='col-6 d-flex justify-content-start'>
                          <h6 className='mx-1 mt-1'>
                          PRODUIT(s) SELECTIONNES
                          </h6> 
                        </div>

                        {
                          this.props.products.length > 0 ? <div className="col-6 d-flex justify-content-end">
                          <button
                            onClick={this.putWishInWaitMode}
                            className="btn btn-sm mx-1 btn-warning"
                          >
                            <FontAwesomeIcon icon="pause-circle"/>
                            <span className='mx-1'>Mettre en attente </span> 
                          </button>

                          <button
                            onClick={() => {this.props.emptyWishList()}}
                            className="btn btn-sm mx-1 btn-danger"
                          >
                            <FontAwesomeIcon icon="trash"/>
                            <span className='mx-1'> Vider le panier </span> 
                          </button>

                        </div> : null
                        }      
                          
                      </div>                    
                  </div>
                  {
                    this.props.products?.length > 0 
                    ?   <div className="card">
                          <div className="card-body">
                            <div className="col-12 border-5 border-black">
                              <div className="card-body p-2">
                                  <div className="col-12">
                                      <Autocomplete
                                          id="client"
                                          options={this.props.clients}
                                          onChange={(e,selectedOption) => {
                                              this.handleClientChange(selectedOption)
                                          }}
                                          getOptionLabel={option => option.label}
                                          size="small"
                                          fullWidth
                                          renderInput={(params) => <TextField {...params} label={`Client`} />}
                                          value={this.state.customer}
                                      />
                                  </div>
                                  <hr/>
                            </div>
                              <div className="table-responsive">
                                    <table className="table table-striped">
                                      <tbody>
                                        {this.props.products.map ((product, index) => (
                                          <tr key={product.value}>
                                            <td>
                                              <img
                                                src={product.imgLabelSrc || defaultSale}
                                                alt={product.label?.toUpperCase ()}
                                                className="rounded" width="50" height="50"
                                              />
                                            </td>
                                            <td>
                                              <DisableInputBuilder
                                                fieldInformation={{
                                                  id: 'product',
                                                  editableDisable: true,
                                                  name: 'product',
                                                  label: 'Produit',
                                                  defaultValue: {
                                                    value: product.label.toUpperCase(),
                                                  },
                                                  size: 'small',
                                                }}
                                              />
                                            </td>
                                            <td>
                                            <div className={`form-group mt-2 col-12`}>
                                            <TextField
                                                size="small"
                                                id="qty"
                                                type = "number"
                                                label="Quantité"
                                                fullWidth
                                                onChange={(e) => { 
                                                  this.handleQtyChange(index, e.target.value)
                                                  }
                                                }
                                                value= {product.qty}
                                                InputProps={{ inputProps: { min: 1 } }}
                                            />
                                             </div> 
                                          
                                              
                                            </td>
                                            <td className="text-center">
                                              <button
                                                onClick={e => {
                                                  this.props.removeToWishList (product.value);
                                                }}
                                                className="btn btn-sm btn-danger mt-3"
                                              >
                                                <FontAwesomeIcon icon="trash" />
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                        
                                      </tbody>
                                    </table> 
                                  
                                
                                
                              </div>
                            </div>
                            <div className="col mb-2">
                              <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                  <button  onClick={this.validWish} className="p-2 btn btn-sm btn-success text-uppercase mx-1">
                                     
                                      <span className='mx-1'>Valider </span>
                                      <FontAwesomeIcon icon="check-double"/>

                                  </button>
                              
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> : 
                      <div className="p-5 text-center text-danger">
                        <h5>Aucun produit dans le panier</h5>
                      </div>
                  }
                
                  </div>
                </div>
    }
}

