import { useRef } from 'react';
import './style.css';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mainTheme from '../../../../../configs/mainTheme';
import customerInfo from '../../../../../configs/customer';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function QuittanceRender (props) {

    const componentRef = useRef ();
    const onPrint = useReactToPrint ({
      content: () => componentRef.current,
    });
  

    const sellingInfo = props.data?.data?.rows;

    console.log("information sur client : ",sellingInfo)


  return (
    <div className="container">
         <div className="d-flex mb-2  border-bottom">
            <Button
            variant="outlined"
            sx={{color: 'white', bgcolor: 'green', mr: 1, mb:2}}
            startIcon={<FontAwesomeIcon icon="print" />}
            onClick={onPrint}
            >
            Imprimer
            </Button>
        </div>
      <div className="row m-0 justify-content-center">
        <div className="col-6 col-md-6 col-12 ps-md-6 p-0" ref={componentRef}>
          <div className="box-left">
          <div className="d-flex justify-content-center image-text">
            <span className="image">
              <img src={mainTheme.appLogo} alt="logo" className="logo" />
            </span>
          </div>
            <p className="fw-bold h8 text-center">
                {customerInfo?.name}
            </p>
            <p className="fw-bold h8 text-center">
                {customerInfo?.address}    
            </p>
            <p className="fw-bold h8 text-center">
                {customerInfo?.tel}
            </p>

            <div className="invoice-header my-1 p-1">
            <div className="row">
              <span className="col-3 py-3 fw-bold h8">Caisse</span>
              <span className="col-7 py-3 border fw-bold h8">{sellingInfo?.cashRegisterNumber?.toUpperCase()}</span>
              <span className="col-2 py-3 border fw-bold h8">{sellingInfo?.paymentType?.toUpperCase()}</span>
            </div>
            <div className="row my-2">
              <span className="col-3 py-3 fw-bold h8">Client</span>
              <span className="col-9 py-3 border h8 fw-bold">
                {sellingInfo?.order?.customer?.firstName +""+sellingInfo?.order?.customer?.lastName}
              </span>
            </div>
            <div className="row my-1">
            <span className="col-3 py-3 fw-bold h8">N°</span>
            <span className="col-9 py-3 border h8 fw-bold">{sellingInfo?.deliverNumber?.toUpperCase()}</span>
          </div>
          <div className="row mt-1 mb-2">
            <span className="col-3 py-3 fw-bold h8">Le </span>
            <span className="col-3 py-3 border h8 fw-bold">
              {moment(sellingInfo?.date).format("DD/MM/YYYY")}
            </span>
            <span className="col-1 py-3 fw-bold text-center h8"> à </span>
            <span className="col-3 py-3 border h8 fw-bold">
              {moment(sellingInfo?.date).format("HH:mm:ss")}
            </span>
          
          </div>
        
            </div>

            <div className="h8">
              <div className="table-responsive">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ "& th": {
                        color: "white",
                        backgroundColor: "black"
                        }}}>
                          <TableRow>
                              <TableCell sx={{ fontWeight:"bold" }}>DESI.</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>P.U.</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>QTE</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>TOTAL</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              sellingInfo?.articleDelivers?.length > 0 ?   sellingInfo?.articleDelivers?.map((row,index) => (
                                  <TableRow
                                      key={row.id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                          {row?.article?.name.toUpperCase()}
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice} 
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                          {row?.qtyDelivered} 
                                      </TableCell>
                                     
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice * row?.qtyDelivered}
                                      </TableCell>
                                  </TableRow>
                              )) : 
                                  <TableRow>
                                      <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                          Aucun article sur cette fiche de vente :)
                                      </TableCell>
                                  </TableRow>
                          }
                          
                      </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr/>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">REMISE</p>
                <p className="ms-auto">
                    {
                        Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.discount)
                    }
                </p>
              </div>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">TVA</p>
                <p className="ms-auto">
                    {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.totalcostHt * sellingInfo?.tvaValue / 100)
                                  }
                </p>
              </div>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">TOTAL TTC</p>
                <p className="ms-auto">
                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.netToPaid)
                                  }
                </p>
             
              </div>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">ESPECE</p>
                <p className="ms-auto">
                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.amountPaid)
                                  }
                </p>
             
              </div>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">MONNAIE A RENDRE</p>
                <p className="ms-auto">
                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.currency)
                                  }
                </p>
             
              </div>
              <div className="d-flex h7 mb-2 fw-bold">
                <p className="">MONNAIE RENDU</p>
                <p className="ms-auto">
                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.currency)
                                  }
                </p>
             
              </div>
              <hr/>
              <div className="h8 mt-3 fw-bold">
                <p className="text-center">
                MERCI DE VOTRE VISITE
                </p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}
