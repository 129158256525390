import Service from '../../vendor/bases/service';

export default class SupplierdeliveriesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'supplierdeliveries',
    });
  }

  async valid (id) {
    return await this.treatQuery (this.query.valid (id));
  }

  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }

  async unpaidInvoice (id) {
    return await this.treatQuery (this.query.getUnpaidInvoice (id));
  }
}
