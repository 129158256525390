import './single.css';
import {Fragment, useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import fiche from '../../../../../assets/images/fiche.jpg';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';
import moment from 'moment';

const SingleWithdrawsRender = props => {
  const withdrawInformation = props.data.rows;

 
  const componentRef = useRef ();
  

  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE DE TRANSFERT D'ARTICLES #{withdrawInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(withdrawInformation?.stockOutputNumber?.toUpperCase() || "")}</h5>
                            <small>Transfert d'article</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                {
                                        withdrawInformation?.validateDate ?  <div className="d-flex flex-column">
                                        <span className="articles">Date validation</span>
                                        <span className="number1">
                                            {moment(withdrawInformation?.validateDate).format("DD/MM/YYYY")}
                                        </span>
                                    </div> : null
                                }
                                {
                                        withdrawInformation?.cancelDate ?  <div className="d-flex flex-column">
                                        <span className="articles">Date d'annulation</span>
                                        <span className="number1">
                                            {moment(withdrawInformation?.cancelDate).format("DD/MM/YYYY")}
                                        </span>
                                    </div> : null
                                }
                                <div className="d-flex flex-column">
                                    <span className="articles">Depot d'origine</span>
                                    <span className="number1">
                                        {withdrawInformation?.initialDepotName?.toUpperCase() || ""}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Destination</span>
                                    <span className="number1">
                                        {withdrawInformation?.finalDepotName?.toUpperCase() || ""}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                ARTICLES(S) TRANSFERE(S)
                            </h6>
                            <hr/>
                        </div>
                        {
                            withdrawInformation?.articleTransferredList?.map(
                                (item,index) => {
                                    return <Fragment key={item.id}>
                                                 <div className="row">
                                                    <div className="col-sm-6">
                                                        <h6 className="mb-0">{item?.article?.name?.toUpperCase()}</h6>
                                                    </div>
                                                    <div className="col-sm-6 text-secondary">
                                                        {item?.qtyTransferred + " " +item?.article?.unit?.libelle}        
                                                    </div>
                                                </div>
                                                <hr />
                                            </Fragment>
                                }
                            )   
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleWithdrawsRender;
