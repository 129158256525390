import Service from '../../vendor/bases/service';

export default class UnitsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'units',
    });
  }
}
