import moment from 'moment';
import BaseBackComponent from '../base-back-component';

export default class LoopbackcashflowComponent extends BaseBackComponent {
  name = 'Loopbackcashflow';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: moment (new Date ()).format ('YYYY-MM-DD') +
        ',' +
        moment (new Date ()).format ('YYYY-MM-DD'),
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
