import BaseBackComponent from '../base-back-component';

export default class ClientordersComponent extends BaseBackComponent {
  name = 'Clientorders';

  options = [
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    },{
      title: 'products',
      keys: ['articleName'],
      optionValue: 'articleId',
      dataToGetForGroupTitle: ['subFamilyName'],
      imgTarget: 'image',
      source : "articles",
      method: 'articlesWithPricing',
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      state: null,
      customerId: null,
      orderNumber: null,
      date: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }


  async handleSubForm (index, name, value, currentPayLoad = null) {

    let totalCost = 0;
    if (name === 'articleId') {
      if(value && value !== ""){
        const singleArticle = this.state.originalOptionsData?.articles?.find(article => article.articleId === value);
        currentPayLoad[index].qty = {value : 1};
        currentPayLoad[index].cost = {value : singleArticle?.sellingPrice ?? 0};
        currentPayLoad[index].unitPrice = {value : singleArticle?.sellingPrice ?? 0};
        currentPayLoad[index].reductionOrIncreaseRate = {value : 0}
      }else{
        currentPayLoad[index].qty = {value : 1};
        currentPayLoad[index].cost = {value :  0};
        currentPayLoad[index].unitPrice = {value : 0};
        currentPayLoad[index].reductionOrIncreaseRate = {value : 0}
      }
    }
    
    if(name === 'qty'){
     const newCost  = value * currentPayLoad[index]?.unitPrice?.value;
     const ttc = newCost + (newCost * currentPayLoad[index]?.reductionOrIncreaseRate?.value) / 100;
     currentPayLoad[index].cost = {value : ttc};
    }

    if(name === 'reductionOrIncreaseRate'){
      const newCost  = currentPayLoad[index]?.cost?.value;

      const ttc = newCost + (newCost * value) / 100;
      
      currentPayLoad[index].cost = {value : ttc};
    }

    currentPayLoad.forEach(
      item => {
        totalCost += item?.cost?.value;
      }
    )


    await this.setState(
      (state) => ({
        totalCost : totalCost,
        formPayload : {
          ...state.formPayload,
          articleSoldList : {value : currentPayLoad},
          totalCost : {value : totalCost } 
        }
    }));

   
    this.amountActualize({totalProductCost : totalCost});
  }


  async otherHandleFieldTreatment(name,value){
    if(name === "advanceOnOrder"){
      this.amountActualize({[name] : value});
    }

    if(name === "customerId"){
      const singleCustomer = this.state.originalOptionsData?.clients?.find(client => client.id === value);

      console.log("client info",  singleCustomer);
    }
  }

  async amountActualize(params){

    let amountAdvanced = params?.advanceOnOrder || this.state.formPayload.advanceOnOrder?.value || 0;
    let totalProductsCost = params?.totalProductCost || this.getAllArticleCost();


    let totalCost = parseFloat(totalProductsCost) - parseFloat(amountAdvanced);
    
    await this.setState({
      formPayload : {
        ...this.state.formPayload,
        totalOrderCost : {value : `${totalCost}`},
      }
    });

  }

  getAllArticleCost(arrayVal = []){
    let oldCost = 0;
    
    if(arrayVal?.length > 0){
      arrayVal.forEach(item => {
        oldCost += item?.cost?.value
      })
    }else{
      this.state.formPayload?.articleOrderList?.value.forEach(
        item => {
          oldCost += item?.cost?.value
        }
      )
    }
  
    return oldCost;
  }

  async initPayload () {
    await this.setState ({
      formPayload: {
        totalOrderCost : {value : "0"}
      },
    });
  }

  async resetPayload () {
    await this.setState ({
      singleData: {},
    });
    await this.initPayload ();
  }

  otherSingleDataInit (data) {
   
    const articleList = data?.articleOrderedList.value.map(
      article => {
        return {
          articleId : {value : article.article.id},
          qty : {value : article.qty},
          cost : {value : article.unitPrice * article.qty},    
        }
      }
    ); 

    data = {
      ...data,
      customerId : {value : data?.customer?.value?.id},
      previsionnalDate : {value :  data?.previsionalDate?.value},
      articleOrderList : {value : articleList},
      totalOrderCost : {value : this.getAllArticleCost(articleList)}
    }
    return data;
  }
}
