import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const statsFrameConfig = {
  name: 'stats',
  title: 'Statistiques',
};

export default BaseConfiguration (statsFrameConfig);
