import {Fragment} from 'react';
import ChartBuilder from '../../../../../../vendor/builders/Chart';
import  DateTimeRangePickerValue  from '../../../../../../vendor/builders/Frame/_layouts/search/_layouts/dates/date-time';
import { defaultFormatChartValue } from '../../../../../../bootstrap/Helpers/utils';


export default function CAStatsBySalesWeekRender (props) {
  let renderConfigs = props.data;

  const changeDateValue = (e, value = null) => {
    renderConfigs?.screenActions.handleSearch (e, value, 'date');
  };

  const chartValue = defaultFormatChartValue(renderConfigs?.data);

  
  return (
    <Fragment>
      <div className="title text-white p-2">
        CHIFFRE D'AFFAIRE PAR JOUR DE LA SEMAINE
      </div>
      <div className="my-2 p-2">
        <DateTimeRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>

      <div className='col-12 d-flex justify-content-center'>
          <ChartBuilder
            dataset={chartValue?.dataset}
            chartType="bar"
            series={[{data  : chartValue?.series}]} 
            xAxis={chartValue?.xAxis}
        />
      </div>

     
    </Fragment>
  );
}
