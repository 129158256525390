import React, { useEffect, useState } from 'react';
import './form.css';

import Products from './products/index';
import WishList from './wishlist';
import SellRecap from './recap';
import ArticlesService from './../../../../../external-communication/services/articles.service';



function formatInOption(products = []){
    return products.map(
      product => {
        return {
          label : product.articleName,
          value : product.articleId,
          groupTitle : product.subFamilyName,
          imgLabelSrc: product.image,
          originalObject: product
        }
      }
    )
}

const ExportSellForm = (props) => {
  let [parameters,setParameters] = useState({products : [], clients : []});

  useEffect(
     () => {
     
      if(props.fields?.loadFieldOptions  && parameters.products.length <= 0 && parameters.clients.length <= 0){
        
        async function initOptions(){
          const products  = await new ArticlesService().articlesWithPricing();
          const clients  = props.fields?.loadFieldOptions?.find(option => option.title === "clients").options;
          const productsOptions = formatInOption(products.data)
          setParameters({
            clients : clients,
            products : productsOptions
          })
        } 
          initOptions();
      }
    },[parameters,props]
  )

  //console.log(formParams?.oldProducts)

  return  <SellFormCard products={parameters.products} clients={parameters.clients} {...props}/>
}


 class SellFormCard extends React.Component {
  products = [];
  
  constructor (props) {
    super (props);

    this.addOrRemoveToWishList = this.addOrRemoveToWishList.bind (this);
    this.updateListClick = this.updateListClick.bind (this);
    this.changeWishProductQty = this.changeWishProductQty.bind(this);
    this.validSellInformation = this.validSellInformation.bind(this);
    this.buildSelectedProductList = this.buildSelectedProductList.bind(this);
    this.validSellInformation = this.validSellInformation.bind(this);
    this.registerInWait = this.registerInWait.bind(this);
    this.emptyWishList = this.emptyWishList.bind(this);
    this.backToBuildInformation = this.backToBuildInformation.bind(this);
    this.proceedToSellRegistration = this.proceedToSellRegistration.bind(this);

    this.buildForUpdate = this.buildForUpdate.bind(this);

    this.state = {
      products: [],
      clients: [],
      wishProductList: [],
      productToSelectList: [],
      recapComponentVisible : false,
      buildComponentVisible : true,
      recapInfo : {}
    };
  }

  async componentDidMount () {
    await this.setState({
      products: this.props.products,
      clients : this.props.clients,
      wishProductList: [],
      productToSelectList: this.props.products,
    })
  }

  async componentDidUpdate(prevProps,prevState){

    if(this.props.products !== prevProps.products){

      await this.setState({
        products: this.props.products,
        clients : this.props.clients,
        wishProductList: [],
        productToSelectList: this.props.products,
      })

      this.buildForUpdate(this.props.dataValue?.articleSoldList?.value);

     }
     
  }

  async buildForUpdate(soldList = []){
    if(soldList.length > 0){
      let alreadyWishList =  [];
      let toSelectList = [];
      
      this.state.products?.forEach(
        product => {
            const foundProductInSold = soldList.find(article => (article?.article?.id || article?.value) === product.value);
  
            if(foundProductInSold){
              alreadyWishList.push({
                ...product,
                qty : foundProductInSold.qty,
                unitPrice : foundProductInSold.unitPrice
              })
            }else{
              toSelectList.push(product)
            }
        }
      )
  
      await this.setState({
        wishProductList: alreadyWishList,
        productToSelectList: toSelectList,
        products : alreadyWishList.concat(toSelectList)
    }
      );
    }
   
  }

  async updateListClick (productValue = null, qty = 1) {
    let newProductWishList = this.state.wishProductList.length > 0 ? this.state.wishProductList :  [];
    let newProductToSelectList = this.products;
    const products = this.state.productToSelectList.concat(this.state.wishProductList);

    if(productValue){
      const productFound = products.find(product =>  product.value === productValue);

      if(productFound){
        newProductWishList = this.addOrRemoveToWishList(productFound,qty);
        newProductToSelectList = this.addOrRemoveToProductToSelectList(productFound);
      } 
    }

    await this.setState({
          wishProductList: newProductWishList,
          productToSelectList: newProductToSelectList,
          products: newProductToSelectList.concat(newProductWishList)
      }
    );
    
  }

  addOrRemoveToWishList (productFound, qty = 1) {
    let newProductWishList = this.state.wishProductList;

    const foundInWishListIndex = newProductWishList.findIndex (
      product => product.value === productFound.value
    );

    foundInWishListIndex !== -1
      ? newProductWishList.splice (foundInWishListIndex, 1)
      : newProductWishList.push ({...productFound, qty : qty, unitPrice : productFound.originalObject.sellingPrice});

   return newProductWishList
  }

  addOrRemoveToProductToSelectList (productFound) {
    let newProductToSelectList = this.state.productToSelectList;

    const foundInSelectListIndex = newProductToSelectList.findIndex (
      product => product.value === productFound.value
    );

    foundInSelectListIndex !== -1
      ? newProductToSelectList.splice (foundInSelectListIndex, 1)
      : newProductToSelectList.push (productFound);

      
    return newProductToSelectList; 
  }

  async changeWishProductQty(index, value){
    let wishList = this.state.wishProductList;

    wishList[index].qty = value;

    await this.setState({wishProductList : wishList});
  }

  async validSellInformation(information){

    const moreInfo = this.buildSelectedProductList(this.state.wishProductList);

    await this.setState({
      recapComponentVisible : true,
      buildComponentVisible : false,
      recapInfo : {
        clientInformation : information.customer,
        selectedProduct : moreInfo.selectedList,
        totalAmount : moreInfo.totalAmount,
        productsListSize : moreInfo.selectedList.length,
        globalQty : moreInfo.globalQty
      }
    })
  }

  buildSelectedProductList(products){
    let selectedList = [];
    let totalAmount = 0;
    let globalQty = 0;

    selectedList = products.map(
      product => {
        const lineTotalCost = product.qty * product.unitPrice;
        totalAmount += lineTotalCost;
        globalQty += parseInt(product.qty);
        return {
          ...product,
          totalLineAmount : lineTotalCost 
        }
      }
    )

    return {
      selectedList : selectedList,
      totalAmount : totalAmount,
      globalQty : globalQty
    }
  }

  async backToBuildInformation(){

    await this.buildForUpdate(this.state.recapInfo.selectedProduct);

    await this.setState(
      (state) => ({
        ...state,
        recapComponentVisible : false,
        buildComponentVisible : true,
        recap: {}
      })
      )
  }

  proceedToSellRegistration(paymentInformation){
    let articleSoldList = this.formatToArticleSoldList(paymentInformation.validWishList);

    const localPayload = {
      amountPaid :  {value : paymentInformation.amountPaid},
      articleSoldList : {value : articleSoldList},
      customerId : {value : paymentInformation.customer},
      paymentType :  {value  : paymentInformation.paymentWay?.value},
      waiting : {value : 0},
      id : this.props.dataValue?.id
    }

    this.props.fields.onSubmitForm(null,localPayload?.id ? "edit" : "add",localPayload);
    this.emptyWishList();
  }

  registerInWait(information){

    let articleSoldList = this.formatToArticleSoldList(this.state.wishProductList);
    const localPayload = {
      amountPaid :  {value : 0},
      articleSoldList : {value : articleSoldList},
      customerId : {value : information.customer.value},
      paymentType :  {value  :0},
      waiting : {value : 1}
    }
    
    this.emptyWishList();
    this.props.fields.onSubmitForm(null,"add",localPayload);
    
  }

  formatToArticleSoldList(articles){
    return articles.map(
      article => {
        
        return {
          articleId : {value : article.value},
          qty : {value : article.qty},
        }

      }
    )

  }

  async emptyWishList(){
    
    const products = [...this.state.wishProductList].concat([...this.state.productToSelectList]);
    
    await this.setState({
      products: products,
      wishProductList: [],
      productToSelectList: products,
    })
  }

  render () {
  let formInformation = this.props;

    return  <div className="article-card-information">
              <div className="mt-2">
                <div className="article-content" >
                  <div className="p-2 text-center text-white article-title">
                    <h4>{formInformation?.title}</h4>
                  </div>

                  {
                    this.state.recapComponentVisible ? <SellRecap 
                        backToBuildInformation={this.backToBuildInformation}
                        proceedToSellRegistration={this.proceedToSellRegistration}
                        recapInformation={this.state.recapInfo}
                    /> : null 
                  }

                  {
                    this.state.buildComponentVisible ?    <div className="row">
                                                            <div className="col-5 p-2">
                                                              <Products addToWishList={this.updateListClick} products={this.state.productToSelectList}/>
                                                            </div>
                                                            <div className="col-7 p-2">
                                                              <WishList
                                                                clients = {this.state.clients}
                                                                putWishInWaitMode={this.registerInWait} 
                                                                emptyWishList = {this.emptyWishList}
                                                                valid={this.validSellInformation} 
                                                                changeWishProductQty={this.changeWishProductQty} 
                                                                removeToWishList={this.updateListClick} 
                                                                products={this.state.wishProductList}/>
                                                            </div> </div>: null 
                  }

                 
                </div>
              </div>
            </div>;
  }
}


export default ExportSellForm;
