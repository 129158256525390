import { Fragment } from "react";
import BarChartBuilder from "./bar";
import LineChartBuilder from "./linear";

const ChartBuilder = props => {

    let ChartComponent = null;

    switch(props?.chartType){
        case "bar": 
                ChartComponent = BarChartBuilder;
            break;
        case "line" : 
                ChartComponent = LineChartBuilder;
                break;
        default : break;
    }
    
    return  <Fragment>
                <ChartComponent {...props}/>
            </Fragment>
}

export default ChartBuilder;