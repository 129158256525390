import BaseBackScreen from '../base-back-screen';
import InventoryDialog from './_layouts/InventoryDialog';
import InventoryPaper from './_layouts/InventoryPaper';

export default class DepotsScreen extends BaseBackScreen {
    name = 'Depots';

    loadOtherDialogContent(actionName,title=null,dataId=null){
        const config = this.loadFrameContent();
        let otherContent = {};
        if(actionName === 'inventory'){
            this.loadDepotArticles(dataId);
            let data = {
                article : this.state.articles,
                depotName : this.state.depotName
            };  
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : InventoryDialog,
                size : config?.dialogsConfig?.inventoryDialog?.size || "sm",
                data : data
            }
        }else if(actionName === 'inventoryPaper'){
            this.loadDepotArticles(dataId);
            let data = {
                article : this.state.articles,
                depotName : this.state.depotName
            };  
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : InventoryPaper,
                size : config?.dialogsConfig?.inventoryDialog?.size || "sm",
                data : data
            }
        } 
        
        
        else{
            otherContent = {
                actionName : actionName,
                componentName :  title,
                dataReference : dataId,
                validAction :this.props.data?.screenActions?.validOtherAction 
            }
        }
        
        return otherContent;
    }

    otherUpdate(prevProps){
        if(this.props.data !== prevProps.data){
            if(this.props.data?.articles || this.props.data?.depotName){
                this.setState({
                    dialogInformation : {
                        ...this.state.dialogInformation,
                        dialogContent : {
                            ...this.state.dialogInformation.dialogContent,
                            data : {
                                ...this.state.dialogInformation?.dialogContent?.data,
                                articles : this.props.data?.articles,
                                depotName : this.props.data?.depotName,
                            }
                        }
                    }
                });
            }
           
        }
    }

    loadDepotArticles(id){
        this.props.data.loadDepotArticles(id);
    }
}
