import BaseBackComponent from '../base-back-component';

export default class ChargeorproductsComponent extends BaseBackComponent {
  options = [
    {
      title: 'accountingtypes',
      keys: ['libelle'],
    },
    {
      title : "analytiqueplans",
      keys: ['analyticalCode']
    },
    {
      title : 'accountingcategories',
      keys:['libelle']
    }
  ];

  name = "Chargeorproducts";

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      libelle : null,
      accountingTypeId : null,
    }
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }


  

  otherSingleDataInit (data) {
    data = {
      ...data,
      accountingTpeId : {value : data?.accountingType?.value?.id},
      accountingCategoryId : {value : data?.accountingCategory?.value?.id},
      analyticalPlanId : {value : data?.analyticalPlan?.value?.id},
    };
    return data;
  }
}
