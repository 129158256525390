import moment from 'moment';
import BaseBackComponent from '../../../../base-back-component';
import StatsByCAEvolutionRender from './render';

export default class StatsByCAEvolutionComponent extends BaseBackComponent {
  name = 'Sellsevolution';

  options = [
    {
      title: 'departements',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    screen: StatsByCAEvolutionRender,
    searchOptions: {
      ...this.state.searchOptions,
      year: moment (new Date ()).format ('YYYY'),
      departementId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
