import {
  BaseConfiguration,
} from '../../../../../vendor/base-component-class/config.class';

const salesFrameConfig = {
  name: 'sells',
  title: 'Vente directe',
  typeFrame: 'list',
  fields: [
    {
      name: 'sellingNumber',
      title: 'N° de vente',
    },
    {
      name: 'waiting',
      title: 'Statut',
      htmlElement: 'select',
      options: [
        {label: 'En attente', value: '1'},
        {label: 'Vente directe', value: '0'},
      ],
      col: 6,
    },
    {
      name: 'customerId',
      title: 'Client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
      col: 6,
    },
    {
      name: 'customer',
      title: 'Client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
    },
    {
      name: 'cashRegisterNumber',
      title: 'N° de caisse',
    },
    {
      name: 'date',
      title: 'Date vente',
      type: 'datetime',
    },
    {
      name: 'netToPaid',
      title: 'Coût total',
      type: 'money',
    },
    {
      name: 'paymentType',
      title: 'Moyen de paiement',
      htmlElement: 'select',
      col: '6',
      options: [
        {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
        {value: 'BANQUE', label: 'BANQUE'},
        {value: 'CASH', label: 'CASH'},
        {value: 'CREDIT', label: 'CREDIT'},
      ],
    },
    {
      name: 'amountPaid',
      title: 'Montant reçu',
      type: 'number',
      col: '6',
    },
    /*{
      name: 'discount',
      title: 'Montant reduction',
      htmlElement: 'disableInput',
      col: '4',
      type: 'number',
    },
    {
      name: 'tvaValue',
      title: 'Valeur TVA en %',
      type: 'number',
      htmlElement: 'disableInput',
      col: '4',
    },*/
    {
      name: 'currency',
      title: 'MONNAIE',
      type: 'money',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'totalSellCost',
      title: 'VENTE',
      type: 'money',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: '#DC143C', text: 'Annulé', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Enregistré'},
        2: {badgeColor: 'orange', text: 'En attente'},
      },
      type: 'state-multiple',
    },
  ],
  excludeToHeaders: [
    'articleSoldList',
    'amountPaid',
    'discount',
    'paymentType',
    'tvaValue',
    'currency',
    'totalSellCost',
    'customerId',
    'waiting',
  ],
  excludeToForms: [
    'sellingNumber',
    'date',
    'totalcostTtc',
    'cashRegisterNumber',
    'state',
    'customer',
    'netToPaid',
  ],
  filterOptions: [
    {
      name: 'date',
      type: 'interval-time',
    },
    {
      name: 'sellingNumber',
      title: 'N° de vente',
    },
    {
      name: 'userId',
      type: 'select',
      title: 'Caissier',
      optionsTarget: 'users',
    },
    {
      name: 'cashRegisterNumber',
      title: 'N° de caisse',
    },
    {
      name: 'customerId',
      type: 'select',
      title: 'Client',
      optionsTarget: 'clients',
    },
  ],
  dialogsConfig: {
    singleDialog: {
      size: 'md',
    },
    formDialog: {
      size: 'xl',
    },
  },
  formConfig: {
    addFormTitle: 'ENERGISTRER UNE VENTE',
    editFormTitle: "MODIFIER INFORMATION D'UNE VENTE",
    initValue: {
      currency: {value: 0},
      totalCost: {value: 0},
      tvaValue: {value: 0},
    },
  },
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'primary',
      icon: 'eye',
    },
    {
      name: 'quittance',
      title: 'Reçu',
      color: 'info',
      icon: 'paperclip',
      elementTest: 'state',
      value: 1,
      operator: 'equal',
    },
  ],
  //  componentBeforeRenderList: TotalAmountRender,
  hideBar: true,
  frameActions: {
    tableActions: [
      {
        frameType: 'list',
        actions: [],
      },
    ],
  },
};

export default BaseConfiguration (salesFrameConfig);
