import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const productionsFrameConfig = {
  name: 'productions',
  title: 'Production(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'analyticalPlanId',
      title: 'Code analytique',
      htmlElement: 'select',
      options: [],
      col: 6,
      dataServiceSource: 'analytiqueplans',
      selectDataValue: {
        name: 'analyticalPlanId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['analyticalCode', 'libelle'],
        },
      },
    },
    {
      name: 'analyticalPlan',
      title: 'Code analytique',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      isObject: true,
      fieldsSelected: ['analyticalCode', 'libelle'],
      dataServiceSource: 'analyticalplans',
    },
    {
      name: 'finishProductDepot',
      title: 'Dépôt final stockage',
      htmlElement: 'select',
      col: 6,
      options: [],
      dataServiceSource: 'finishedProductDepots',
      selectDataValue: {
        name: 'finishProductDepot',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'product',
      title: 'Recette',
      htmlElement: 'select',
      type: 'objectFetcher',
      isObject: true,
      fieldsSelected: ['name'],
      dataServiceSource: 'products',
      selectDataValue: {
        name: 'product',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'secondFinishProductDepot',
      title: 'Dépôt prélevement produit fini',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'finishedProductDepots',
      selectDataValue: {
        name: 'secondFinishProductDepot',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'rawMaterialDepot',
      title: 'Dépot matière première',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'rawMaterialDepots',
      selectDataValue: {
        name: 'finishProductDepot',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'date',
      type: 'date',
    },
    {
      name: 'qty',
      title: 'Quantité',
      type: 'number',
    },
    {
      name: 'volume',
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validée'},
        2: {badgeColor: '#DC143C', text: 'Annulée'},
      },
      type: 'state-multiple',
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  excludeToForms: ['analyticalPlan', 'date', 'state', 'volume'],
  excludeToHeaders: [
    'analyticalPlanId',
    'finishProductDepot',
    'rawMaterialDepot',
    'analyticalPlan',
    'secondFinishProductDepot',
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Validée',
          value: 1,
        },
        {
          label: 'Annulée',
          value: 2,
        },
      ],
    },
    {
      name: 'productId',
      title: 'Produit',
      type: 'select',
      optionsTarget: 'products',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (productionsFrameConfig);
