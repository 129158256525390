import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const sellsAndWasteByArticleFrameConfig = {
  name: 'sellsandwastebyarticle',
  title: 'VENTE(S) ET WASTE PAR ARTICLE',
  typeFrame: 'list',
  fields: [],
  hideBar: true,
  filterOptions: [
    {
      name: 'articleId',
      title: 'Article',
      type: 'select',
      optionsTarget: 'articles',
    },
  ],
};

export default BaseConfiguration (sellsAndWasteByArticleFrameConfig);
