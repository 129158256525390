import BaseBackScreen from '../base-back-screen';
import PConfigDialog from './_layouts/PConfigDialog';

export default class RolesScreen extends BaseBackScreen {
    name = 'Roles';

    state = {
        ...this.state,
        authorities : []
    }

    constructor(props){
        super(props);
        this.loadRoleAuthorities = this.loadRoleAuthorities.bind(this);
    }

    otherUpdate(prevProps){
        if(this.props.data !== prevProps.data){
            if(this.props.data.authorities !== prevProps.data.authorities){
                this.setState({
                    dialogInformation : {
                        ...this.state.dialogInformation,
                        dialogContent : {
                          ...this.state.dialogInformation.dialogContent,
                          data : {
                            ...this.state.dialogInformation.dialogContent.data,
                            authorities : this.props.data.authorities,
                          }
                        }
                    }
                 });
            }
            if(this.props.data.authoritiesCredentials !== prevProps.data.authoritiesCredentials){
                this.setState({
                    dialogInformation : {
                        ...this.state.dialogInformation,
                        dialogContent : {
                          ...this.state.dialogInformation.dialogContent,
                          data : {
                            ...this.state.dialogInformation.dialogContent.data,
                            roleAuthorities : this.props.data.authoritiesCredentials,
                          }
                        }
                    }
                 }); 
            }
        }
    }

    loadOtherDialogContent(actionName,title=null,dataId=null){
        let otherContent = {};
        if(actionName === 'config'){
            this.loadRoleAuthorities(dataId);
            let data = {
                resources : this.props.data.resources,
                authorities : this.state.authorities,
                roleAuthorities : this.state.currentRoleAuthorities,
                callAuthoritiesResources : this.props.data.callAuthoritiesResources,
                roleName :this.props.data.data.items.find(item => item.id === dataId).name,
                saveChanges : this.props.data.saveAuthoritiesChanges,
                handleAuthorities : this.props.data.handleAuthorities,
                checkAll : this.props.data.checkAll
            };  
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : PConfigDialog,
                data : data
            }
        } else{
            otherContent = {
                actionName : actionName,
                componentName :  title,
                dataReference : dataId,
                validAction :this.props.data?.screenActions?.validOtherAction 
            }
        }
        
        return otherContent;
    }

    loadRoleAuthorities(id){
        this.props.data.loadRoleAuthorities(id);
    }
}
