import './style.css';
import Quittance from './quittance';

export default function QuittanceRender (props) {

    const sellingInfo = props.data?.data?.rows;

  return (
    <Quittance sell={sellingInfo}/>
  )
}
