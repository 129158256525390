import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/material';
import './single.css';
import {useReactToPrint} from 'react-to-print';
import {useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import customerInfo from '../../../../../configs/customer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';

export default function SingleSellRender (props) {
  const componentRef = useRef ();
  const onPrint = useReactToPrint ({
    content: () => componentRef.current,
  });

  const sellingInfo = props.data?.data?.rows;

  console.log("information : ", props.data)


  return (
    <div className="supplier-card-information">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: 'green', mr: 1}}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>
      <div ref={componentRef} className="mt-2 p-2">
          <div className="d-flex justify-content-center image-text border-top">
            <span className="image">
              <img src={mainTheme.appLogo} alt="logo" className="logo" />
            </span>
          </div>
          <div className="invoice-header">
            <div className="row gutters text-center mb-1">
              <h6 className="invoice-logo fw-bold">
                {customerInfo?.name}
              </h6>
            </div>
            <div className="row gutters text-center mb-1">
              <h6 className="invoice-logo fw-bold">
              {customerInfo?.address}
              </h6>
            </div>
            <div className="row gutters text-center mb-1">
              <h6 className="invoice-logo fw-bold">
              {customerInfo?.tel}
              </h6>
            </div>
          </div>
          <div className="invoice-header my-2 p-3">
            <div className="row my-2">
              <span className="col-3 py-3 fw-bold">Client</span>
              <span className="col-9 py-3 border">{sellingInfo?.customer?.firstName +" "+sellingInfo?.customer?.lastName}</span>
            </div>
            <div className="row my-1">
            <span className="col-3 py-3 fw-bold">N°</span>
            <span className="col-9 py-3 border">{sellingInfo?.orderingNumber?.toUpperCase()}</span>
          </div>
          <div className="row mt-1 mb-2">
            <span className="col-3 py-3 fw-bold">Le </span>
            <span className="col-3 py-3 border">
              {moment(sellingInfo?.orderingDate).format("DD/MM/YYYY")}
            </span>
            <span className="col-1 py-3 fw-bold text-center"> à </span>
            <span className="col-3 py-3 border">
              {moment(sellingInfo?.orderingDate).format("HH:mm")}
            </span>
            <span className="col-1 py-3 fw-bold">
              N.A
            </span>
            <span className="col-1 py-3 fw-bold">
              ?
            </span>
          </div>
          </div>
          
        {/* Row start */}
        <div className="row gutters">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="table-responsive">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ "& th": {
                        color: "white",
                        backgroundColor: "black"
                        }}}>
                          <TableRow>
                              <TableCell sx={{ fontWeight:"bold" }}>DESIGNATION</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>PRIX U</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>QTE</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>TOTAL</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              sellingInfo?.articleOrderedList?.length > 0 ?   sellingInfo?.articleOrderedList?.map((row,index) => (
                                  <TableRow
                                      key={row.id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                          {row?.article?.name.toUpperCase()}
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice} 
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                          {row?.qtyDelivered} 
                                      </TableCell>
                                     
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice * row?.qtyDelivered}
                                      </TableCell>
                                  </TableRow>
                              )) : 
                                  <TableRow>
                                      <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                          Aucun article sur cette fiche de vente :)
                                      </TableCell>
                                  </TableRow>
                          }
                          
                      </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="my-2 border-top d-flex justify-content-end">
                <div className="col-6 d-flex justify-content-end">
                    <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td className="text-left text-muted">REMISE</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.discount)
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left text-muted">TVA</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.totalcostHt * sellingInfo?.tvaValue / 100)
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left text-muted">TOTAL TTC</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.totalcostTtc)
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left text-muted">ESPECE</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.amountPaid)
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left text-muted">MONNAIE A RENDRE</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.currency)
                                  }
                              </td>
                            </tr>
                            <tr>
                              <td className="text-left text-muted">MONNAIE RENDU</td>
                              <td className="fw-bold">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellingInfo?.currency)
                                  }
                              </td>
                            </tr>
                          </tbody>
                    </table>      
                </div>          
               
              </div>
            </div>
        </div>
        <div className="text-center my-2 text-muted">
          MERCI DE VOTRE VISITE
        </div>
        {/* Row end */}
      </div>
    </div>
  );
}
