import DefaultQuery from './../../vendor/bases/default.query';

export default class InventorydepotQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'inventorydepot',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'inventory-depot'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'inventory-depot'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'inventory-depot'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'inventory-depot'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'inventory-depot'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'inventory-depot')
    );
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`consolidate/${id}`, 'inventory-depot'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'inventory-depot'),
      {
        id: id,
      }
    );
  }
}
