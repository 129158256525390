import {useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './single.css';
import {Button} from '@mui/material';
import {useReactToPrint} from 'react-to-print';
import customerInfo from '../../../../../configs/customer';
import mainTheme from '../../../../../configs/mainTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';


const SingleOrder = props => {
  const componentRef = useRef ();

  const orderInformation = props.data.rows;
  

  let htCost = orderInformation?.orderAmount || 0;
 

  orderInformation?.articleOrderedList?.forEach(
    order => {
      htCost += order.qty * order?.unitPrice
    }
  )

  //const tvaCost =  htCost * appParams.tva;
  const ttcCost = htCost;


  const onPrint = useReactToPrint ({
    content: () => componentRef.current,
  });  

  return (
    <div className="p-2">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: 'green', mr: 1}}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>
      <div className="invoice-container" ref={componentRef}>
        <div className="invoice-header">
            <div className="row gutters text-center mb-2">
              <span className="invoice-logo">
                BON DE COMMANDE CLIENT
              </span>
            </div>
            <div className="mt-2 d-flex flex-column">
              <div className="d-flex">
                  <span>N° : {' '} </span>
                  <strong className="mx-1"> {orderInformation?.orderingNumber?.toUpperCase()}</strong>
              </div>
              <div className="d-flex ">
                  <span>Date : {' '} </span>
                  <strong className="mx-1">
                    {moment(orderInformation?.orderingDate).format("DD/MM/YYYY")}
                  </strong>
              </div>
              <div className="d-flex ">
                  <span>Date prévisionnelle de livraison: {' '} </span>
                  <strong className="mx-1">
                    {moment(orderInformation?.previsionalDate).format("DD/MM/YYYY")}
                  </strong>
              </div>
              <div className="d-flex flex-column">
                  <span>Commentaire : {' '} </span>
                  <strong>
                    {orderInformation?.comment}
                  </strong>
              </div>
            </div>
            {/* Row end */}
            {/* CUSTOMER AND SUPPLIER INFORMATION */}
            <div className="row gutters d-flex justify-content-space-between">
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    FOURNISSEUR
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="ml-1">{customerInfo.name}</strong>
                    <br />
                    Adresse :{' '}
                    <strong className="ml-1">{customerInfo.address}</strong>
                    <br />
                    Tel : <strong className="ml-1">{customerInfo.tel}</strong>
                  </address>
                </div>
              </div>
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    CLIENT
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="ml-1">
                      {orderInformation?.customer?.firstName +
                        ' ' +
                        orderInformation?.customer?.lastName}
                    </strong>
                    <br />
                    Adresse :
                    {' '}
                    <strong className="ml-1">
                      {orderInformation?.customer?.addresse || "Non fourni"}
                    </strong>
                    <br />
                    Tel :
                    {' '}
                    <strong className="ml-1">
                      {orderInformation?.customer?.tel}
                    </strong>
                  </address>
                </div>
              </div>
            </div>
            {/* CUSTOMER AND SUPPLIER INFORMATION END*/}
            <div className="invoice-body my-2">
                  {/* ARTICLES RENDER */}
                  <div className="table-responsive border-bottom ">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                          <TableHead sx={{ bgcolor:mainTheme.color }}>
                              <TableRow>
                                  <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Désignation</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Quantité</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Prix unitaire</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Total</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  orderInformation?.articleOrderedList?.length > 0 ?  orderInformation?.articleOrderedList?.map((row,index) => (
                                      <TableRow
                                          key={row.id}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                          <TableCell component="td" scope="index">
                                              {index+1}
                                          </TableCell>
                                          <TableCell component="td" scope="articleName">
                                              {row?.article?.name}
                                          </TableCell>
                                          <TableCell>
                                              x {row?.qty} 
                                          </TableCell>
                                          <TableCell>
                                            {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                                        currency: 'XOF',
                                                                        
                                                              }).format(row?.unitPrice)} 
                                          </TableCell>
                                          <TableCell>
                                            {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                      currency: 'XOF',
                                                      
                                            }).format(row?.cost)}
                                          </TableCell>
                                      </TableRow>
                                  )) : 
                                      <TableRow>
                                          <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                              Aucun article sur ce bon de commande :)
                                          </TableCell>
                                      </TableRow>
                              }
                              
                          </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {/* ARTICLES RENDER  END */}
                  <div className="my-3">
                    <div className="d-flex justify-content-end">
                      <p className="text-muted me-3">Montant HT : </p>
                      <span>{
                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                        currency: 'XOF',
                                        
                              }).format(htCost)
                            }</span>
                    </div>
                  
                    <div className="d-flex justify-content-end">
                      <p className="text-muted me-3">TVA : </p>
                      <span>{
                         Intl.NumberFormat('fr-FR',{ style: 'currency',
                         currency: 'XOF',
                         
                            }).format(0)
                        }</span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="text-muted me-3">Montant avancé : </p>
                      <span>{
                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                        currency: 'XOF',
                                        
                              }).format(orderInformation?.advanceOnOrder)
                            }</span>
                    </div>
                    <div className="d-flex justify-content-end p-3 bg-light border-top">
                      <h5 className="me-3">Montant TTC:</h5>
                      <h5 className="fw-bold">{
                      Intl.NumberFormat('fr-FR',{ style: 'currency',
                      currency: 'XOF',
                      
                         }).format(ttcCost - orderInformation?.advanceOnOrder)
                     }</h5>
                    </div>
                  </div>
            </div>
        </div>
              
        <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
        </div>
      </div>
    </div>
  );
};

export default SingleOrder;
