import DefaultQuery from '../../vendor/bases/default.query';

export default class CashflowmovementQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'cashflowmovement',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'cash-flow-movement'),
      params
    );
  }
}
