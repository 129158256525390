import {Card, CardContent} from '@mui/material';
import React, {Fragment} from 'react';
import FrameTitleLinked
  from '../../../vendor/builders/Frame/_layouts/title/title-linked';

import StatsByCA from './_layouts/by-ca';
import StatsBySales from './_layouts/by-sales';
import StatsByChargeOrProduct from './_layouts/by-charges-or-product';
import StatsByDebt from './_layouts/by-debt';

export default class RenderStats extends React.Component {
  state = {
    ...this.state,
    renderConfigs: this.props.renderFrameConfigs,
    activeBtnName : "caStats",
    defaultStatsComponent : StatsByCA 
  }

  constructor(props){
    super(props);
    this.filterStats = this.filterStats.bind(this);
  }

  filterStats = (e,value) => {
    
    let Component = null;

    switch(value){
      case "salesStats" : Component = StatsBySales; break;
      case "chargeOrProduct" : Component = StatsByChargeOrProduct; break;
      case "debt" : Component = StatsByDebt; break;
      default : Component = StatsByCA; break;
    }

    this.setState({
      activeBtnName : value,
      defaultStatsComponent : Component
    })
  } 


  render = () => {
    const CurrentComponent = this.state.defaultStatsComponent; 
    return (<Fragment>
              <FrameTitleLinked
                activeBtnName={this.state.activeBtnName}
                name={this.state.renderConfigs?.name}
                title={this.state.renderConfigs?.title}
                handleActionClick = {this.filterStats}
                actions={[
                  {
                    name : "caStats",
                    title: "Stats sur CA",
                  },
                  {
                    name : "salesStats",
                    title: "Stats de vente" 
                  },
                  {
                      name : "chargeOrProduct",
                    title: "Charges/Produits"
                  },
                  {
                    name : "debt",
                    title: "Créance"
                  }
                ]}
              />
                
              <Card sx={{width: '100%'}}>
                <CardContent>
                    <CurrentComponent/> 
                </CardContent>
              </Card>
            </Fragment>
          );
  };
}
