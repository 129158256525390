import BaseBackComponent from '../base-back-component';

export default class SuppliersComponent extends BaseBackComponent {
  name = 'Suppliers';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      lastName: null,
      firstName: null,
    },
  };

  options = [
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      articleFamilyId : {value : data?.articleFamilyId?.value},
      auxiliaryAccount : {value : data?.account?.value?.auxiliaryAccount},
      generalAccountNumber : {value : data?.account?.value?.accountNumber},
    };
    return data;
  }
}
