import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import mainTheme from "../../../configs/mainTheme";
import { capitalizeFirstLetter } from './../../../bootstrap/Helpers/utils';
import { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const StyledTable = props => {
    const headers = props.tableParams?.headers || [];
    const rows = props.tableParams?.rows || [];
    const actions = props.tableParams?.actions || [];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                 <TableContainer sx={{ maxHeight: 500,border: 1 }}>
                    <Table stickyHeader size={props.tableParams?.size  || 'small'}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor : mainTheme.mainColor, color : 'white'}}>
                                { headers.map(
                                    header => {
                                        return <StyledTableCell key={header.name}>
                                                  {capitalizeFirstLetter(header.title || header.name || "")}
                                                </StyledTableCell>
                                    }
                                )}
                                {
                                    actions.length > 0 ? 
                                    <StyledTableCell>
                                        Actions
                                    </StyledTableCell> : null
                                }
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map(
                                    (row,index) => <StyledTableRow  key={row?.id}>
                                                        {
                                                            headers.map(
                                                                header => <StyledTableCell >
                                                                                {row[header.name]}
                                                                            </StyledTableCell>
                                                            )
                                                        }

                                                        {
                                                            actions.length > 0 ? 
                                                            <StyledTableCell>
                                                                <StyledTableActions actions={actions}/>
                                                            </StyledTableCell> : null
                                                        }
                                                        
                                                </StyledTableRow>

                                )
                            }
                        </TableBody>
                    </Table>  
                 </TableContainer>
                 <TablePagination
                  rowsPerPageOptions={[5,10, 25,100]}
                  component="div"
                  count={rows?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
}

export default StyledTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
'&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
},
// hide last border
'&:last-child td, &:last-child th': {
    border: 0,
},
}));

const StyledTableActions = props => {

    const actions =  props.actions || [];
    const actionId = props.dataId;

    const handleAction = (e,name) => {
        props.handleAction(e,name,actionId)
    }

    return <Fragment>
            {
                actions?.map(
                    action => <Fragment key={action?.name}>
                    {
                        <button 
                            className={"btn btn-sm btn-"+action.btnColor + " mx-1 p-1 px-2 "}
                            onClick={(e) => {handleAction(e,action?.name)}}
                            name={action?.name}
                            id={actionId}
                            title={action?.title}
                    >
                        <FontAwesomeIcon icon={action?.icon}/>
                    </button> 
                    }
                   
                </Fragment>
                )
            }
    </Fragment>
    
    
}