import DefaultQuery from '../../vendor/bases/default.query';

export default class ArticlesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'articles',
    });
  }
  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async getCloseInventory (options) {
    return await this.getMethod (this.buildPath ('bouclage'), options);
  }

  async getArticleInventory (articleId) {
    return await this.getMethod (
      this.buildPath (`article-stock/${articleId}`, 'stocks')
    );
  }

  async onlyFinishedProducts () {
    return await this.getMethod (this.buildPath (`finished-product`));
  }

  async onlyFinishedProductsAndRawMaterials () {
    return await this.getMethod (
      this.buildPath (`finished-product-and-raw-material`)
    );
  }

  async excludeFinishedProducts () {
    return await this.getMethod (
      this.buildPath (`article-without-finished-product`)
    );
  }

  async onlyFinishedProductsAndNegoce () {
    return await this.getMethod (
      this.buildPath (`finished-product-and-negoce`)
    );
  }

  async onlyRawMaterials () {
    return await this.getMethod (this.buildPath (`raw-material`));
  }

  async getArticlePrice (articleId) {
    return await this.getMethod (this.buildPath (`${articleId}/price`));
  }

  async getArticlesWithPrice () {
    return await this.getMethod (this.buildPath (`article-to-sell`));
  }

  async createAddStock (credentials) {
    return await this.createMethod (
      this.buildPath (`create-stock`),
      credentials
    );
  }
}
