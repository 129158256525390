import BaseBackComponent from '../base-back-component';

export default class CashflowmovementComponent extends BaseBackComponent {
  name = 'Cashflowmovement';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      type: null,
      paymentMode: null,
      size: 10,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
