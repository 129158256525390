import BaseBackScreen from '../../../base-back-screen';
import config from './config';
import moment from 'moment';
import SingleCustomerInvoice from './single';

export default class SalesScreen extends BaseBackScreen {
  name = 'sales';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleCustomerInvoice,
  };

  openGoodDialog (event, name = null, id = null) {
    let actionName = name || event.target.name
    if(actionName === "export"){
      const config = this.loadFrameConfig();
      this.onExport(event,"export "+ config?.title+ ` ${moment(new Date()).format("DD/MM/YYY - HH:mm:ss")}`)
    }else{
      const dialogContent = this.getDialogContent(actionName, id);
      this.openDialog(dialogContent);
    }
  }
}
