import Service from '../../vendor/bases/service';

export default class CashregistersService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'cashregisters',
    });
  }

  close = async id => {
    return await this.treatQuery (this.query.close (id));
  };
}
