import {
  BaseConfiguration,
} from '../../../../../vendor/base-component-class/config.class';

const supplierDeliverFrameConfig = {
  name: 'supplierdeliveries',
  title: 'Réception(s) commande fournisseur',
  typeFrame: 'list',
  fields: [
    {
      name: 'deliveryDate',
      title: 'Date de livraisaon',
      type: 'date',
      col: 4,
    },
    {
      name: 'deliveryNumber',
      title: 'N° de livraison',
    },
    {
      name: 'supplierOrderId',
      title: 'N° de commande',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'supplierorders',
      isObject: true,
      fieldsSelected: ['supplierorders'],
      selectDataValue: {
        name: 'supplierorder',
        options: {
          valueOptions: ['id'],
          labelOptions: ['orderingNumber'],
        },
      },
    },
    {
      name: 'supplierOrder',
      title: 'N° commande',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'supplierorders',
      isObject: true,
      fieldsSelected: ['orderingNumber'],
    },
    {
      name: 'depotId',
      title: 'depot',
      col: 4,
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'depots',
      selectDataValue: {
        name: 'depot',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'depot',
      title: 'Stokage',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'depots',
      isObject: true,
      fieldsSelected: ['name'],
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validée'},
        2: {badgeColor: 'blue', text: 'Dépôtée'},
        3: {badgeColor: '#DC143C', text: 'Annulée'},
      },
      type: 'state-multiple',
    },
    {
      name: 'rawMaterialTodelivery',
      title: 'Article(s) à livrer',
      fields: [
        {
          name: 'articleName',
          title: 'Article',
          editableDisable: true,
          htmlElement: 'disableInput',
        },
        {
          name: 'qty',
          title: 'quantité',
          type: 'number',
          htmlElement: 'disableInput',
        },
        /*{
          name: 'qtyRest',
          title: 'qte restante',
          htmlElement: 'editable-label',
          inputType: 'number',
          editableDisable: true,
        },*/
        {
          name: 'expiryDate',
          title: 'Date expiration',
          type: 'date',
        },
      ],
      htmlElement: 'dynamicUnChangeForm',
    },
  ],
  excludeToHeaders: ['depotId', 'supplierOrderId', 'rawMaterialTodelivery'],
  excludeToForms: ['depot', 'state', 'supplierOrder', 'deliveryNumber'],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Validée',
          value: 1,
        },
        {
          label: 'Dépôtée',
          value: 2,
        },
        {
          label: 'Annulée',
          value: 3,
        },
      ],
    },
    {
      name: 'orderId',
      title: 'N° commande',
      type: 'select',
      optionsTarget: 'supplierorders',
    },
    {
      name: 'deliveryNumber',
      title: 'N° de livraison',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
  hideBar: true,
  frameActions: {
    tableActions: [
      {
        frameType: 'list',
        actions: [],
      },
    ],
  },
};

export default BaseConfiguration (supplierDeliverFrameConfig);
