import Service from '../../vendor/bases/service';

export default class ResourcesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'resources',
    });
  }

  async resourceAuthorities (resourceId) {
    return await this.treatQuery (this.query.resourceAuthorities (resourceId));
  }
}
