import BaseBackScreen from '../base-back-screen';
import Resume from './_layouts/resume';

export default class CashregistersScreen extends BaseBackScreen {
  name = 'Cashregisters';

  state = {
    ...this.state,
    cashRegisterInformation : null
  }


  constructor(props){
    super(props);
    this.loadResumeData = this.loadResumeData.bind(this);
  }

  loadOtherDialogContent(actionName,title=null,dataId=null){
      let otherContent = {};
      const config = this.loadFrameContent();
      if(actionName === 'resume'){
          this.loadResumeData(dataId);
          let data = {};  
          
          otherContent = {
              actionName : actionName,
              componentName :  title,
              pDialogRender : Resume,
              data : data,
              size : config?.dialogsConfig?.resumeDialog?.size || "sm"
          }
      }else{
          otherContent = {
              actionName : actionName,
              componentName :  title,
              dataReference : dataId,
              validAction :this.props.data?.screenActions?.validOtherAction 
          }
      }
      
      return otherContent;
  }

  loadResumeData(dataId){
    this.props.data.loadResumeData(dataId);
  }

  async otherUpdate(prevProps){
    if(this.props.data !== prevProps.data){
        if(this.props.data.cashRegisterInformation !== prevProps.data.cashRegisterInformation){
          this.setState({
            dialogInformation : {
                ...this.state.dialogInformation,
                dialogContent : {
                  ...this.state.dialogInformation.dialogContent,
                  data : {
                    ...this.state.dialogInformation.dialogContent.data,
                    cashRegisterInformation : this.props.data.cashRegisterInformation,
                  }
                }
            }
         });
        }
      }
  }
}
