import DefaultQuery from '../../vendor/bases/default.query';

export default class ArticleSubfamilliesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'articlessubfamillies',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'article-sub-families'),
      params
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'article-sub-families'),
      credentials
    );
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'article-sub-families'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'article-sub-families'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (
      this.buildPath (`${id}`, 'article-sub-families')
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'article-sub-families')
    );
  }
}
