import BaseBackComponent from '../base-back-component';
import SellsService
  from './../../../external-communication/services/sells.service';

export default class CashregistersComponent extends BaseBackComponent {
  name = 'Cashregisters';

  options = [
    {
      title: 'users',
      keys: ['firstName', 'lastName'],
    },
    {
      title: 'depots',
      keys: ['name'],
      method: 'finishedProductDepots',
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      userId: null,
      date: null,
      state: null,
      size: 10,
    },
    cashRegisterInformation: null,
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  constructor (props) {
    super (props);
    this.loadResumeData = this.loadResumeData.bind (this);
    this.state = {
      ...this.state,
      loadResumeData : this.loadResumeData
    }
  }

  async loadResumeData (cashRegisterId) {
    const resumeRequest = await new SellsService ().getCashRegisterSellsResume (
      cashRegisterId
    );


    if(resumeRequest?.success){
      this.setState ({
        cashRegisterInformation : resumeRequest?.data
      });
    }
  }
}
