import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const clientsFrameConfig = {
  name: 'clients',
  typeFrame: 'list',
  fields: [
    /*{
      name: 'account',
      title: 'N° de compte auxilliaire',
      fieldToTake: ['account'],
      type: 'objectFetcher',
      fieldsSelected: ['accountNumber'],
      htmlElement: 'select',
      options: [],
    },*/
    {
      name: 'auxiliaryAccount',
      title: 'N° de compte auxilliaire',
      col: 6,
    },
    {
      name: 'customerCategoryId',
      title: 'Catégorie de client',
      col: 6,
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'customercategories',
      selectDataValue: {
        name: 'customerCategory',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['customerCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
    },
    {
      name: 'category',
      title: 'Catégorie de client',
      htmlElement: 'select',
      options: [],
      fieldToTake: ['category'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
    },
    {
      name: 'firstName',
      title: 'nom',
      col: '4',
    },
    {
      name: 'lastName',
      title: 'Prénom(s)',
      col: '4',
    },
    {
      name: 'tel',
      title: 'Téléphone',
      type: 'phone',
      col: '4',
    },
  ],
  excludeToForms: ['account', 'category'],
  excludeToHeaders: ['auxiliaryAccount', 'customerCategoryId'],
  filterOptions: [
    {
      name: 'firstName',
      title: 'nom',
    },
    {
      name: 'lastName',
      title: 'Prénom(s)',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'lg',
    },
  },
};

export default BaseConfiguration (clientsFrameConfig);
