import DefaultQuery from '../../vendor/bases/default.query';

export default class SummarychargeandproductQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'statistic',
    });
  }

  async readAll (params = null) {
    const path = this.buildPath ('summary-charge-and-product', 'statistic');
    return await this.getMethod (path, params);
  }
}
