import DefaultQuery from './../../vendor/bases/default.query';

export default class UsersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'users',
    });
  }

  login = async credentials => {
    const path = this.buildPath ('login', 'user');
    const serverResponse = await this.createMethod (path, credentials);
    return serverResponse;
  };

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list', 'user'), params);
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('add', 'user'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`detail/${id}`, 'user'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`update/${id}`, 'user'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'user'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('active-list', 'user'));
  }

  async changePassword (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}/update-password`, 'user'),
      credentials
    );
  }

  /*async loadAgentOnly () {
    return [];
  }*/
}
