import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const logsFrameConfig = {
  name: 'logs',
  title: 'Journal comptable',
  typeFrame: 'list',
  fields: [
    {
      name: 'accountNumber',
      title: 'N° de compte',
    },
    {
      name: 'date',
      title: 'date',
      type: 'date',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
    },
    {
      name: 'credit',
      title: 'crédit',
    },
    {
      name: 'debit',
      title: 'débit',
    },
  ],
  excludeToForms: ['accountNumber', 'date', 'libelle', 'credit', 'debit'],
  actions: [],
};

export default BaseConfiguration (logsFrameConfig);
