import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const analysMarginFrameConfig = {
  name: 'analysmargin',
  title: 'ANALYSE DE MARGE / ARTICLE',
  typeFrame: 'list',
  fields: [],
  filterOptions: [
    {
      name: 'departementId',
      title: 'departement',
      type: 'select',
      optionsTarget: 'departements',
    },
    {
      name: 'familyId',
      title: 'Famille article',
      type: 'select',
      optionsTarget: 'articlefamillies',
    },
    {
      name: 'subFamilyId',
      title: 'Sous famille article',
      type: 'select',
      optionsTarget: 'articlesubfamillies',
    },
  ],
};

export default BaseConfiguration (analysMarginFrameConfig);
