import {Fragment} from 'react';
import SupplierdebtComponent from './../../../../Supplierdebt/index';

export default function SupplierDebt (props) {
  return (
    <Fragment>
      <SupplierdebtComponent />
    </Fragment>
  );
}
