import BaseBackScreen from '../../../base-back-screen';
import config from './config';
import SingleWithdrawsRender from './../../../Withdraws/_layouts/single/index';

export default class TransferScreen extends BaseBackScreen {
  name = 'transfer';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleWithdrawsRender,
  };
}
