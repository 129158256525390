import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const articlesFrameConfig = {
  name: 'articles',
  title: 'article(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'image',
      type: 'image',
    },
    {
      name: 'code',
      col: '4',
    },
    {
      name: 'articleFamily',
      title: "Famille d'article",
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      selectDataValue: ['name'],
      fieldToTake: ['articleFamilly'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'articleSubFamily',
      title: 'Sous-famille',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlesubfamillies',
      selectDataValue: ['name'],
      fieldToTake: ['articleSubFamily'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'departementId',
      title: 'Département',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'departements',
      selectDataValue: {
        name: 'departement',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['departement'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '4',
    },
    {
      name: 'articleFamilyId',
      title: "Famille d'article",
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      selectDataValue: {
        name: 'articleFamily',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['articleFamilly'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '4',
    },
    {
      name: 'articleSubFamilyId',
      title: 'Sous-famille',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlesubfamillies',
      selectDataValue: {
        name: 'articleSubFamily',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['articleSubFamily'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '4',
    },

    {
      name: 'unit',
      title: 'unité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'units',
      selectDataValue: {
        name: 'unit',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['unit'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '4',
    },

    {
      name: 'name',
      title: 'nom',
      col: '4',
    },
    {
      name: 'alertStock',
      title: 'Stock alerte',
      type: 'number',
      col: '3',
    },
    {
      name: 'maxToStock',
      title: 'Maximum à stocker',
      type: 'number',
      col: '3',
    },
    {
      name: 'weight',
      title: 'Poids unitaire',
      type: 'number',
      col: '3',
    },
    {
      name: 'stockValue',
      title: 'Valeur du stock',
    },
    {
      name: 'totalQty',
      title: 'Stock théorique',
    },
    {
      name: 'unitPrice',
      title: 'Prix unitaire',
      type: 'number',
    },
    {
      name: 'departement',
      title: 'Département',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'departements',
      selectDataValue: ['name'],
      fieldToTake: ['departement'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name : 'qtyToDestock',
      title: 'Quantité matiere à déstocker', 
      col: 3,
      type : 'number'
    }
  ],
  excludeToHeaders: [
    'articleFamilyId',
    'articleSubFamilyId',
    'departementId',
    'unit',
    'maxToStock',
    'weight',
    'stockValue',
    'qtyToDestock'
  ],
  excludeToForms: [
    'articleFamily',
    'articleSubFamily',
    'departement',
    'totalQty',
    'stockValue',
    'unitPrice',
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
    },
    {
      name: 'stock-add',
      title: 'Créer stock',
      color: 'success',
      icon: 'plus',
    },
    {
      name: 'inventory',
      title: 'Stock du dépôt',
      color: 'warning',
      icon: 'boxes-stacked',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
    },
  ],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'code',
      title: 'code',
    },
    {
      name: 'familyId',
      title: 'Famille',
      type: 'select',
      optionsTarget: 'articlefamillies',
    },
    {
      name: 'subFamilyId',
      title: 'Sous famille',
      type: 'select',
      optionsTarget: 'articlesubfamillies',
    },
    {
      name: 'departementId',
      title: 'Departement',
      type: 'select',
      optionsTarget: 'departements',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
    inventoryDialog: {
      size: 'md',
    },
    closedDialog: {
      size: 'md',
    },
    stockAddDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (articlesFrameConfig);
