import "./style.css";
import fiche from '../../../../../assets/images/fiche.jpg';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const SingleSale = props => { 
    const saleInformation = props.row;


    return <div className="card-body bg-light">
                <div className="card-content p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">
                                VENTE SOUS-FAMILLE : {saleInformation?.subFamily?.toUpperCase()}</h5>

                             <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Quantite vendue</span>
                                    <span className="number1">
                                        {saleInformation?.qtySelled?.toLocaleString('fr-FR')}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Total vente</span>
                                    <span className="number1">
                                        {saleInformation?.subFamilyCost?.toLocaleString('fr-FR') + " F.CFA"}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card-content h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  Sale-card-title">
                                REPARTITION DES FRAIS
                            </h6>
                            <hr/>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="table-responsive">
                                <TableContainer component={Paper}>
                                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                    <TableHead sx={{ "& th": {
                                        color: "white",
                                        backgroundColor: "black"
                                        }}}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight:"bold" }}>Article</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Qte vendue</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Montant</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            saleInformation?.articleSelledList?.length > 0 ?   saleInformation?.articleSelledList?.map((row,index) => {
                                              
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                                        {row.article.toUpperCase()}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.qtySelled?.toLocaleString('fr-FR')} 
                                                    </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.cost?.toLocaleString('fr-FR')} 
                                                    </TableCell>
                                                </TableRow>
                                            )}) : 
                                                <TableRow>
                                                    <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                                        Aucun article vendu :)
                                                    </TableCell>
                                                </TableRow>
                                        }
                                        
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                 </div>
            </div> 
}


export default SingleSale;