import {
  BaseConfiguration,
} from './../../../../../vendor/base-component-class/config.class';

const customerOrderFrameConfig = {
  name: 'clientorders',
  title: 'Commande(s) client',
  typeFrame: 'list',
  fields: [
    {
      name: 'orderingNumber',
      title: 'N° de commande',
    },
    {
      name: 'orderingDate',
      title: 'Date de commande',
      type: 'date',
      col: 4,
    },
    {
      name: 'previsionalDate',
      title: 'Date prévisionelle livraison',
      type: 'date',
    },
    {
      name: 'previsionnalDate',
      title: 'Date prévisionelle livraison',
      type: 'date',
      col: 4,
    },
    {
      name: 'dayRemaining',
      title: 'Jour(s) restant livraison',
    },
    {
      col: 4,
      name: 'customerId',
      title: 'Client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
      selectDataValue: {
        name: 'customer',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName', 'lastName'],
        },
      },
    },
    {
      name: 'customer',
      title: 'Client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      selectDataValue: ['firstName', 'lastName'],
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
    },
    {
      name: 'articleOrderList',
      htmlElement: 'dynamicForm',
      title: 'Article(s) commandé(s)',
      fields: [
        {
          name: 'articleId',
          title: 'Produit',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'products',
          fieldToTake: ['articleName'],
          type: 'objectFetcher',
          fieldsSelected: ['articleName'],
          groupBy: ['subFamilyName'],
        },
        {name: 'qty', title: 'quantité', type: 'number'},
        {name: 'reductionOrIncreaseRate', title: 'Remise', type: 'number'},
        {
          name: 'cost',
          title: 'Coût',
          htmlElement: 'disableInput',
          type: 'number',
          editableDisable: true,
        },
      ],
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: 'blue', text: 'Livrée partiellement'},
        2: {badgeColor: '#5F9EA0 ', text: 'Totalement livrée'},
        3: {badgeColor: '#DC143C', text: 'Annulée'},
        4: {badgeColor: '#5F9EA0', text: 'Validée'},
      },
      type: 'state-multiple',
    },
    {
      name: 'advanceOnOrder',
      title: 'Avance',
      type: 'money',
      col: '6',
    },
    {
      name: 'totalOrderCost',
      title: 'Montant',
      htmlElement: 'disableInput',
      type: 'number',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'comment',
      title: 'Commentaire',
      htmlElement: 'textarea',
    },
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Annulée',
          value: 2,
        },
        {
          label: 'Validée',
          value: 1,
        },
      ],
    },
    {
      name: 'customerId',
      title: 'Client',
      type: 'select',
      optionsTarget: 'clients',
    },
    {
      name: 'orderNumber',
      title: 'N° de commande',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  excludeToHeaders: [
    'customerId',
    'articleOrderList',
    'totalOrderCost',
    'comment',
    'previsionnalDate',
  ],
  excludeToForms: [
    'customer',
    'makeAsCompleted',
    'state',
    'orderingNumber',
    'previsionalDate',
    'dayRemaining',
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé',
      color: 'success',
      icon: 'check',
      renderConditions: [
        {
          field: 'state',
          value: 0,
          operation: 'different',
        },
      ],
    },
    {
      name: 'cancel',
      title: 'Annuler vente',
      color: 'danger',
      icon: 'times',
      renderConditions: [
        {
          field: 'state',
          value: 0,
          operation: 'different',
        },
      ],
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      renderConditions: [
        {
          field: 'state',
          value: 0,
          operation: 'different',
        },
      ],
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
    deliverDialog: {
      size: 'md',
    },
    quittanceDialog: {
      size: 'md',
    },
  },
  hideBar: true,
  frameActions: {
    tableActions: [
      {
        frameType: 'list',
        actions: [],
      },
    ],
  },
};

export default BaseConfiguration (customerOrderFrameConfig);
