import Service from '../../vendor/bases/service';

export default class ChargeorproductsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'chargeorproducts',
    });
  }

  async onlyCharges(){
      const data = await this.treatQuery (this.query.onlyCharges());
      return data;
  }

  formatValue (value) {
    return {
      ...value,
      items: value.items.map (item => {
        item.departement = item?.analyticalPlan?.departement?.name;
        return item;
      }),
    };
  }
}
