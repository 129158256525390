import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const unitsFrameConfig = {
  name: 'units',
  title: 'Unités',
  typeFrame: 'list',
  fields: [
    {
      name: 'libelle',
      title: 'libéllé',
    },
  ],
  filterOptions: [
    {
      name: 'libelle',
      title: 'Libéllé',
    },
  ],
};

export default BaseConfiguration (unitsFrameConfig);
