import Service from '../../vendor/bases/service';

export default class ClientdeliversService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'clientdelivers',
    });
  }

  async valid (id) {
    return await this.treatQuery (this.query.valid (id));
  }

  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }

  async program (deliverInformation) {
    return await this.treatQuery (
      this.query.program (this.getFormValues (deliverInformation))
    );
  }
}
