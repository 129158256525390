import DefaultQuery from '../../vendor/bases/default.query';

export default class StockoutputsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'stockoutputs',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'stock-output'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'stock-output'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'stock-output'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'stock-output'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'stock-output'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'stock-output'));
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`${id}/valid`, 'stock-output'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`${id}/cancel`, 'stock-output'),
      {
        id: id,
      }
    );
  }
}
