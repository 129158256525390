import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const rolesFrameConfig = {
  name: 'roles',
  title: 'rôle(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'active',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  actions: [
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
    },
    {
      name: 'config',
      title: 'Permissions',
      color: 'warning',
      icon: 'cog',
    },
  ],
  unsetToHeader: [],
  unsetToForm: [],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'isActive',
      title: 'statut',
      type: 'select',
      options: [
        {label: 'Actif', value: true},
        {label: 'Inactif', value: false},
      ],
    },
  ],
};

export default BaseConfiguration (rolesFrameConfig);
