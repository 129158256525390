import BaseBackComponent from '../base-back-component';

export default class CustomercategoriesComponent extends BaseBackComponent {
  name = 'Customercategories';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      libelle: null,
    },
  };

  options = [
    {
      title: 'departements',
      keys: ['name'],
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    let exemptionList = []; 
    
    if(data?.exemptionLIst?.value && data?.exemptionLIst?.value.length > 0){
      exemptionList = data?.exemptionLIst.value.map(
        exp => {
          return {
            departementId : {value : exp.departement.id},
            exemptionRate : {value : exp.exemptionRate},    
          }
        }
      )
    }

    let majorationList = []; 
    
    if(data?.majorationList?.value && data?.majorationList?.value.length > 0){
      majorationList = data?.majorationList.value.map(
        maj => {
          return {
            departementId : {value : maj.departement.id},
            majorationAmount : {value : maj.majorationAmount},    
          }
        }
      )
    }
   

    data = {
      ...data,
      exemptionList :{value : exemptionList},
      majorationList : {value : majorationList} 
    }
    return data;
  }
}


/*const articleList = data?.articleOrderedList.value.map(
  article => {
    return {
      articleId : {value : article.article.id},
      qty : {value : article.qtyOrdered},
      unitPrice : {value : article.unitPrice},    
    }
  }
)*/
