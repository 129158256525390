import './single.css';
import {Fragment} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import profile from '../../../../../assets/images/img.png';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';

const SingleDepotRender = props => {
  const depotInformation = props.data.rows;

  return (
    <div className="article-card-information">
      
        <div className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>INFORMATION DEPOT #{depotInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={depotInformation?.image || profile} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(depotInformation?.name || "")}</h5>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Localisation</span>
                                    <span className="number1">
                                        {depotInformation?.localisation?.toUpperCase()}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                        <span className="articles">Téléphone</span>
                                        <span className="number2">
                                            {depotInformation?.tel}
                                        </span>

                                </div>
                                <div className="d-flex flex-column">
                                    <span className="followers">Etat</span>
                                    <span className="number3">
                                        {depotInformation?.isActive ? "Actif" : "Inactif"}

                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                FAMILLE(S) ARTICLE 
                            </h6>
                        <hr/>
                        </div>
                        {
                            depotInformation?.articleFamilies?.map(
                                (family,index) => {
                                    return  <Fragment>
                                                <div className="row">
                                                        <div className="col-sm-3">
                                                            <h6 className="mb-0">{index+1}</h6>
                                                        </div>
                                                        <div className="col-sm-9 text-secondary">
                                                            {family?.name}        
                                                        </div>
                                                    </div>
                                                    <hr />
                                            </Fragment>
                                }
                            )

                        }
                      
                    
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleDepotRender;
