import React from 'react';
import CustomerDebt from './customer-debt';
import SupplierDebt from './supplier-debt';
import StatsTemplate from '../stats.template';

export default function StatsByDebt () {
  return <StatsTemplate components={components} />;
}

const components = [
  {
    title: 'clientDebt',
    label: 'Créance client',
    renderComponent: CustomerDebt,
    isVisible: true,
  },
  {
    title: 'supplierDebt',
    label: 'Dette(s) fournisseur',
    renderComponent: SupplierDebt,
    isVisible: true,
  },
];
