import Service from '../../vendor/bases/service';

export default class SupplierordersService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'supplierorders',
    });
  }
  async valid (id) {
    return await this.treatQuery (this.query.valid (id));
  }

  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }

  async onlyValidOrders () {
    return await this.treatQuery (this.query.onlyValidOrders ());
  }

  async orderArticles (orderId) {
    return await this.treatQuery (this.query.orderArticles (orderId));
  }
}
