import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const accountingCategoriesFrameConfig = {
  name: 'accountingcategories',
  title: 'Catégorie de comptabilité',
  typeFrame: 'list',
  fields: [
    {
      name: 'accountingType',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: ['libelle'],
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingTpeId',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: {
        name: 'accountingType',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
      col: '6',
    },
  ],
  excludeToHeaders: ['accountingTpeId'],
  excludeToForms: ['accountingType'],
  filterOptions: [
    {
      name: 'accoutingTypeId',
      title: 'Type de comptabilité',
      type: 'select',
      optionsTarget: 'accountingtypes',
    },
    {
      name: 'libelle',
    },
  ],
};

export default BaseConfiguration (accountingCategoriesFrameConfig);
