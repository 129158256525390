import {Fragment} from 'react';
import CustomerdebtComponent from './../../../../Customerdebt/index';

export default function CustomerDebt (props) {
  return (
    <Fragment>
      <CustomerdebtComponent />
    </Fragment>
  );
}
