import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const sellsResumesFrameConfig = {
  name: 'sellsresumes',
  title: 'Vente par jour',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'nom',
    },
  ],
};

export default BaseConfiguration (sellsResumesFrameConfig);
