import './single.css';
import React,{ Fragment, useRef, useState } from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import register from '../../../../../assets/images/cash-register.png';
import appParams from '../../../../../configs/app-params';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Dialog } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Quittance from '../../../Sells/_layouts/quittance/quittance';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const SingleCashRegisterRender = props => {
  const cashRegisterInformation = props.data.rows;
  
  const componentRef = useRef ();

  const [openModal,setOpenModal] = useState(false);

  const [sellInformation,setSellInformation] = useState(null);

    const openSellDetailDialog = (e,information) => {
        setOpenModal(true);
        setSellInformation({...information, cashRegisterNumber : cashRegisterInformation?.reference});
    }

  
    
    const handleCloseModal = e => {
        setOpenModal(false);
        setSellInformation(null);
    }
  

  return (
    <Fragment>
        <div className="cashRegister-card-information">
        
            <div ref={componentRef} className="mt-2">
                <div className="cashRegister-content">
                    <div className="p-2 text-center text-white cashRegister-title">
                        <h4>{cashRegisterInformation?.reference?.toUpperCase()}</h4>
                    </div>
                    <div className="card p-3 m-1">
                        <div className="d-flex align-items-center">
                            <div className="image bg-light p-2 mr-3">
                                <img src={register} alt="logo" className="rounded" width="120" />
                            </div>
                            <div className="p-2 w-100"> 
                                <small>Solde</small>
                                <h5 className="mb-0 mt-0 text-danger">
                                    {
                                        Intl.NumberFormat(appParams.language,{ style: 'currency',
                                            currency: appParams.currency,
                                            
                                        }).format(cashRegisterInformation?.balance)    
                                    }
                                </h5>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                    <div className="d-flex flex-column">
                                        <span className="cashRegisters">Solde à l'ouverture</span>
                                        <span className="number1">
                                            {
                                                Intl.NumberFormat(appParams.language,{ style: 'currency',
                                                    currency: appParams.currency,
                                                    
                                                        }).format(cashRegisterInformation?.openingBalance)    
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="cashRegisters">DEPOT.</span>
                                        <span className="number1">
                                            {cashRegisterInformation?.depot?.name}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="cashRegisters">Responsable</span>
                                        <span className="number1">
                                            {cashRegisterInformation?.user?.firstName +" "+ cashRegisterInformation?.user?.lastName}
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2 mt-1 bg-primary d-flex justify-content-between rounded text-white stats">
                                    <div className="d-flex flex-column">
                                            <span className="cashRegisters">Date ouverture</span>
                                            <span className="number2">
                                                {moment(cashRegisterInformation?.openingDate).format("DD/MM/YYYY HH:mm:ss")}
                                            </span>

                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="followers">Date fermerture</span>
                                        <span className="number3">
                                            {
                                                cashRegisterInformation?.closingDate? 
                                                moment(cashRegisterInformation?.closingDate).format("DD/MM/YYYY HH:mm:ss")
                                                : ""
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>     
                        </div>
                    </div>
                    <div className="card h-100 m-1">
                        <div className="card-body">
                            <div>
                                <h6 className="d-flex align-items-center p-2 text-white  cashRegister-title">
                                    VENTE(S)
                                </h6>
                                <hr/>

                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="table-responsive">
                                    <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                        <TableHead sx={{ bgcolor:mainTheme.color }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                                <TableCell sx={{ fontWeight:"bold" }}>N°</TableCell>
                                                <TableCell sx={{ fontWeight:"bold" }}>Date</TableCell>
                                                <TableCell sx={{ fontWeight:"bold" }}>Montant</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                cashRegisterInformation?.sellingList?.length > 0 ?  cashRegisterInformation?.sellingList?.map((row,index) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="td" scope="index">
                                                            {index+1}
                                                        </TableCell>
                                                        <TableCell component="td" scope="articleName">
                                                        <Button size="small" onClick={(e) => openSellDetailDialog(e,row)} variant="contained" 
                                                            endIcon={<FontAwesomeIcon icon="eye" />}>
                                                            {row?.sellingNumber}
                                                        </Button>
                                                            
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(row?.date).format('DD/MM/YYYY HH:mm:ss')} 
                                                        </TableCell>
                                                        <TableCell>
                                                            {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                                                        currency: 'XOF',
                                                                                        
                                                                            }).format(row?.netToPaid)} 
                                                        </TableCell>
                                                        
                                                    </TableRow>
                                                )) : 
                                                    <TableRow>
                                                        <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                                            Aucune vente enregistrée :)
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                            
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center image-text border-top">
                <span className="image">
                    <img src={mainTheme.appLogo} alt="logo" className="logo" />
                </span>

                <div className="text logo-text">
                    <span className="name"> PIKADO</span>
                </div>
                </div>
            </div>
        </div>
        <Dialog
            fullScreen
            open={openModal}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Information sur vente
                </Typography>
                <Button autoFocus color="inherit" onClick={handleCloseModal}>
                    Fermer
                </Button>
            </Toolbar>
            </AppBar>
            <Quittance sell={sellInformation}/>    
        </Dialog>
    </Fragment> 
  );
};

export default SingleCashRegisterRender;
