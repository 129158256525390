import DefaultQuery from '../../vendor/bases/default.query';

export default class WithdrawsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'withdraws',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list', 'transfers'), params);
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'transfers'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'transfers'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'transfers'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'transfers'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'transfers'));
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`validate/${id}`, 'transfers'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'transfers'),
      {
        id: id,
      }
    );
  }
}
