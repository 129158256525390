import Service from '../../vendor/bases/service';

export default class ProductsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'products',
    });
  }

  async loadList (params = null) {
    const data = await this.treatQuery (this.query.loadList (params));
    return {
      ...data,
      data: data?.data?.map (item => {
        return {
          ...item,
          name: item.product.name,
          productId : item.product.id,
          image : item.product.image,
          subFamilyName : item.product.articleSubFamily.name
        };
      }),
    };
  }

  formatValue (value) {
    return {
      ...value,
      items: value?.items?.map (item => {
        item.code = item.product.code;
        return item;
      }),
    };
  }
}
