import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const chargeOrProductsFrameConfig = {
  name: 'chargeorproducts',
  title: 'Charge ou produit(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'date',
      type: 'date',
      col: '6',
    },
    {
      name: 'accountingType',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: ['libelle'],
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingTpeId',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: {
        name: 'accountingType',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingCategory',
      title: 'Catégorie de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingcategories',
      selectDataValue: ['libelle'],
      fieldToTake: ['accountingCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingCategoryId',
      title: 'Catégorie de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingcategories',
      selectDataValue: {
        name: 'accountingCategory',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['accountingCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'analyticalPlan',
      title: 'Code analytique',
      selectDataValue: ['analyticalCode'],
      fieldToTake: ['analyticalPlan'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
      col: '6',
    },
    {
      name: 'analyticalPlanId',
      title: 'Code analytique',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'analytiqueplans',
      selectDataValue: {
        name: 'analyticalPlan',
        options: {
          valueOptions: ['id'],
          labelOptions: ['analyticalCode'],
        },
      },
      fieldToTake: ['analyticalPlan'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
      col: '4',
    },
    {
      name: 'departement',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
      col: '4',
    },
    {
      name: 'cost',
      title: 'Coût',
      type: 'money',
      col: '4',
    },
  ],
  excludeToHeaders: [
    'accountingTpeId',
    'accountingCategoryId',
    'analyticalPlanId',
    'analyticalPlan',
  ],
  excludeToForms: [
    'accountingType',
    'accountingCategory',
    'analyticalPlan',
    'date',
    'departement',
  ],
  filterOptions: [
    {
      name: 'accountingTypeId',
      title: 'Type de comptabilité',
      type: 'select',
      optionsTarget: 'accountingtypes',
    },
    {
      name: 'accountingCategoryId',
      title: 'Catégorie de comptabilité',
      type: 'select',
      optionsTarget: 'accountingcategories',
    },
    {
      name: 'date',
      type: 'interval',
    },
    {
      name: 'libelle',
    },
  ],
};

export default BaseConfiguration (chargeOrProductsFrameConfig);
