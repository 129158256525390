import DefaultQuery from './../../vendor/bases/default.query';

export default class ClientdeliversQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'clientdelivers',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'customer-delivers'),
      params
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'customer-delivers'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (
      this.buildPath (`${id}/single`, 'customer-delivers')
    );
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'customer-delivers'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'customer-delivers'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'customer-delivers')
    );
  }

  async program (programDto) {
    return await this.updateMethod (
      this.buildPath (`program`, 'customer-delivers'),
      programDto
    );
  }

  async valid (deliverId) {
    return await this.updateMethod (
      this.buildPath (`${deliverId}/valid`, 'customer-delivers'),
      {id: deliverId}
    );
  }

  async cancel (deliverId) {
    return await this.updateMethod (
      this.buildPath (`${deliverId}/cancel`, 'customer-delivers'),
      {id: deliverId}
    );
  }
}
