import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const customerPaymentsFrameConfig = {
  name: 'customerpayments',
  title: 'Règlement(s) client',
  typeFrame: 'list',
  fields: [
    {
      name: 'customerId',
      title: 'client',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'clients',
      selectDataValue: {
        name: 'supplierId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName', 'lastName'],
        },
      },
      col: '6',
    },
    {
      name: 'customer',
      title: 'Client',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      isObject: true,
      fieldsSelected: ['firstName', 'lastName'],
      dataServiceSource: 'clients',
    },
    {
      name: 'payementMode',
      title: 'Mode de paiement',
      htmlElement: 'select',
      options: [
        {
          value: 'Mobile money',
          label: 'Mobile money',
        },
        {
          value: 'Chèque',
          label: 'Chèque',
        },
        {
          value: 'Virement bancaire',
          label: 'Virement bancaire',
        },
        {
          value: 'Espèce',
          label: 'Espèce',
        },
      ],
      col: '6',
    },
    {
      name: 'payementDate',
      title: 'Date de paiement',
      type: 'date',
      col: '6',
    },
    /*{
      name: 'amount',
      title: 'Montant',
      type: 'money',
      col: '6',
    },*/
    {
      name: 'invoiceList',
      title: 'Facture(s)',
      htmlElement: 'select',
      fieldToTake: 'sellList',
      col: 6,
      options: [],
      multiple: true,
      dataServiceSource: 'sells',
      selectDataValue: {
        name: 'sellList',
        options: {
          valueOptions: ['id'],
          labelOptions: ['sellingNumber'],
        },
      },
    },
    {
      name: 'amount',
      title: 'Montant',
      type: 'money',
      col: 12,
      htmlElement: 'disableInput',
      editableDisable: true,
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  excludeToForms: ['customer'],
  excludeToHeaders: ['customerId', 'invoiceList'],
  filterOptions: [
    {
      name: 'customerId',
      title: 'Client',
      optionsTarget: 'customers',
      type: 'select',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (customerPaymentsFrameConfig);
