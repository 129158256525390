import DefaultQuery from '../../vendor/bases/default.query';

export default class ChargeorproductsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'chargeorproducts',
    });
  }
  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'charge-or-products'),
      params
    );
  }

  async getSimpleCharges () {
    return await this.getMethod (
      this.buildPath ('charge-list', 'charge-or-products')
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'charge-or-products'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (
      this.buildPath (`${id}`, 'charge-or-products')
    );
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'charge-or-products'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'charge-or-products'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'charge-or-products')
    );
  }

  async onlyCharges () {
    return await this.deleteMethod (
      this.buildPath (`charge-list`, 'charge-or-products')
    );
  }
}
