import BaseBackComponent from '../../../base-back-component';
import SalesScreen from './screen';
import SellsService
  from './../../../../../external-communication/services/sells.service';

export default class SalesComponent extends BaseBackComponent {
  name = 'sales';

  options = [
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    },
    {
      title: 'products',
      keys: ['articleName'],
      optionValue: 'articleId',
      dataToGetForGroupTitle: ['subFamilyName'],
      imgTarget: 'image',
      source: 'articles',
      method: 'articlesWithPricing',
    },
  ];

  state = {
    ...this.state,
    screen: SalesScreen,
    searchOptions: {
      ...this.state.searchOptions,
      state: null,
      customerId: null,
      orderNumber: null,
      date: null,
    },
  };

  async componentDidMount () {
    let status = '';

    let state = this.props.params.state;

    if (state === 'in wait') {
      status = 2;
    } else if (state === 'cancel') {
      status = 0;
    } else if (state === 'valid') {
      status = 1;
    }
    await this.setState ({
      service: new SellsService (),
      searchOptions: {
        ...this.state.searchOptions,
        state: status,
        date: this.props.params.date || '',
      },
    });
    this.initDataLoading ();
    this.loadOptions ();
  }
}
