import BaseBackComponent from '../base-back-component';

export default class StockmovesComponent extends BaseBackComponent {
  name = 'Stockmoves';

  options = [
    {
      title: 'articles',
      keys: ['name'],
    },
    {
      title: 'depots',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      movementNumber: null,
      depotId: null,
      articleId: null,
      type: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
