import Service from '../../vendor/bases/service';

export default class ClientordersService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'clientorders',
    });
  }

  async valid (id) {
    return await this.treatQuery (this.query.valid (id));
  }

  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }

  async deliver (deliverInformation) {
    return await this.treatQuery (
      this.query.deliver (this.getFormValues (deliverInformation))
    );
  }

  async getOrderArticles (orderId) {
    return await this.treatQuery (this.query.getOrderArticles (orderId));
  }

  async notDeliverCmd () {
    return await this.treatQuery (this.query.notDeliverCmd ());
  }
}
