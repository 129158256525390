import BaseBackScreen from '../../../base-back-screen';
import SingleCustomerDeliver from '../../../Clientdelivers/_layouts/single';
import config from './config';

export default class CustomerDeliverScreen extends BaseBackScreen {
  name = 'Dashboard/customer-deliver';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleCustomerDeliver,
  };
}
