import DefaultQuery from '../../vendor/bases/default.query';

export default class DepotsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'depots',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async getArticles (depotId) {
    return await this.getMethod (
      this.buildPath (`depot-stock/${depotId}`, 'stocks')
    );
  }

  async rawMaterialDepots () {
    return await this.getMethod (this.buildPath (`raw-material-depots`));
  }

  async finishedProductDepots () {
    return await this.getMethod (this.buildPath (`finished-product-depots`));
  }

  async loadDepotArticleQtyAvailable (articleId) {
    return await this.getMethod (
      this.buildPath (`article/${articleId}/information`, 'stocks')
    );
  }
}
