import {useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './style.css';
import {Button} from '@mui/material';
import {useReactToPrint} from 'react-to-print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import customerInfo from '../../../../../../configs/customer';
import mainTheme from '../../../../../../configs/mainTheme';


const SingleCustomerInvoice = props => {
  const componentRef = useRef ();

  const invoiceInformation = props.data.rows;

  console.log(invoiceInformation)

  const onPrint = useReactToPrint ({
    content: () => componentRef.current,
  });

  
  return (
    <div className="p-2">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: 'green', mr: 1}}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>
      <div className="invoice-container" ref={componentRef}>
          <div className="invoice-header">
            <div className="row gutters text-center mb-2">
              <span className="invoice-logo">
                FACTURE CLIENT
              </span>
            </div>
            <div className="mt-2 d-flex flex-column">
              <div className="d-flex">
                  <span>N° : </span>
                  <strong className="mx-1">{invoiceInformation?.invoiceNumber?.toUpperCase()}</strong>
              </div>
              <div className="d-flex ">
                  <span>Date : </span>
                  <strong className="mx-1">
                    {moment(invoiceInformation?.date).format("DD/MM/YYYY")}
                  </strong>
              </div>
            </div>
            {/* Row end */}
            {/* Row start */}
            <div className="row gutters d-flex justify-content-space-between">
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    CLIENT
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="mx-1">{customerInfo.name}</strong>
                    <br />
                    Adresse :
                    <strong className="mx-1">{customerInfo.address}</strong>
                    <br />
                    Tel : <strong className="mx-1">{customerInfo.tel}</strong>
                  </address>
                </div>
              </div>
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    CLIENT
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="mx-1">
                      {invoiceInformation?.customer?.firstName +
                        ' ' +
                        invoiceInformation?.customer?.lastName}
                    </strong>
                    <br />
                    Adresse :
                    <strong className="mx-1">
                      {invoiceInformation?.customer?.addresse}
                    </strong>
                    <br />
                    Tel :
                    {' '}
                    <strong className="mx-1">
                      {invoiceInformation?.customer?.tel}
                    </strong>
                  </address>
                </div>
              </div>
            </div>
            {/* Row end */}
            <div className="invoice-body mt-2">
              {/* Row start */}
              <div className="row gutters">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="table-responsive">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                          <TableHead sx={{ bgcolor:mainTheme.color }}>
                              <TableRow>
                                  <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Désignation</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Quantité</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Prix unitaire</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Total</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  invoiceInformation?.articles?.length > 0 ?  invoiceInformation?.articles?.map((row,index) => (
                                      <TableRow
                                          key={row.id}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                          <TableCell component="td" scope="index">
                                              {index+1}
                                          </TableCell>
                                          <TableCell component="td" scope="articleName">
                                              {row?.article?.name}
                                          </TableCell>
                                          <TableCell>
                                              x {row?.qtySelled} 
                                          </TableCell>
                                          <TableCell>
                                            {
                                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                                }).format( row?.unitPrice)
                                           } 
                                          </TableCell>
                                          <TableCell>
                                            {
                                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                                }).format(row?.qtySelled * row?.unitPrice )
                                            }
                                          </TableCell>
                                      </TableRow>
                                  )) : 
                                      <TableRow>
                                          <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                              Aucun article sur ce bon de livraison :)
                                          </TableCell>
                                      </TableRow>
                              }
                              
                          </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="mt-2 border-top">
                   
                    
                    <div className="d-flex justify-content-end p-3 bg-light border-top">
                      <h5 className="me-3">Montant :</h5>
                      <h5 className="fw-bold">{ Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                                }).format(invoiceInformation?.invoiceCost)}</h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* Row end */}
            </div>
          </div>
      </div>
    </div>
  );
};

export default SingleCustomerInvoice; 