import BaseBackComponent from '../base-back-component';

export default class AccountingtypesComponent extends BaseBackComponent {
  name = 'Accountingtypes';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      libelle: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
