import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const withdrawsFrameConfig = {
  name: 'withdraws',
  title: 'Transfert(s) inter-dépôt',
  typeFrame: 'list',
  fields: [
    {
      name: 'initialDepotName',
      title: 'Origine',
      col: 6,
    },
    {
      name: 'finalDepotName',
      title: 'Destination',
      col: 6,
    },

    {
      name: 'initialDepotId',
      title: 'Origine',
      col: 4,
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'initialDepots',
      selectDataValue: {
        name: 'initialDepotId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'finalDepotId',
      title: 'Destination',
      col: 4,
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'finalDepots',
      selectDataValue: {
        name: 'finalDepotId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'transferDate',
      title: 'Date transfert',
      col: 4,
      type: 'date',
    },
    {
      name: 'state',
      title: 'etat',
      options: {
        0: {badgeColor: '#FFA500', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validé'},
        2: {badgeColor: '#DC143C', text: 'Annulé'},
      },
      type: 'state-multiple',
    },
    {
      name: 'validateDate',
      title: 'Date validation',
      type: 'date',
    },
    {
      name: 'cancelDate',
      title: 'Date annulation',
      type: 'date',
    },
    {
      name: 'articleTransfered',
      title: 'Article(s) à transférer',
      fields: [
        {
          name: 'articleId',
          title: 'Article',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'articles',
          fieldToTake: ['article'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        {name: 'qtyTransferred', title: 'quantité', type: 'number'},
      ],
      htmlElement: 'dynamicForm',
    },
  ],
  excludeToHeaders: ['articleTransfered', 'finalDepotId', 'initialDepotId'],
  excludeToForms: [
    'state',
    'validateDate',
    'cancelDate',
    'finalDepotName',
    'initialDepotName',
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Annulée',
          value: 2,
        },
        {
          label: 'Validée',
          value: 1,
        },
      ],
    },
    /*{
      name: 'initialDepotId',
      title: 'dépot initial ',
      type: 'select',
      optionsTarget: 'depots',
    },
    {
      name: 'finalDepotId',
      title: 'dépot actuel ',
      type: 'select',
      optionsTarget: 'depots',
    },*/
    {
      name: 'transferNumber',
      title: 'N° ',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
  formConfig: {
    addFormTitle: "Enregistrer transfert d'article",
    editFormTitle: "Modifier information de transfert d'article",
  },
};

export default BaseConfiguration (withdrawsFrameConfig);
