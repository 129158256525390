import moment from 'moment';
import BaseBackComponent from '../../../../base-back-component';
import CashflowLoopbackRender from './render';

export default class CashflowLoopbackComponent extends BaseBackComponent {
  name = 'Loopbackcashflow';

  state = {
    ...this.state,
    screen: CashflowLoopbackRender,
    searchOptions: {
      ...this.state.searchOptions,
      date: moment (new Date ()).format ('YYYY-MM-DD') +
        ',' +
        moment (new Date ()).format ('YYYY-MM-DD'),
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
