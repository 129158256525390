import DefaultQuery from '../../vendor/bases/default.query';

export default class SellsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'sells',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'direct-sellings'),
      params
    );
  }

  async create (credentials) {
    let goodPath = '';
    if (credentials.waiting === 1) {
      goodPath = 'sell-pending';
    }

    return await this.createMethod (
      this.buildPath (`${goodPath}`, 'direct-sellings'),
      credentials
    );
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'direct-sellings'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'direct-sellings'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'direct-sellings'));
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'direct-sellings'),
      {
        id: id,
      }
    );
  }

  async getCashRegisterSellsResume (cashRegisterId) {
    return await this.getMethod (
      this.buildPath (`sell-summary/${cashRegisterId}`, 'direct-sellings')
    );
  }

  async getUnpaidInvoices (customerId = null) {
    if (customerId) {
      return await this.getMethod (
        this.buildPath (`sell-not-paid/${customerId}`, 'direct-sellings')
      );
    }
    return await this.getMethod (this.buildPath (``, 'client-invoices'));
  }
}
