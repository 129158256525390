import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const productsFrameConfig = {
  name: 'products',
  title: 'Recette(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'code',
    },
    {
      name: 'product',
      title: 'Désignation',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articles',
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'productId',
      title: 'Désignation',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'finishedProducts',
      selectDataValue: {
        name: 'productId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      col: 6,
    },
    {
      name: 'qty',
      title: 'Quantité',
      type: 'number',
      col: 6,
    },
    /*{
      name: 'materialCost',
      title: 'Coût matière',
    },*/
    {
      name: 'rawMaterialList',
      title: 'Composition de la recette',
      fields: [
        {
          name: 'rawMaterialId',
          title: 'Article ou Produit fini',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'excludeFinishedProducts',
          fieldToTake: ['article'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        {
          name: 'qty',
          title: 'Quantité',
          type: 'text',
        },
      ],
      htmlElement: 'dynamicForm',
    },
  ],
  excludeToHeaders: ['rawMaterialList', 'productId'],
  excludeToForms: ['product', 'code', 'materialCost'],
  filterOptions: [
    {
      name: 'finishProductId',
      title: 'Produit fini',
      type: 'select',
      optionsTarget: 'articles',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (productsFrameConfig);
