import Service from '../../vendor/bases/service';

export default class BestsellerService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'bestseller',
    });
  }
}
