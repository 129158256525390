import './single.css';
import {useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import profile from '../../../../../assets/images/img.png';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';
import moment from 'moment/moment';

const SingleExpenseRender = props => {


  const expenseInformation = props.data.rows;

  const componentRef = useRef ();
  

  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE DEPENSE #{expenseInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={expenseInformation?.image || profile} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(expenseInformation?.expenseNumber || "")}</h5>
                            <small>DEPENSE PIKADO</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Departement</span>
                                    <span className="number1">
                                        {capitalizeFirstLetter(expenseInformation?.analyticalPlan?.departement?.name || "")}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                        <span className="articles">Type</span>
                                        <span className="number2">
                                            {expenseInformation?.accountingType?.libelle}
                                        </span>

                                </div>
                                <div className="d-flex flex-column">
                                    <span className="followers">Date</span>
                                    <span className="number3">
                                        {
                                            expenseInformation?.expenseDate
                                             ? 
                                            moment(expenseInformation?.expenseDate).format("DD/MM/YYYY") : null
                                        }

                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                        <hr/>

                        </div>
                        {   expenseInformation?.supplier ? 
                               <div className="row">
                               <div className="col-sm-3">
                                   <h6 className="mb-0">Fournisseur</h6>
                               </div>
                               <div className="col-sm-9 text-secondary">
                                   {expenseInformation?.supplier?.firstName}        
                               </div>
                           </div> : null
                        }
                        <hr />
                        {   expenseInformation?.customer ? 
                               <div className="row">
                               <div className="col-sm-3">
                                   <h6 className="mb-0">Client</h6>
                               </div>
                               <div className="col-sm-9 text-secondary">
                                   {`${expenseInformation?.customer?.firstName +" "+ expenseInformation?.customer?.lastName}`}        
                               </div>
                           </div> : null
                        }
                        <hr/>
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Montant</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                                        currency: 'XOF',
                                                                        
                                                              }).format(expenseInformation?.amount || 0)}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Description</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {expenseInformation?.description}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleExpenseRender;
