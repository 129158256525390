import moment from 'moment';
import BaseBackComponent from '../base-back-component';
import SellandwastebyarticleService
  from '../../../external-communication/services/sellandwastebyarticle.service';

export default class SellandwastebyarticleComponent extends BaseBackComponent {
  name = 'Sellsandwastebyarticle';

  options = [
    {
      title: 'articles',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    service: new SellandwastebyarticleService (),
    searchOptions: {
      ...this.state.searchOptions,
      year: moment (new Date ()).format ('YYYY'),
      articleId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
