import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const supplierOrdersFrameConfig = {
  name: 'supplierorders',
  title: 'Commande(s) fournisseur',
  typeFrame: 'list',
  fields: [
    {
      name: 'orderingDate',
      title: 'Date de commande',
      type: 'date',
      col: 4,
    },
    {
      col: 4,
      name: 'supplierId',
      title: 'Fournisseur',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'suppliers',
      fieldToTake: ['supplier'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName'],
      selectDataValue: {
        name: 'supplier',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName'],
        },
      },
    },
    {
      name: 'supplier',
      title: 'Fournisseur',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'suppliers',
      selectDataValue: ['firstName'],
      fieldToTake: ['supplier'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName'],
    },
    {
      col: 4,
      name: 'accountingTypeId',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      selectDataValue: {
        name: 'accountingType',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
    },
    {
      col: 4,
      name: 'accountingCategoryId',
      title: 'Catégorie de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingcategories',
      fieldToTake: ['accountingCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      selectDataValue: {
        name: 'accountingCategory',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
    },
    {
      col: 4,
      name: 'analyticalPlanId',
      title: 'Code analytique',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'analytiqueplans',
      fieldToTake: ['analyticalPlan'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
      selectDataValue: {
        name: 'analyticalPlan',
        options: {
          valueOptions: ['id'],
          labelOptions: ['analyticalCode'],
        },
      },
    },
    {
      col: 4,
      name: 'tva',
      type: 'number',
    },
    {
      name: 'analyticalPlan',
      title: 'Code',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'analyticalplans',
      selectDataValue: ['firstName'],
      fieldToTake: ['analyticalCode'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
    },
    {
      name: 'accountingType',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      selectDataValue: ['accountingType'],
      fieldToTake: ['libelle'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
    },
    {
      name: 'accountingCategory',
      title: 'Catégorie de comptabité',
      htmlElement: 'select',
      selectDataValue: ['accountingCategory'],
      fieldToTake: ['libelle'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
    },
    {
      name: 'orderingNumber',
      title: 'N° de commande',
    },

    {
      name: 'makeAsCompleted',
      title: '',
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Lancée'},
        3: {badgeColor: '#DC143C', text: 'Annulée'},
        4: {badgeColor: 'blue', text: 'Reçue'},
        2: {badgeColor: '#757500', text: 'Partiellement reçue'},
      },
      type: 'state-multiple',
    },
    {
      name: 'articleList',
      title: 'Article(s) à commander',
      fields: [
        {
          name: 'articleId',
          title: 'Article',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'articles',
          fieldToTake: ['article'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        {name: 'qty', title: 'quantité', type: 'number'},
        {name: 'unitPrice', title: 'Prix unitaire', type: 'number'},
      ],
      htmlElement: 'dynamicForm',
    },
  ],
  excludeToHeaders: [
    'supplierId',
    'analyticalPlanId',
    'articleList',
    'accountingCategoryId',
    'accountingTypeId',
    'tva',
    'accountingCategory',
    'accountingType',
    'makeAsCompleted',
  ],
  excludeToForms: [
    'supplier',
    'makeAsCompleted',
    'analyticalPlan',
    'state',
    'orderingNumber',
    //  'orderingDate',
    'accountingCategory',
    'accountingType',
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Annulée',
          value: 3,
        },
        {
          label: 'Validée',
          value: 1,
        },
        {
          label: 'Reçu partiellement',
          value: 2,
        },
        {
          label: 'Receptionée',
          value: 4,
        },
      ],
    },
    {
      name: 'supplierId',
      title: 'fournisseur',
      type: 'select',
      optionsTarget: 'suppliers',
    },
    {
      name: 'orderNumber',
      title: 'N° de commande',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (supplierOrdersFrameConfig);
