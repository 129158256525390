import DefaultQuery from '../../vendor/bases/default.query';

export default class DepartementsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'departements',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }
}
