import Service from '../../vendor/bases/service';

export default class ProductionsService extends Service {

  constructor (params) {
    super ({
      ...params,
      name: 'productions',
    });
  }

  async all (params = null) {
    const data = await this.treatQuery (this.query.readAll (params));
    
    return {
        ...data,
        data: {
            ...data.data,
            items : this.formatBeforeSendForRender(data?.data?.items)
        }
    }; 
  }

  formatBeforeSendForRender(data){
    let formatCollection  = data.map(
        row => {
            return {
                ...row,
                product : {
                    ...row.product,
                    name : row.product.product.name
                }
            };
        }
    )

   
    return formatCollection;
  }

  async valid (id) {
    return await this.treatQuery (this.query.valid (id));
  }

  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }
}
