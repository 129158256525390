import BaseBackComponent from '../base-back-component';

export default class SupplierordersComponent extends BaseBackComponent {
  name = 'Supplierorders';

  options = [
    {
      title: 'analytiqueplans',
      keys: ['analyticalCode'],
    },
    {
      title: 'accountingtypes',
      keys: ['libelle'],
    },
    {
      title: 'accountingcategories',
      keys: ['libelle'],
    },
    {
      title: 'suppliers',
      keys: ['firstName'],
    },
    {
      title: 'articles',
      keys: ['name'],
      method: 'excludeFinishedProducts',
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      state: null,
      supplierId: null,
      orderNumber: null,
      date: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    const articleList = data?.articleOrderedList.value.map(
      article => {
        return {
          articleId : {value : article.article.id},
          qty : {value : article.qtyOrdered},
          unitPrice : {value : article.unitPrice},    
        }
      }
    ) 


    data = {
      ...data,
      analyticalPlanId : {value : data?.analyticalPlan?.value?.id},
      accountingTypeId : {value : data?.accountingType?.value?.id},
      analyticalCategoryId : {value : data?.accountingCategory?.value?.id},
      supplierId : {value : data?.supplier?.value?.id},
      articleList : {value : articleList}
    };
    return data;
  }
}
