import BaseBackScreen from '../../../base-back-screen';
import SingleOrder from '../../../Supplierorders/_layouts/single';
import config from './config';

export default class SupplierOrderScreen extends BaseBackScreen {
  name = 'customer-order';

  state = {
    ...this.state,
    config: config,
    SingleComponent: SingleOrder,
  };
}
