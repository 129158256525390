import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const expensesFrameConfig = {
  name: 'expenses',
  title: 'Dépense(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'expenseNumber',
      title: 'N° de dépense',
    },
    {
      name: 'expenseDate',
      type: 'date',
      title: 'Date dépense',
      col: 6,
    },
    {
      name: 'accountingType',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: ['libelle'],
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingTypeId',
      title: 'Type de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingtypes',
      selectDataValue: {
        name: 'accountingType',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['accountingType'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '6',
    },
    {
      name: 'accountingCategory',
      title: 'Catégorie de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingcategories',
      selectDataValue: ['libelle'],
      fieldToTake: ['accountingCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '4',
    },
    {
      name: 'accountingCategoryId',
      title: 'Catégorie de comptabilité',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'accountingcategories',
      selectDataValue: {
        name: 'accountingCategory',
        options: {
          valueOptions: ['id'],
          labelOptions: ['libelle'],
        },
      },
      fieldToTake: ['accountingCategory'],
      type: 'objectFetcher',
      fieldsSelected: ['libelle'],
      col: '4',
    },
    {
      name: 'analyticalPlan',
      title: 'Code analytique',
      selectDataValue: ['analyticalCode'],
      fieldToTake: ['analyticalPlan'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
      col: '4',
    },

    {
      name: 'analyticalPlanId',
      title: 'Code analytique',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'analytiqueplans',
      selectDataValue: {
        name: 'analyticalPlan',
        options: {
          valueOptions: ['id'],
          labelOptions: ['analyticalCode'],
        },
      },
      fieldToTake: ['analyticalPlan'],
      type: 'objectFetcher',
      fieldsSelected: ['analyticalCode'],
      col: '4',
    },

    {
      name: 'paymentModde',
      title: 'Moyen de paiement',
      htmlElement: 'select',
      col: '4',
      options: [
        {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
        {value: 'BANQUE', label: 'BANQUE'},
        {value: 'CASH', label: 'CASH'},
        {value: 'CREDIT', label: 'CREDIT'},
      ],
    },
    {
      name: 'supplier',
      title: 'Fournisseur',
      selectDataValue: ['firstName'],
      fieldToTake: ['supplier'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName'],
      col: '4',
    },
    {
      name: 'customer',
      title: 'Client',
      selectDataValue: ['firstName', 'lastName'],
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
      col: '4',
    },
    {
      name: 'supplierId',
      title: 'Fournisseur',
      htmlElement: 'select',
      dataServiceSource: 'suppliers',
      selectDataValue: {
        name: 'supplier',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName'],
        },
      },
      fieldToTake: ['supplier'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName'],
      col: '4',
    },
    {
      name: 'customerId',
      title: 'Client',
      htmlElement: 'select',
      dataServiceSource: 'clients',
      selectDataValue: {
        name: 'customer',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName', 'lastName'],
        },
      },
      fieldToTake: ['customer'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
      col: '4',
    },
    {
      name: 'amount',
      type: 'money',
      title: 'Montant',
      col: 4,
    },
    {
      name: 'description',
    },
    {
      name: 'status',
      title: 'Etat',
      options: {
        0: {
          badgeColor: 'orange',
          text: "En attente d'approbation",
          textColor: 'white',
        },
        1: {badgeColor: 'blue', text: 'Approuvée'},
        2: {badgeColor: '#5F9EA0 ', text: 'Exécutée'},
      },
      type: 'state-multiple',
    },
  ],
  excludeToHeaders: [
    'accountingTypeId',
    'accountingCategoryId',
    'analyticalPlanId',
    'supplierId',
    'chargeOrProductId',
    'description',
    'customerId',
  ],
  excludeToForms: [
    'accountingType',
    'accountingCategory',
    'analyticalPlan',
    'expenseNumber',
    'supplier',
    'chargeOrProduct',
    'customer',
    'status',
  ],
  filterOptions: [
    {
      name: 'expenseNumber',
      title: 'N°',
    },
    {
      name: 'accountingTypeId',
      title: 'Type de comptabilité',
      type: 'select',
      optionsTarget: 'accountingtypes',
    },
    {
      name: 'departementId',
      title: 'Département',
      type: 'select',
      optionsTarget: 'departements',
    },
    {
      name: 'status',
      title: 'Statut',
      type: 'select',
      options: [
        {value: 0, label: 'En attente'},
        {value: 1, label: 'Approuvée'},
        {value: 2, label: 'Executée'},
      ],
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'approved',
      title: 'Approuvé',
      color: 'primary',
      icon: 'check',
      elementTest: 'status',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'performed',
      title: 'Exécuté',
      color: 'success',
      icon: 'check',
      elementTest: 'status',
      value: 1,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'status',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'status',
      value: 0,
      operator: 'equal',
    },
  ],
};

export default BaseConfiguration (expensesFrameConfig);
