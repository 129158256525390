import DefaultQuery from '../../vendor/bases/default.query';

export default class ClientordersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'clientorders',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('list', 'customer-orders'),
      params
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'customer-orders'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'customer-orders'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'customer-orders'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'customer-orders'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'customer-orders')
    );
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`validate/${id}`, 'customer-orders'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'customer-orders'),
      {
        id: id,
      }
    );
  }

  async getOrderArticles (orderId) {
    return await this.getMethod (
      this.buildPath (`${orderId}/articles`, `customer-orders`)
    );
  }

  async notDeliverCmd () {
    return await this.getMethod (
      this.buildPath (`validate`, `customer-orders`)
    );
  }
}
