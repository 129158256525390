import Service from '../../vendor/bases/service';

export default class SellsevolutionService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'sellsevolution',
    });
  }
}
