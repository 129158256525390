import Service from '../../vendor/bases/service';

export default class ProductsellspriceService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'productsellsprice',
    });
  }

  formatValue (value) {
    return {
      ...value,
      items: value.items.map (item => {
        item.code = item.article.code;
        item.articleFamily = item.article.articleFamily.name;
        item.articleSubFamily = item.article.articleSubFamily.name;
        item.weight = item.article.weight;
        item.rangeValue = item.sellingPrice - item.purchasePrice;
        item.rangePercentage = 0;
        if (item.purchasePrice > 0) {
          item.rangePercentage = item.rangeValue / item.purchasePrice * 100;
        }
        item.rangePercentage = item.rangePercentage?.toFixed(2) + ' %';
        return item;
      }),
    };
  }

  async one (id) {
    const articleSimple = await this.treatQuery (this.query.readOne (id));
    
    let rangeValue = articleSimple?.data?.sellingPrice - articleSimple?.data?.purchasePrice;
    
    let rangePercentage = 0;

    if(articleSimple.data?.purchasePrice > 0){
      rangePercentage = (rangeValue / articleSimple.data?.purchasePrice) * 100;
    }
    const donnees = {
      ...articleSimple,
      data : {
        ...articleSimple.data,
        rangePercentage : rangePercentage?.toFixed(2) + "%",
        rangeValue : rangeValue,
      }
    };
    console.log(donnees.data);
    
    return donnees; 
  }
}
