import {Fragment, useState} from 'react';
import ChartBuilder from '../../../../../../vendor/builders/Chart';
import { Autocomplete, TextField } from '@mui/material';

const currentYear = new Date ().getFullYear ();

const years = () => {
  let yearsCollection = [];
  for (let i = 2010; i <= currentYear; i++) {
    yearsCollection.push ({value: `${i}`,label : `${i}`});
  }
  return yearsCollection;
};


export default function StatsByCAEvolutionRender (props) {

  let renderConfigs = props.data;

  
  const elementIndex = years().findIndex(item => item.value  === currentYear)

  const [currentExercise,setCurrentExercise] = useState(elementIndex !== - 1 ? years()[elementIndex] : years()[years().length - 1]);


  const departments = renderConfigs?.options?.find(option => option.title === "departements")?.options || [];

  const changeYear = (e, value = null) => {
    if(value){
        renderConfigs.screenActions.handleSearch(e,value?.value,"year");
    }else{
        renderConfigs.screenActions.handleSearch(e,null,"year");
    }
    setCurrentExercise(value);
  }

  const changeDepartment = (e,value = null) => {
    if(value){
      renderConfigs.screenActions.handleSearch(e,value?.value,"departementId");
    }else{
        renderConfigs.screenActions.handleSearch(e,null,"departementId");
    }
    setDepartment(value);
  }


  const [department,setDepartment] = useState();

  const chartValue = otherDefaultFormatChartValue(renderConfigs?.data);

  return (
    <Fragment>
      <div className="title text-white p-2">
        EVOLUTION CHIFFRE D'AFFAIRE PAR AN
      </div>
      <div className="col-12 d-flex justify-content-start my-3">
            <Autocomplete
                id="year"
                options={years()}
                onChange={(e,value) => {
                    changeYear(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === currentExercise?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Exercice`} />}
                value={currentExercise}
            />
            <Autocomplete
                id="departementId"
                options={departments}
                onChange={(e,value) => {
                    changeDepartment(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === department?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Departement`} />}
                value={department}
            />
      </div>
      <div className='col-12 d-flex justify-content-center'>
            <ChartBuilder 
                dataset={chartValue?.dataset}
                series={[{ data : chartValue?.series,type: 'line' }]} 
                xAxis={chartValue?.xAxis} 
                chartType="line" 
            />
      </div>
    </Fragment>
  );
}


function otherDefaultFormatChartValue (donnees) {
  let series = [];
  let dataset = [];
  let xAxis = [];

  if (donnees) {
    for (let data of donnees) {
      const valueData = data?.sellAmount;

      dataset.push ({
        [data.month]: valueData,
      });

      series.push (valueData);
      xAxis.push (data.month);
    }
  }

  return {xAxis, series, dataset};
}