import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const articleFamilliesFrameConfig = {
  name: 'articlefamillies',
  title: "Familles d'article",
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
    },
  ],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
  ],
};

export default BaseConfiguration (articleFamilliesFrameConfig);
