import DefaultQuery from '../../vendor/bases/default.query';

export default class InventorycashQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'inventorycash',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'inventory-cash'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'inventory-cash'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'inventory-cash'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'inventory-cash'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'inventory-cash'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'inventory-cash'));
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`consolidate/${id}`, 'inventory-cash'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'inventory-cash'),
      {
        id: id,
      }
    );
  }
}
