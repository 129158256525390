import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const analytiquePlanFrameConfig = {
  name: 'analytiqueplans',
  title: 'Plan analytique',
  typeFrame: 'list',
  fields: [
    {
      name: 'departement',
      title: 'Département',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'departements',
      selectDataValue: ['name'],
      fieldToTake: ['departement'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '6',
    },
    {
      name: 'departementId',
      title: 'Département',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'departements',
      selectDataValue: {
        name: 'departement',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['departement'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '6',
    },
    {
      name: 'acronym',
      title: 'acronyme',
      col: '6',
    },
    {
      name: 'chargeCategory',
      title: 'nom',
      col: '4',
    },
    {
      name: 'analyticalCode',
      title: 'Code',
      col: '4',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
      col: '4',
    },
  ],
  excludeToHeaders: ['departementId', 'acronym'],
  excludeToForms: ['departement'],
  filterOptions: [
    {
      name: 'libelle',
    },
    {
      name: 'code',
    },
    {
      name: 'acronym',
    },
    {
      name: 'chargeCategory',
      title: 'Catégorie de charge',
    },
    {
      name: 'departementId',
      title: 'département',
      type: 'select',
      optionsTarget: 'departements',
    },
  ],
};

export default BaseConfiguration (analytiquePlanFrameConfig);
