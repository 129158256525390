import Service from '../../vendor/bases/service';

export default class SellsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'sells',
    });
  }
  async cancel (id) {
    return await this.treatQuery (this.query.cancel (id));
  }

  async getCashRegisterSellsResume (cashRegisterId) {
    return await this.treatQuery (
      this.query.getCashRegisterSellsResume (cashRegisterId)
    );
  }

  async unpaidInvoice (customerId = null) {
    return await this.treatQuery (this.query.getUnpaidInvoices (customerId));
  }
}
