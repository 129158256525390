import DefaultQuery from '../../vendor/bases/default.query';

export default class LogsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'logs',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list', 'journals'), params);
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'journals'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'journals'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'journals'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'journals'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'journals'));
  }
}
