import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const configInventoryDepot = {
  name: 'inventorydepot',
  title: 'Inventaire dépôt',
  typeFrame: 'list',
  fields: [
    {
      name: 'inventoryNumber',
      title: 'N°',
    },
    {
      name: 'depot',
      fieldToTake: ['depot'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'depots',
      col: 6,
      selectDataValue: {
        name: 'depot',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'inventoryDate',
      title: 'Date inventaire',
      type: 'date',
      col: 6,
    },
    {
      name: 'depotStockValue',
      type: 'number',
      title: 'Valeur théorique',
    },

    {
      name: 'inventoryStockValue',
      title: 'Valeur inventoriée',
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validée'},
        2: {badgeColor: '#DC143C', text: 'Annulée'},
      },
      type: 'state-multiple',
    },
    {
      name: 'articleInventoriedList',
      title: 'Articles recensée(s)',
      htmlElement: 'dynamicForm',
      fields: [
        {
          name: 'articleId',
          title: 'Article',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'articles',
          fieldToTake: ['article'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        /*{
          name: 'articleName',
          title: 'Nom',
          htmlElement: 'disableInput',
          editableDisable: true,
        },*/
        /*{
          name: 'qtyGet',
          title: 'quantité théorique',
          type: 'number',
          htmlElement: 'disableInput',
          editableDisable: true,
        },*/
        {
          name: 'qtyCount',
          title: 'quantité comptée',
          type: 'number',
        },
        /*{
          name: 'difference',
          title: 'Ecart',
          htmlElement: 'disableInput',
          editableDisable: true,
        },*/
      ],
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Consolidé',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  excludeToForms: [
    'depotStockValue',
    'inventoryNumber',
    'inventoryStockValue',
    'state',
  ],
  excludeToHeaders: ['articleInventoriedList'],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Annulée',
          value: 2,
        },
        {
          label: 'Validée',
          value: 1,
        },
      ],
    },
    {
      name: 'depotId',
      title: 'Depot',
      type: 'select',
      optionsTarget: 'depots',
    },
    {
      name: 'inventoryNumber',
      title: 'N°',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (configInventoryDepot);
