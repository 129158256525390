import {useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './single.css';
import {Button} from '@mui/material';
import {useReactToPrint} from 'react-to-print';
import customerInfo from '../../../../../configs/customer';
import mainTheme from '../../../../../configs/mainTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';


const SingleCustomerDeliver = props => {
  const componentRef = useRef ();

  const deliverInformation = props.data.rows;

  let htCost = 0;
 

  deliverInformation?.articleDelivers?.forEach(
    article => {
      htCost += article?.unitPrice * article?.qtyDelivered
    }
  )

  const ttcCost = htCost;



  const onPrint = useReactToPrint ({
    content: () => componentRef.current,
  });  

  return (
    <div className="p-2">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: 'green', mr: 1}}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
      </div>
      <div className="invoice-container" ref={componentRef}>
        <div className="invoice-header">
            <div className="row gutters text-center mb-2">
              <span className="invoice-logo">
                BON DE LIVRAISON CLIENT
              </span>
            </div>
            <div className="mt-2 d-flex flex-column">
              <div className="d-flex">
                  <span>N° : {' '} </span>
                  <strong className="mx-1"> {deliverInformation?.deliverNumber?.toUpperCase()}</strong>
              </div>
              <div className="d-flex ">
                  <span>Date : {' '} </span>
                  <strong className="mx-1">
                    {deliverInformation?.date ? moment(deliverInformation?.date).format("DD/MM/YYYY") : "Non encore livré"}
                  </strong>
              </div>
            </div>
            {/* Row end */}
            {/* CUSTOMER AND SUPPLIER INFORMATION */}
            <div className="row gutters d-flex justify-content-space-between">
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    FOURNISSEUR
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="ml-1">{customerInfo.name}</strong>
                    <br />
                    Adresse :{' '}
                    <strong className="ml-1">{customerInfo.address}</strong>
                    <br />
                    Tel : <strong className="ml-1">{customerInfo.tel}</strong>
                  </address>
                </div>
              </div>
              <div className="col-6 invoice-details p-2">
                <div className="card p-2">
                  <span className="card-title border-bottom">
                    CLIENT
                  </span>
                  <address>
                    Nom :
                    {' '}
                    <strong className="ml-1">
                      {deliverInformation?.order?.customer?.firstName +
                        ' ' +
                        deliverInformation?.order?.customer?.lastName}
                    </strong>
                    <br />
                    Adresse :
                    {' '}
                    <strong className="ml-1">
                      {deliverInformation?.order?.customer?.addresse || "Non fourni"}
                    </strong>
                    <br />
                    Tel :
                    {' '}
                    <strong className="ml-1">
                      {deliverInformation?.order?.customer?.tel}
                    </strong>
                  </address>
                </div>
              </div>
            </div>
            {/* CUSTOMER AND SUPPLIER INFORMATION END*/}
            <div className="invoice-body my-2">
                  {/* ARTICLES RENDER */}
                  <div className="table-responsive border-bottom ">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                          <TableHead sx={{ bgcolor:mainTheme.color }}>
                              <TableRow>
                                  <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Désignation</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Quantité</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Total</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  deliverInformation?.articleDelivers?.length > 0 ?  deliverInformation?.articleDelivers?.map((row,index) => (
                                      <TableRow
                                          key={row.id}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                          <TableCell component="td" scope="index">
                                              {index+1}
                                          </TableCell>
                                          <TableCell component="td" scope="articleName">
                                              {row?.article?.name}
                                          </TableCell>
                                         <TableCell>
                                            x {row?.qtyDelivered} 
                                          </TableCell>
                                          <TableCell>
                                            {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                      currency: 'XOF',
                                                      
                                            }).format(row?.unitPrice * row?.qtyDelivered)}
                                          </TableCell>
                                      </TableRow>
                                  )) : 
                                      <TableRow>
                                          <TableCell sx={{ textAlign:"center" }} colSpan={3} component="td" scope="row">
                                              Aucun article sur ce bon de livraison :)
                                          </TableCell>
                                      </TableRow>
                              }
                              
                          </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {/* ARTICLES RENDER  END */}
                  <div className="my-3">
                    <div className="d-flex justify-content-end">
                      <p className="text-muted mx-3">Montant HT : </p>
                      <span>{
                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                        currency: 'XOF',
                                        
                              }).format(htCost)
                            }</span>
                    </div>
                  
                    <div className="d-flex justify-content-end">
                      <p className="text-muted mx-5">TVA : </p>
                      <span>{
                         Intl.NumberFormat('fr-FR',{ style: 'currency',
                         currency: 'XOF',
                         
                            }).format(0)
                        }</span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="text-muted mx-3">Montant avancé : </p>
                      <span>{
                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                        currency: 'XOF',
                                        
                              }).format(deliverInformation?.order?.advanceOnOrder)
                            }</span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="text-muted mx-3">Montant remis : </p>
                      <span>{
                              Intl.NumberFormat('fr-FR',{ style: 'currency',
                                        currency: 'XOF',
                                        
                              }).format(deliverInformation?.amountPaid)
                            }</span>
                    </div>
                   
                    <div className="d-flex justify-content-end p-3 bg-light border-top">
                      <h5 className="me-3">Montant TTC:</h5>
                      <h5 className="fw-bold">{
                      Intl.NumberFormat('fr-FR',{ style: 'currency',
                      currency: 'XOF',
                      
                         }).format(ttcCost - deliverInformation?.order?.advanceOnOrder)
                     }</h5>
                    </div>
                  </div>
            </div>
        </div>
              
        <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCustomerDeliver;
