import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const sellsAndWasteBySubfamilyFrameConfig = {
  name: 'sellandwastebyfubfamily',
  title: 'VENTE ET PERTE PAR SOUS-FAMILLE',
  typeFrame: 'list',
  fields: [],
  filterOptions: [
    {
      name: 'subFamilyId',
      title: 'Sous-famille',
      type: 'select',
      optionsTarget: 'articlesubfamilies',
    },
  ],
  hideBar: true,
};

export default BaseConfiguration (sellsAndWasteBySubfamilyFrameConfig);
