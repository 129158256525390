import moment from 'moment';
import BaseBackComponent from '../../../../base-back-component';
import CAStatsByDepartmentRender from './render';

export default class CAStatsByDepartmentComponent extends BaseBackComponent {
  name = 'Statsbydepartement';

  state = {
    ...this.state,
    screen: CAStatsByDepartmentRender,
    searchOptions: {
      ...this.state.searchOptions,
      date: moment (new Date ()).format ('YYYY-MM-DD HH:mm:ss') +
        ',' +
        moment (new Date ()).format ('YYYY-MM-DD HH:mm:ss'),
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
