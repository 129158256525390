import BaseBackScreen from '../base-back-screen';
import CloseDialog from './_layouts/close';
import InventoryDialog from './_layouts/InventoryDialog';
import StockAddComponent from './_layouts/stock-add';

export default class ArticlesScreen extends BaseBackScreen {
      name = 'Articles';

    loadOtherDialogContent(actionName,title=null,dataId=null, config = null){
        let otherContent = {};
        

        if(actionName === 'inventory'){
            this.loadArticleDepots(dataId);
            let data = {
                depots : this.state.depots,
                articleName : this.state.depotName,
                articleUnit : this.state.articleUnit
            };  
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : InventoryDialog,
                data : data,
                size : config?.dialogsConfig?.inventoryDialog?.size || "sm",
            }
        }else if(actionName === "closed"){
            let data = {
                depots : this.state.depots,
                articleName : this.state.depotName,
                articleUnit : this.state.articleUnit
            };  
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : CloseDialog,
                data : data,
                size : config?.dialogsConfig?.closedDialog?.size || "sm",
            }
        }else if(actionName === "stock-add"){
            const depots = this.props.data.loadDepots();

            let data = {
                stockAddForm : this.props.data.stockAddForm,
                articleId : dataId,
               depots: depots
            };  
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : StockAddComponent,
                data : data,
                size : config?.dialogsConfig?.stockAddDialog?.size || "sm",
            }
        }
        else{
            otherContent = {
                actionName : actionName,
                componentName :  title,
                dataReference : dataId,
                validAction :this.props.data?.screenActions?.validOtherAction 
            }
        }
        
        return otherContent;
    }

    otherUpdate(prevProps){
        if(this.props.data !== prevProps.data){
            if(this.props.data?.articleName){
                this.setState({
                    dialogInformation : {
                        ...this.state.dialogInformation,
                        dialogContent : {
                            ...this.state.dialogInformation.dialogContent,
                            data : {
                                ...this.state.dialogInformation?.dialogContent?.data,
                                depots : this.props.data?.depots,
                                articleName : this.props.data?.articleName,
                                articleUnit : this.props.data?.articleUnit,

                            }
                        }
                    }
                });
            }
            
            if(this.props.data.stockAddForm !== prevProps.data.stockAddForm){
                this.setState({
                    dialogInformation : {
                        ...this.state.dialogInformation,
                        dialogContent : {
                            ...this.state.dialogInformation.dialogContent,
                            data : {
                                ...this.state.dialogInformation?.dialogContent?.data,
                                stockAddForm : {
                                    ...this.state.dialogInformation?.dialogContent?.data.stockAddForm,
                                    payLoad : this.state.dialogInformation?.dialogContent?.data.stockAddForm.payLoad
                                }
                            }
                        }
                    }
                })
            }
        }
    }

    loadArticleDepots(id){
        this.props.data.loadArticleDepots(id);
    }

    articleClosed(articleId){
        this.props.data.articleClosed(articleId);
    }   
}
