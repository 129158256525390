import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const customerCategoriesFrameConfig = {
  name: 'customercategories',
  title: 'Catégorie(s) client',
  typeFrame: 'list',
  fields: [
    {
      name: 'libelle',
      title: 'Libéllé',
    },
    {
      name: 'exemptionList',
      title: "Liste d'exemption",
      fields: [
        {
          name: 'departementId',
          title: 'Departement',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'departements',
          selectDataValue: {
            name: 'departement',
            options: {
              valueOptions: ['id'],
              labelOptions: ['name'],
            },
          },
          fieldToTake: ['departement'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        {
          name: 'exemptionRate',
          title: "Pourcentage d'exemption",
          type: 'number',
        },
      ],
      htmlElement: 'dynamicForm',
    },
    {
      name: 'majorationList',
      title: 'Liste de majoration',
      fields: [
        {
          name: 'departementId',
          title: 'Departement',
          htmlElement: 'select',
          options: [],
          dataServiceSource: 'departements',
          selectDataValue: {
            name: 'departement',
            options: {
              valueOptions: ['id'],
              labelOptions: ['name'],
            },
          },
          fieldToTake: ['articleSubFamily'],
          type: 'objectFetcher',
          fieldsSelected: ['name'],
        },
        {
          name: 'majorationAmount',
          title: 'Pourcentage de majoration',
          type: 'number',
        },
      ],
      htmlElement: 'dynamicForm',
    },
  ],
  filterOptions: [
    {
      name: 'libelle',
    },
  ],
  excludeToHeaders: ['majorationList', 'exemptionList'],
};

export default BaseConfiguration (customerCategoriesFrameConfig);
