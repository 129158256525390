import BaseBackComponent from './../../../base-back-component';
import CltinvoiceService
  from './../../../../../external-communication/services/cltinvoice.service';
import ClientInvoiceScreen from './screen';

export default class ClientInvoices extends BaseBackComponent {
  name = 'client-invoices';

  options = [
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    }
  ];
  
  state = {
    ...this.state,
    screen: ClientInvoiceScreen,
    searchOptions: {
      ...this.state.searchOptions,
      status: null,
      customerId: null,
    },
  };

  async componentDidMount () {
    this.loadOptions();
  }

  async componentDidUpdate(prevProps,prevState){
    if(this.props.params !== prevProps.params){
        await this.setState ({
            service: new CltinvoiceService (),
            searchOptions: {
              ...this.state.searchOptions,
              customerId: this.props.params?.clientId,
            },
        });
          
        this.initDataLoading ();
    }
  }
}
