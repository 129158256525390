import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const sellsEvolutionFrameConfig = {
  name: 'sellresumepersubfamily',
  title: 'RESUME DE VENTE PAR SOUS-FAMILLE',
  typeFrame: 'list',
  fields: [],
  filterOptions: [
    {
      name: 'date',
      type: 'interval-time',
    },
  ],
  hideBar: true,
};

export default BaseConfiguration (sellsEvolutionFrameConfig);
