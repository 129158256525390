import DefaultQuery from '../../vendor/bases/default.query';

export default class SupplierdeliveriesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'supplierdeliveries',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'supplier-deliveries'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'supplier-deliveries'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (
      this.buildPath (`${id}`, 'supplier-deliveries')
    );
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'supplier-deliveries'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'supplier-deliveries'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'supplier-deliveries')
    );
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`validate/${id}`, 'supplier-deliveries'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`cancel/${id}`, 'supplier-deliveries'),
      {
        id: id,
      }
    );
  }

  async getUnpaidInvoice (id) {
    return await this.getMethod (
      this.buildPath (`unpaid/${id}`, `supplier-deliveries`)
    );
  }
}
