import BaseBackComponent from './../base-back-component';
import DepotsService from './../../../external-communication/services/depots.service';

export default class InventorydepotComponent extends BaseBackComponent {
  name = 'Inventorydepot';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      inventoryNumber: null,
      state: null,
      depotId: null,
    },
  };

  options = [
    {
      title: 'depots',
      keys: ['name'],
    },
    /*{
      title: 'articles',
      keys: ['name'],
    },*/
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment (name, value) {
    if (name === 'depot') {
      let articles = [];
      //let newArticlesFormValue = null;

      if(value && value !== ""){
        const queryDepotArticles = await new DepotsService ().getArticles (
          value
        );

        if(queryDepotArticles?.success){
          let newArticlesOptions = [];
          articles = queryDepotArticles?.data?.articleStock;

          let newOptions = this.state.options;
          
          const articlesOptionsIndex = newOptions.findIndex(
            option => option.title === "articles"
          );
      
          if(articlesOptionsIndex !== -1){
              newOptions.splice(articlesOptionsIndex,1);
          }
          
          articles.forEach(
            article => {
              const option = {
                value : article.id,
                label: article.articleName
             
              };
              newArticlesOptions.push(option);  
            }
          );
          console.log(newArticlesOptions);

          newOptions.push({title : "articles",options : newArticlesOptions});
      
          await this.setState({
            options : newOptions,
          });

        }
      }
    }
  }

  /*async handleSubForm (index, name, value, currentPayLoad = null) {
    if(name === "qtyCount"){
      if(value && value !== ""){
        const difference  = value - currentPayLoad[index]?.qtyGet?.value;
        currentPayLoad[index].difference = {value : difference};
      }else{
        const difference  = currentPayLoad[index]?.qtyGet?.value;
        currentPayLoad[index].difference = {value : - difference+""};
      } 
      
      await this.setState(
        (state) => ({
          formPayload : {
            ...state.formPayload,
            articleInventoriedList : {value : currentPayLoad},
          }
      }));
    }
  }*/
}
