import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const ProductSellsPriceFrameConfig = {
  name: 'productsellsprice',
  title: 'MAJ Prix & Analyse article',
  typeFrame: 'list',
  fields: [
    {
      name: 'articleFamily',
      title: 'Famille',
    },
    {
      name: 'articleSubFamily',
      title: 'Sous-famille',
    },
    {
      name: 'code',
      title: 'Code article',
    },
    {
      name: 'article',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'finishedProducts',
      fieldToTake: ['article'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'articleId',
      title: 'article',
      col: '6',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'finishedProducts',
      selectDataValue: {
        name: 'articleId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'analyticalPlanId',
      title: 'Code analytique',
      col: '6',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'analyticalplans',
      selectDataValue: {
        name: 'analyticalPlan',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'purchasePrice',
      title: 'Coût de reviens',
      type: 'money',
      col: '6',
    },
    {
      name: 'sellingPrice',
      title: 'Prix de vente',
      type: 'money',
      col: '6',
    },
    {
      name: 'startDate',
      title: 'Date début',
      type: 'date',
    },
    {
      name: 'endDate',
      title: 'Date fin',
      type: 'date',
    },
    {
      name: 'weight',
      title: 'Poids unitaire',
    },
    {
      name: 'rangeValue',
      title: 'Marge en valeur',
    },
    {
      name: 'rangePercentage',
      title: 'Marge en pourcentage',
    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToHeaders: [
    'articleId',
    'startDate',
    'endDate',
    'state',
    'analyticalPlanId',
  ],
  excludeToForms: [
    'startDate',
    'endDate',
    'state',
    'article',
    'weight',
    'articleFamily',
    'code',
    'articleSubFamily',
    'rangePercentage',
    'rangeValue',
  ],
  filterOptions: [
    {
      name: 'name',
      title: 'Nom article',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
    inventoryDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (ProductSellsPriceFrameConfig);
