import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FrameTitle from './../../../vendor/builders/Frame/_layouts/title/index';
import { Fragment, useRef, useState } from 'react';
import DateRangePickerValue  from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';
import mainTheme from './../../../configs/mainTheme';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import {Button} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const currentYear = new Date ().getFullYear ();

const years = () => {
  let yearsCollection = [];
  for (let i = 2010; i <= currentYear; i++) {
    yearsCollection.push ({value: `${i}`,label : `${i}`});
  }
  return yearsCollection;
};

export default function LogRender (props) {


    let renderConfigs = props.renderFrameConfigs;


    const logData = transformDataToLogFormat(renderConfigs?.fetchData?.items || []);

    let creditSum = 0;
    let debitSum = 0;

    renderConfigs?.fetchData?.items.forEach(element => {
        if(element.credit){
            creditSum += element.credit;
        }
        if(element.debit){
            debitSum += element.debit;
        }
    });

    const elementIndex = years().findIndex(item => item.value  === currentYear)
    
    const [currentExercise,setCurrentExercise] = useState(elementIndex !== - 1 ? years()[elementIndex] : years()[years().length - 1])
    
    const changeYear = (e, value = null) => {
        if(value){
            renderConfigs.handleSearchOption(e,value?.value,"exercise");
        }else{
            renderConfigs.handleSearchOption(e,null,"exercise");
        }
        setCurrentExercise(value);
    }

    const changeDateValue = (e,value = null) => {
        renderConfigs.handleSearchOption(e,value,"date");
    }

    const componentRef = useRef();
    const onPrint =   useReactToPrint({
        content : () => componentRef.current
    });

    const completePeriod = currentExercise?.value ||  "";

    return (
        <div className="card ledger-template">
             <div className="d-flex m-2" >
                <Button
                    variant="outlined"
                    sx={{color: 'white', bgcolor: 'green', mr: 1}}
                    startIcon={<FontAwesomeIcon icon="print" />}
                    onClick={onPrint}
                >
                    Imprimer
                </Button>
       
            </div>


            <div ref={componentRef} className="p-2">
                <FrameTitle title={"JOURNAL COMPTABLE " +completePeriod} />

                <div className="col-12 d-flex justify-content-end mt-3">
                    <Autocomplete
                        id="exercise"
                        options={years()}
                        onChange={(e,value) => {
                            changeYear(e,value)
                        }}
                        getOptionLabel={option => option.label}
                        isOptionEqualToValue={option => option.value === currentExercise?.value}
                        size="small"
                        sx={{ width:150,mr:1 }}
                        renderInput={(params) => <TextField {...params} label={`Exercice`} />}
                        value={currentExercise}
                    />
                </div>

                <div className='row mt-2 p-2 mb-2'>
                    <div className="col-12 col-md-8 col-xl-8 col-lg-8 d-flex justify-content-start m-auto">
                        <DateRangePickerValue size="medium" changeSearchValue={changeDateValue} data={{
                            name : 'date'
                        }}/>
                    </div>
                    <div className="col-12 col-md-4 col-xl-4 col-lg-4 d-flex justify-content-end">
                        <div className="d-flex flex-column">
                            <div className="p-2 text-white" style={{ backgroundColor : mainTheme.mainColor }}>
                                Solde actuelle : <span className="fw-bold">{Intl.NumberFormat('fr-FR',{
                                                                                        style: 'currency',
                                                                                        currency: 'XOF',
                                                                                        
                                                                                    }).format(creditSum - debitSum)}</span> 
                            </div>   
                        </div>
                        
                    </div>
                </div>

                <div className="col-12 p-2">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th>N° </th>
                                <th>Date </th>
                                <th>N° de compte</th>
                                <th>Libéllé</th>
                                <th className="text-center">Crédit </th>
                                <th className="text-center">Débit </th>
                            </tr>
                            {
                                logData?.map(
                                    (logRow,index) => {
                                        return <tr key={logRow.operationDate}>
                                                    <th align="left">
                                                        {index+1}
                                                    </th>
                                                    <td>{moment(logRow.operationDate).format("DD/MM/YYYY")}  </td>
                                                    <td>
                                                        <div className="row">
                                                            {
                                                                logRow.rows.map(
                                                                    (row,i) => {
                                                                        return <Fragment key={logRow.operationDate+"row"+row.id+"number"}>
                                                                                    <div className='col-6 text-center border-right'>
                                                                                        {
                                                                                            row?.credit ? row?.accountNumber
                                                                                            : null
                                                                                        }    
                                                                                    </div>
                                                                                    <div className='col-6 border-right'>
                                                                                        {
                                                                                            row?.debit ? row?.accountNumber
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                        </Fragment>
                                                                    }   
                                                                )
                                                            }
                                                            
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {
                                                            logRow.rows.map(
                                                                    (row,i) => {
                                                                    return <div key={logRow.operationDate+"row"+row.id+"label"}>
                                                                                {
                                                                                    row?.libelle
                                                                                }    
                                                                            </div>
                                                                    }   
                                                            )
                                                        }
                                                            
                                                    </td>
                                                    <td>
                                                        {
                                                            logRow.rows.map(
                                                                    (row,i) => {
                                                                    return <div className="text-center" key={logRow.operationDate+"row"+row.id+"credit"}>
                                                                                {
                                                                                    row?.credit ? Intl.NumberFormat('fr-FR',{
                                                                                        style: 'currency',
                                                                                        currency: 'XOF',
                                                                                        
                                                                                    }).format(row?.credit): "-"
                                                                                }    
                                                                            </div>
                                                                    }   
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            logRow.rows.map(
                                                                    (row,i) => {
                                                                    return <div className="text-center" key={logRow.operationDate+"row"+row.id+"credit"}>
                                                                                {
                                                                                    row?.debit ? "- " + 
                                                                                    Intl.NumberFormat('fr-FR',{
                                                                                        style: 'currency',
                                                                                        currency: 'XOF',
                                                                                        
                                                                                    }).format(row?.debit) : "-"
                                                                                }    
                                                                            </div>
                                                                    }   
                                                            )
                                                        } 
                                                    </td>
                                                </tr>
                                    }
                                )
                            }                    
                            <tr>
                                <td colSpan={4}></td>
                                <td className="text-center bg-dark">
                                <span className="text-white text-decoration-underlined">Total : </span>
                                <span className="text-white text-decoration-underlined">
                                            {
                                                    Intl.NumberFormat('fr-FR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'XOF',
                                                            
                                                        }).format(creditSum)
                                            }
                                </span>
                                </td>
                                <th className="text-center  bg-dark">
                                    <span className="text-white text-decoration-underlined"> 
                                        Total : - {
                                                    Intl.NumberFormat('fr-FR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'XOF',
                                                            
                                                        }).format(debitSum)
                                                }
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
           
        </div>
    );
}


function transformDataToLogFormat(data){
    let newData = [];   
    data.forEach(element => {
        const findDateInNewData = newData.find(item => item.operationDate === element.date);
        if(!findDateInNewData){
            newData =  [...newData,{
                operationDate : element.date,
                rows : data.filter(item => item.date === element.date)
            }];
        }
        
    });
    return newData;
}