import DefaultQuery from '../../vendor/bases/default.query';

export default class StockentriesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'stockentries',
    });
  }

  async readAll () {
    return await this.getMethod (this.buildPath ('list', 'stock-entries'));
  }
}
