import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const cashflowMovementFrameConfig = {
  name: 'cashflowmovement',
  title: 'Trésorerie',
  typeFrame: 'list',
  fields: [
    {
      name: 'cashFlowNumber',
      title: 'N°',
    },
    {
      name: 'date',
      title: 'date',
      type: 'date',
    },
    {
      name: 'amount',
      title: 'Montant',
      type: 'money',
    },
    {
      name: 'paymentMode',
      title: 'Mode de paiement',
    },
    {
      name: 'type',
      title: 'Nature',
      options: {
        0: {badgeColor: '#5F9EA0', text: 'encaissement', textColor: 'white'},
        1: {badgeColor: '#DC143C', text: 'decaissement'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: [
    'type',
    'date',
    'paymentMode',
    'amount',
    'cashFlowNumber',
    'date',
  ],
  actions: [],
  filterOptions: [
    {
      name: 'type',
      title: 'Type',
      type: 'select',
      options: [
        {label: 'Encaissement', value: 0},
        {label: 'Décaissement', value: 1},
      ],
    },
    {
      name: 'paymentMode',
      title: 'Mode de paiement',
      type: 'select',
      options: [
        {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
        {value: 'TPE', label: 'TPE'},
        {value: 'BANQUE', label: 'BANQUE'},
        {value: 'CASH', label: 'CASH'},
      ],
    },
    {
      name: 'date',
      title: 'date',
      type: 'interval',
    },
  ],
};

export default BaseConfiguration (cashflowMovementFrameConfig);
