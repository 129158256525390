import React from 'react';
import DailySellsComponent from './daily-sells';
import AnalysisMarginComponent from './analysis-margin';
import StatsTemplate from '../stats.template';
import SellandwastebyarticleComponent from '../../../Sellsandwastebyarticle';
import SellsandwastebysubfamilyComponent
  from '../../../Sellsandwastebysubfamily';
import SellResumePersubFamilyComponent from './sell-resume-per-subfamilly';

export default function StatsBySales () {
  return <StatsTemplate components={components} />;
}

const components = [
  {
    title: 'sellresumepersubfamily',
    label: 'RESUME DE VENTE PAR SOUS-FAMILLE',
    renderComponent: SellResumePersubFamilyComponent,
    isVisible: true,
  },
  
  {
    title: 'dailySells',
    label: 'Vente journalière',
    renderComponent: DailySellsComponent,
    isVisible: true,
  },
  {
    title: 'sellAndWasteByArticle',
    label: 'Vente et perte article',
    renderComponent: SellandwastebyarticleComponent,
    isVisible: true,
  },
  {
    title: 'sellAndWasteBySubfamily',
    label: 'Vente et perte sous-famille',
    renderComponent: SellsandwastebysubfamilyComponent,
    isVisible: true,
  },
  {
    title: 'marginAnalysis',
    label: 'Analyse des marges',
    renderComponent: AnalysisMarginComponent,
    isVisible: true,
  },
];
