import {BarChart} from '@mui/x-charts/BarChart';
import 
  DateTimeRangePickerValue from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/date-time';
import {Card, CardContent} from '@mui/material';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RenderSalesByWeek (props) {
  let renderConfigs = props.renderFrameConfigs;

  const changeDateValue = (e, value = null) => {
    renderConfigs.handleSearchOption (e, value, 'date');
  };

  let series = [];

  let sold = 0;

  if (renderConfigs.fetchData) {
    for (let data in renderConfigs.fetchData) {
      sold += renderConfigs.fetchData[data];
      series.push ({
        data: [renderConfigs.fetchData[data]],
        stack: data,
        label: data,
      });
    }
  }

  return (
    <div className="">
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />
      <div className="my-2 p-2">
        <DateTimeRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>

      <div className="col my-2">
          <div className="card radius-10 border-start border-0 border-3 border-info">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">C.A.</p>
                  <h5 className="my-1 text-info">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF',
                                
                        }).format(sold)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
      </div>    


      <Card sx={{width: '100%'}}>
        <CardContent>
          <BarChart
            series={series}
            height={350}
            colors={['black','yellow',"blue",'orange',"purple","indigo",'skyBlue',"red",'green',"#00FFFF","#A52A2A","#00008B"]}
            yAxis={[
              {
                min: 10,
                max:  100000000,
              },
            ]}
            xAxis={[
              {
                data: ['VENTE PAR SEMAINE'],
                scaleType: 'band',
              },
            ]}
            margin={{top: 60, bottom: 30, left: 40, right: 10}}
          />
        </CardContent>
      </Card>
    </div>
  );
}
