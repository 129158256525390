import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FrameTitle from "../../../vendor/builders/Frame/_layouts/title";
import {  Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";

export default function RenderSellsResumes (props) {
  let renderConfigs = props.renderFrameConfigs;


  const data = renderConfigs?.fetchData || [];
  
  let sold = 0;

  if (renderConfigs.fetchData) {
    data.forEach(element => {
      sold += element.debt
    });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return <div className="">
            <FrameTitle
              name={renderConfigs?.name}
              title={renderConfigs?.title}
              openDialog={(e) => {}}
              actions={[]}
            />

            <div className="col my-2">
              <div className="card radius-10 border-start border-0 border-3 border-info">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">TOTAL</p>
                      <h5 className="my-1 text-info">  
                          { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                    currency: 'XOF',
                                    
                            }).format(sold)
                          } </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                      <FontAwesomeIcon icon="wallet" />
                    </div>
                  </div>
                </div>
              </div>
          </div> 
            
            <Box sx={{ width: '100%' , my:3}}>     
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 450,border: 1 }}>
                  <Table stickyHeader size={'small'}>
                    <TableHead >
                      <TableRow sx={{ backgroundColor : 'black', color : 'white'}}>
                        <StyledTableCell>
                          Client
                        </StyledTableCell>
                        <StyledTableCell>
                          Montant
                        </StyledTableCell>
                      </TableRow>
                   </TableHead>
                    <TableBody>
                      {
                        data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(
                          (option,index) => <StyledTableRow  key={option.customerName}>
                                        <StyledTableCell >
                                          {option.customerName}
                                        </StyledTableCell>
                                        <StyledTableCell >
                                          {option?.debt?.toLocaleString("fr-FR")}
                                        </StyledTableCell>
                                    
                                      
                                    </StyledTableRow>

                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>  
            </Box>
   </div>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
