import * as React from 'react';
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {BarChart} from '@mui/x-charts/BarChart';

const otherSetting = {
  height: 400,
  width: 850,
  grid: {horizontal: true},
  sx: {
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-10px)',
    },
  },
};

export default function BarChartBuilder (props) {
  
  return (
    <BarChart
      xAxis={[
        {
          data: props?.xAxis,
          scaleType: 'band',
        },
      ]}
      barLabel={(item, context) => {
        
        return  item.value?.toLocaleString('fr-FR');
      }}
      colors={props.colors}
      series={props?.series}
      {...otherSetting}
    />
  );
  
}


/*function formatToSeries(series){
//console.log("series : ",series)

   const s = series[0].data.map(
    ss => {
      let data = [];
      data.push(ss);
      return {
        data : data
      }
    }
   )
   
  console.log("new series: ",s);

  return s;
}*/
