import {Autocomplete, Card, TextField} from '@mui/material';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import { useState } from 'react';
import ChartBuilder from '../../../vendor/builders/Chart';

const currentYear = new Date ().getFullYear ();

const years = () => {
  let yearsCollection = [];
  for (let i = 2010; i <= currentYear; i++) {
    yearsCollection.push ({value: `${i}`,label : `${i}`});
  }
  return yearsCollection;
};



function formatToCompleteYear(data){
  let series = [];  
  
  for(let i = 0; i < 12; i++){
    series.push(
      {
        month : i,
        sellQty : 0,
        wasteQty : 0,
      }
    )
  }

  for(let j in data){
    let index = series.findIndex(ser => ser.month === (data[j].month - 1));
    series[index].sellQty = data[j]?.sellQty;
    series[index].wasteQty = data[j]?.wasteQty;
  }

  return series;
}

export default function RenderSellAndWasteByArticle (props) {
  let renderConfigs = props.renderFrameConfigs;

  const elementIndex = years().findIndex(item => item.value  === currentYear)

  const [currentExercise,setCurrentExercise] = useState(elementIndex !== - 1 ? years()[elementIndex] : years()[years().length - 1]);

//  console.log(renderConfigs?.options)

  const articles = renderConfigs?.options?.find(option => option.title === "articles")?.options || [];

  const changeYear = (e, value = null) => {
    if(value){
        renderConfigs.handleSearchOption(e,value?.value,"year");
    }else{
        renderConfigs.handleSearchOption(e,null,"year");
    }
    setCurrentExercise(value);
  }

  const changeArticle = (e,value = null) => {
    if(value){
      renderConfigs.handleSearchOption(e,value?.value,"articleId");
    }else{
        renderConfigs.handleSearchOption(e,null,"articleId");
    }
    setArticle(value);
  }


  const [article,setArticle] = useState();

  

  let xLabels = [];


  let sellSeries = [];
  let wastesSeries = [];
  
  if (renderConfigs.fetchData) {

    let donnees = formatToCompleteYear(renderConfigs.fetchData)
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    

    for (let data in donnees) {
      xLabels.push(month[donnees[data]?.month]);
      sellSeries.push(donnees[data]?.sellQty)
      wastesSeries.push(donnees[data]?.wasteQty);
    }
    
  }

  return (
    <div >
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />

        <div className="col-12 d-flex justify-content-start my-3">
            <Autocomplete
                id="year"
                options={years()}
                onChange={(e,value) => {
                    changeYear(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === currentExercise?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Exercice`} />}
                value={currentExercise}
            />
            <Autocomplete
                id="articleId"
                options={articles}
                onChange={(e,value) => {
                    changeArticle(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === article?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Article`} />}
                value={article}
            />
        </div>

        <Card sx={{ width:"100%", p:2 }}>
            <div className="d-flex justify-content-center">
              <ChartBuilder
                  chartType="bar"
                  series={[  { data: sellSeries },
                    { data: wastesSeries }]} 
                  xAxis={xLabels}
              />
            </div>    
        </Card>
    </div>
  );
}

