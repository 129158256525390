import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const stockEntriesFrameConfig = {
  name: 'stockentries',
  title: 'Entrée(s) en stock',
  typeFrame: 'list',
  fields: [
    {
      name: 'depotId',
      title: 'Dépôt',
    },
    {
      name: ' supplierDeliveryId',
      title: 'Bon de livraison',
    },
  ],
};

export default BaseConfiguration (stockEntriesFrameConfig);
