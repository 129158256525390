import BaseBackComponent from '../base-back-component';
import SupplierordersService
  from './../../../external-communication/services/supplierorders.service';

export default class SupplierdeliveriesComponent extends BaseBackComponent {
  name = 'Supplierdeliveries';

  options = [
    {
      title: 'supplierorders',
      keys: ['orderingNumber'],
      method: 'onlyValidOrders',
    },
    {
      title: 'depots',
      keys: ['name'],
    }
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment (name, value) {
    if (name === 'supplierOrderId') {
      let articles = [];
      let newArticlesFormValue = null;

      if(value && value !== ""){
        const queryOrderArticles = await new SupplierordersService ().orderArticles (
          value
        );
        if(queryOrderArticles?.success){
          articles = this.loadOrderArticle(queryOrderArticles?.data);
          newArticlesFormValue = this.setPayloadData(queryOrderArticles?.data);
        }
      }
      
      let newOptions  = this.state.options; 
      
      const indexOfArticleOptions = newOptions?.findIndex(item => item.title === "articles");

      if(indexOfArticleOptions !== -1){
        newOptions.splice(indexOfArticleOptions,1);
      }

      newOptions.push({
        title : "articles",
        options : articles
      })
      
      
      await this.setState({
        options: newOptions,
        formPayload : {
          ...this.state.formPayload,
          rawMaterialTodelivery : {
            value : newArticlesFormValue 
          }
        }
      })

     
    }
    if(name === 'rawMaterialTodelivery'){
      //console.log('qtyValue is', value)
    }
  }

  setPayloadData(articles){
    let data = [];
    articles.forEach(
      item => {
        const qtyRest = item?.qtyOrdered - item?.qtyDelivered;
        if(qtyRest > 0){
          data.push({
            articleName : {value : item?.article?.name},
            rawMaterialId : {value : item?.article?.id},
            qty : {value : ""+qtyRest,maxValue : ""+item?.qtyOrdered},
            qtyRest : {value : ""+qtyRest},
            expiryDate: {value : item.expiryDate}
          })
        }
      }
    )

    return data;
  }

  loadOrderArticle(articles){
    return articles.map (article => {
      return {
        value: article.article.id,
        label: article.article.name,
      };
    });
  }

  otherSingleDataInit (data) {
    const articleList = data?.rawMaterialDeliveredList.value.map(
      article => {
        return {
          rawMaterialId : {value : article.article.id},
          articleName : {value : article.article.name},
          qty : {value : article.qtyDelivered, maxValue : article.qtyDelivered},
          qtyRest : {value : article?.qtyDelivered - article?.qtyOrdered},
          expiryDate: {value : article.expiryDate}
        }
      }
    )  
    data = {
      ...data,
      supplierOrderId : {value : data?.supplierOrder?.value?.id},
      depotId : {value : data?.depot?.value?.id},
      rawMaterialTodelivery : {value : articleList}
    };
    return data;
  }
}
