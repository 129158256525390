import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';
import SingleCashierCard from './_layouts/SingleCard';

const cashRegistersFrameConfig = {
  name: 'cashregisters',
  title: 'Caisse(s)',
  typeFrame: 'list',
  listElementsType: 'card',
  listSingleElementRender: SingleCashierCard,
  fields: [
    {
      name: 'reference',
      title: 'N°',
    },
    {
      name: 'userId',
      title: 'Responsable',
      htmlElement: 'select',
      options: [],
      col: '4',
      dataServiceSource: 'users',
      selectDataValue: {
        name: 'userId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['firstName', 'lastName'],
        },
      },
      fieldToTake: ['user'],
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
    },
    {
      name: 'depotId',
      title: 'Dépot',
      htmlElement: 'select',
      options: [],
      col: '4',
      dataServiceSource: 'depots',
      selectDataValue: {
        name: 'depotId',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['user'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'balance',
      title: 'Solde',
      type: 'money',
    },
    {
      name: 'openingDate',
      type: 'dateTime',
      title: 'Date ouverture',
    },
    {
      name: 'closingDate',
      type: 'dateTime',
      title: 'Date fermerture',
    },
    {
      name: 'openingBalance',
      type: 'number',
      title: 'Solde ouverture',
      col: '4',
    },
    {
      name: 'user',
      title: 'Responsable',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'users',
      type: 'objectFetcher',
      fieldsSelected: ['firstName', 'lastName'],
    },
    {
      name: 'depot',
      title: 'Dépot',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'depots',
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Fermée', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Ouverte'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: [
    'reference',
    'balance',
    'openingDate',
    'closingDate',
    'depot',
    'user',
    'state',
  ],
  excludeToHeaders: ['depotId', 'userId', 'openingBalance', 'depot'],
  filterOptions: [
    {
      name: 'userId',
      title: 'Responsable',
      type: 'select',
      optionsTarget: 'users',
    },
    {
      name: 'date',
      title: 'Date ouverture',
      type: 'interval-time',
    },
    {
      name: 'state',
      title: 'statut',
      type: 'select',
      options: [
        {label: 'Ouverte', value: true},
        {label: 'Fermée', value: false},
      ],
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'close',
      title: 'Fermer',
      color: 'danger',
      icon: 'times',
      elementTest: 'state',
      value: true,
      operator: 'equal',
    },
    {
      name: 'resume',
      title: 'Système',
      color: 'dark',
      icon: 'paste',
      elementTest: 'state',
      value: false,
      operator: 'equal',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
    inventoryDialog: {
      size: 'md',
    },
    resumeDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (cashRegistersFrameConfig);
