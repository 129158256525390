import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const articlesubfamilliesFrameConfig = {
  name: 'articlesubfamillies',
  title: "Sous famille d'article",
  typeFrame: 'list',
  fields: [
    {
      name: 'familyId',
      title: 'Famille',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      selectDataValue: {
        name: 'articleFamily',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
      fieldToTake: ['articleFamilly'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
      col: '6',
    },
    {
      name: 'articleFamily',
      title: "Famille d'article",
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'name',
      title: 'nom',
      col: '6',
    },
  ],
  excludeToHeaders: ['familyId'],
  excludeToForms: ['articleFamily'],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'familyId',
      title: "Famille d'article",
      type: 'select',
      optionsTarget: 'articlefamillies',
    },
  ],
};

export default BaseConfiguration (articlesubfamilliesFrameConfig);
