import Service from '../../vendor/bases/service';

export default class RolesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'roles',
    });
  }

  async loadAuthorities (id) {
    return await this.treatQuery (this.query.loadAuthorities (id));
  }

  async loadAuthoritiesByResourceId (id) {
    return await this.treatQuery (this.query.loadAuthoritiesResourceId (id));
  }

  async setAuthorities (credentials) {
    return await this.treatQuery (
      this.query.setAuthorities (credentials.role_id, credentials)
    );
  }

  async getRoleAuthorities (id) {
    return await this.treatQuery (this.query.getRoleAuthorities (id));
  }
}
