import BaseBackComponent from '../base-back-component';

export default class ArticlesubfamilliesComponent extends BaseBackComponent {
  name = 'Articlesubfamillies';

  options = [
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      familyId : {value : data?.articleFamily?.value?.id}
    };
    return data;
  }
}
