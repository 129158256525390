import BaseBackComponent from '../base-back-component';

export default class ProductsellspriceComponent extends BaseBackComponent {
  name = 'Productsellsprice';

  options = [
    {
      title: 'finishedProducts',
      keys: ['name'],
      source: 'articles',
      method: 'onlyFinishedProductsAndNegoce',
    },
    {
      title : 'analyticalplans',
      keys : ['analyticalCode'],
      source : 'analytiqueplans'
    }
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      articleId : {value : data?.article?.value?.id},
      analyticalPlanId : {value : data?.analyticalPlan?.value?.id}      
    } 
    return data;
  }
}
