import BaseBackComponent from '../base-back-component';

export default class ArticlefamilliesComponent extends BaseBackComponent {
  name = 'Articlefamillies';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      name: null,
    },
  };
  componentDidMount () {
    this.initDataLoading ();
  }
}
