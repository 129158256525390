import Service from '../../vendor/bases/service';

export default class ExpensesService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'expenses',
    });
  }

  performed = async id => {
    return await this.treatQuery (this.query.performedExpense (id));
  };

  approved = async id => {
    return await this.treatQuery (this.query.approvedExpense (id));
  };
}
