import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const PConfigDialog = props => {
  const [name,setName] = useState('');

  const modules = props.data?.data?.resources || [];
 
  const selectOption = (value) => {
      if(value){
        setName(value.title);
        props.data?.data?.callAuthoritiesResources(value.id);
      }else{
        setName(null);
      }
  }

  return (
    <Fragment>
      <div className="p-3 my-2 bg-primary text-white fw-bold">
        Configuration droits : {props.data?.data?.roleName?.toUpperCase() || ''}
      </div>
      
      <div className="mt-3 mb-1">
        <div className="col-12">
          <Autocomplete
            id="module"
            options={modules} 
            getOptionLabel={(option) => option.title}
            renderInput={params => <TextField  
                inputProps={{
                    autoComplete: 'off'
                }}
              {...params} label="Module" />}
            onChange={(event, newValue) => {
              event.preventDefault();
              selectOption(newValue);
            }}
          />
        </div>
      </div>
      <AccessRightList 
          currentAuthorities={props.data.data?.roleAuthorities?.authorities} 
          resourceName={name} 
          actions={props.data?.data?.authorities || []}
          switchAuthority={props.data?.data?.handleAuthorities}
          checkAll={props.data?.data?.checkAll}
          saveChange={props.data?.data?.saveChanges}
        />
    </Fragment>
  );
};


class AccessRightList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      allChecked : false,
      searchValue : "",
      actions : [],
      checks : []
    }

    this.handleToggle = this.handleToggle.bind(this);
    this.searchAction = this.searchAction.bind(this);
    this.filterArrayActions = this.filterArrayActions.bind(this);
    this.checkAllSwitch = this.checkAllSwitch.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.allCheckStatus = this.allCheckStatus.bind(this);
  }

  componentDidMount(){
    this.setState({
      actions : this.props.actions || []
    })
  }


  async componentDidUpdate(prevProps,prevState){
    if(this.props !== prevProps){
      if(this.props.currentAuthorities !== prevProps.currentAuthorities){
        await this.setState({checks : this.props.currentAuthorities});
        this.allCheckStatus(this.props.currentAuthorities,this.props.actions);
      }

      if(this.props.actions !== prevProps.actions ){
        await this.setState({actions : this.props.actions})
        this.allCheckStatus(this.props.currentAuthorities,this.props.actions);

      }
    }
  }

  async allCheckStatus(authorities,actions){
    let toInc = [];
    authorities.forEach(
      authorityNumber => {
        if(actions.findIndex(action => action.id === authorityNumber) !== -1){
          toInc.push(authorityNumber);
        }
      }
    ); 
    await this.setState({allChecked : toInc.length === actions.length});
  }

  searchAction = async event => {
    await this.setState({searchValue : event.target.value});
    this.filterArrayActions(event.target.value);
  }

  filterArrayActions = value  => {
    let newArray = this.props.actions;
    if(value && value !== ""){
      newArray = this.state.actions.filter(
        action => action.displayName.toLowerCase().includes(value.toLowerCase())
      )
    }

    this.setState({actions : newArray});
  }

  async checkAll(status){
    let checks = [];
    this.state.actions.forEach(
      action => {
        checks.push(action.id)
      }
    )
    
    await this.props.checkAll(status,checks);

  }

  async checkAllSwitch(){
    await this.setState({
      allChecked : !this.state.allChecked,
    });
    this.checkAll(this.state.allChecked ? 'check' : 'uncheck');     
  }

  handleToggle = async value => {
    await this.props.switchAuthority(value);
  }

  render(){
    return <Fragment>
              {
                this.props.resourceName ? 
                <Fragment>
                    <RenderRight
                        title = {this.props.resourceName?.toUpperCase()}
                        searchValue = {this.state.searchValue}
                        changeSearchValue={this.searchAction}
                        checkAll={this.checkAllSwitch}
                        allChecked={this.state.allChecked}
                        actions = {this.state.actions}
                        handleToggle={this.handleToggle}
                        checks={this.state.checks}
                      />
                    <div className="mt-2 d-flex justify-content-end">
                      <button onClick={this.props.saveChange} type="button" className='btn  btn-block btn-primary p-2'>
                          Sauvegarder
                      </button>
                    </div>
                </Fragment>
                 : 
                <div className="text-center text-bold text-danger my-3">
                  <span>Aucune resource selectionnée</span>
                </div>
              }
          </Fragment>  
  }
}

const RenderRight = props => {
  return   <Fragment>
              <Typography  className="d-flex mt-4 mb-3">
                <span className="bg-black px-2">
                    <FontAwesomeIcon color="#fff" icon="chevron-right" size="sm"/> 
                </span>
                <span className="fw-bold text-muted mx-1"> ACTIONS MODULE : </span> 
                <span className="text-danger mx-1"> {props.title}</span> 
              </Typography>
              <div className="form-group my-3">
                    <TextField
                      label="Rechercher action"
                      value={props.searchValue}
                      onChange={props.changeSearchValue}  
                      sx={{ width:'100%' }}
                    />
              </div>
              <div className="d-flex">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Tous sélectionner</Typography>
                  <Switch
                    edge="end"
                    onChange={props.checkAll}
                    checked={props.allChecked}
                  />
                </Stack>
              </div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className="bg-dark">
                    <th scope="action-name">Action</th>
                    <th scope="action-status">Actif?</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.actions.map(
                      action => {
                        return <tr key={action.name}>
                            <td>
                              {action.displayName}
                            </td>
                            <td>
                              <Switch
                                  edge="end"
                                  onChange={(e) => {
                                    props.handleToggle (action.id)
                                  }}
                                  checked={props.checks.indexOf (action.id) !== -1}
                                />
                            </td>
                        </tr>
                    })
                  }
                </tbody>
              </table>           
            </Fragment>
}


export default PConfigDialog;
