import DefaultQuery from '../../vendor/bases/default.query';

export default class ProductionsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'productions',
    });
  }

  async readAll (params) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async valid (id) {
    return await this.updateMethod (this.buildPath (`${id}/valid`), {
      id: id,
    });
  }

  async cancel (id) {
    return await this.updateMethod (this.buildPath (`${id}/cancel`), {
      id: id,
    });
  }
}
