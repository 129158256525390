import DefaultQuery from '../../vendor/bases/default.query';

export default class SupplierordersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'supplierorders',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'supplier-orders'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'supplier-orders'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'supplier-orders'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'supplier-orders'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'supplier-orders'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'supplier-orders')
    );
  }

  async valid (id) {
    return await this.updateMethod (
      this.buildPath (`${id}/valid`, 'supplier-orders'),
      {
        id: id,
      }
    );
  }

  async cancel (id) {
    return await this.updateMethod (
      this.buildPath (`${id}/cancel`, 'supplier-orders'),
      {
        id: id,
      }
    );
  }

  async onlyValidOrders () {
    return await this.getMethod (
      this.buildPath (`orders-validate`, 'supplier-orders')
    );
  }

  async orderArticles (orderId) {
    return await this.getMethod (
      this.buildPath (`${orderId}/articles`, 'supplier-orders')
    );
  }
}
