import Service from '../../vendor/bases/service';

export default class LoopbackService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'loopback',
    });
  }

  async all (params = null) {
    const data = await this.treatQuery (this.query.readAll (params));
    return data;
  }
}
