import './single.css';
import {useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import fiche from '../../../../../assets/images/fiche.jpg';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';
import moment from 'moment';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const SingleSellRender = props => {
  const sellInformation = props.data.rows;

  const componentRef = useRef ();
  
    
  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE DE VENTE #{sellInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">
                                {capitalizeFirstLetter(sellInformation?.sellingNumber?.toUpperCase() || "")}</h5>
                                <small>Vente</small>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Date enregistrement</span>
                                    <span className="number1">
                                        {moment(sellInformation?.date).format("DD/MM/YYYY")}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">N° de caisse</span>
                                    <span className="number1">
                                        {sellInformation?.cashRegisterNumber?.toUpperCase()}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                            <hr/>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="table-responsive">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead sx={{ "& th": {
                        color: "white",
                        backgroundColor: "black"
                        }}}>
                          <TableRow>
                              <TableCell sx={{ fontWeight:"bold" }}>DESIGNATION</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>PRIX U</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>QTE</TableCell>
                              <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>TOTAL</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              sellInformation?.articleSoldList?.length > 0 ?   sellInformation?.articleSoldList?.map((row,index) => (
                                  <TableRow
                                      key={row.id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                          {row?.article?.name.toUpperCase()}
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice} 
                                      </TableCell>
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                          {row?.qty} 
                                      </TableCell>
                                     
                                      <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                        {row?.unitPrice * row?.qty}
                                      </TableCell>
                                  </TableRow>
                              )) : 
                                  <TableRow>
                                      <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                          Aucun article sur cette fiche de vente :)
                                      </TableCell>
                                  </TableRow>
                          }
                          
                      </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="my-2 border-top d-flex justify-content-end">
                <div className="col-6 d-flex justify-content-end">
                    <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td className="text-left text-muted">TOTAL </td>
                              <td className="fw-bold text-right">{Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                    }).format(sellInformation?.totalcostTtc)
                                  }
                              </td>
                            </tr>
                            
                           
                            
                          </tbody>
                    </table>      
                </div>          
               
              </div>
            </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleSellRender;
