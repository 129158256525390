import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Fragment} from 'react';

export default function StateRender (props) {

  let initSold = props?.soldeInitial || 0; 
  let soldIn = props?.encaissement || 0; 
  let soldOut = props?.decaissement || 0; 
  let soldAvailable = props?.disponibilite || 0; 

  return (
    <Fragment>
      <div className="row p-2 my-2">
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-info">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Solde inital</p>
                  <h5 className="my-1 text-info">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF',
                        }).format(initSold)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-success">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Encaissement</p>
                  <h5 className="my-1 text-success">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF'
                        }).format(soldIn)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-danger">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Décaissement</p>
                  <h5 className="my-1 text-danger">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF'
                        }).format(soldOut)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-warning">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Disponibilité</p>
                  <h5 className="my-1 text-secondary">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF'
                        }).format(soldAvailable)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
