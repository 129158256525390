import React,{Fragment, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../inventory.css";
import mainTheme from '../../../../../configs/mainTheme';
import { useReactToPrint } from 'react-to-print';
import { Button, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export default function InventoryPaper (props) {
    let [date,setDate] = useState(new Date());
    let [dateText,setDateText] = useState(moment(new Date()).format("DD/MM/YYYY"));

    let articles = props?.data?.data?.articles || [];
    const componentRef = useRef();
    const onPrint =   useReactToPrint({
        content : () => componentRef.current
    });

    const changeDate = (e) => {
        setDate(e.target.value);
        setDateText(moment(new Date(e.target.value)).format("DD/MM/YYYY"));
    }
    
   
  return (
    <Fragment>
         <div className="d-flex">
            <Button
                variant="outlined"
                sx={{color: 'white', bgcolor: 'green', mr: 1}}
                startIcon={<FontAwesomeIcon icon="print" />}
                onClick={onPrint}
            >
                    Imprimer
                </Button>  
        </div>

        <div className="form-group my-3 p-2 bg-light">
            <TextField
                label="Préciser date"
                value={date}
                onChange={changeDate}  
                sx={{ width:'100%' }}
                type="date"
            />
        </div>

        <div ref={componentRef} className="mt-2 p-2">
            <div className="depot-content">
                <div className="p-3 depot-title text-white fw-bold">
                    FICHE INVENTAIRE : {props.data?.data?.depotName?.toUpperCase() || ''} du {dateText}
                </div>
            
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                <TableCell sx={{ fontWeight:"bold" }}>Article</TableCell>
                                <TableCell sx={{ fontWeight:"bold" }}>Quantité</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                articles.map((row,index) => (
                                    <TableRow
                                        key={row.articleName}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="td" scope="index">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="td" scope="articleName">
                                            {row.articleName}
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))
                            }
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
                <span className="image">
                    <img src={mainTheme.appLogo} alt="logo" className="logo" />
                </span>

                <div className="text logo-text">
                    <span className="name"> PIKADO</span>
                </div>
            </div>
        </div>
</Fragment>
  );
}
