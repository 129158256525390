import DefaultQuery from '../../vendor/bases/default.query';

export default class ChargeproductevolutionQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'statistic',
    });
  }

  async readAll (params) {
    return await this.getMethod (
      this.buildPath ('charge-product-evolution', 'statistic'),
      params
    );
  }
}
