import DefaultQuery from '../../vendor/bases/default.query';

export default class ExpensesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'expenses',
    });
  }
  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async approvedExpense (id) {
    return await this.updateMethod (this.buildPath (`${id}/approved`), {
      id: id,
    });
  }

  async performedExpense (id) {
    return await this.updateMethod (this.buildPath (`${id}/performed`), {
      id: id,
    });
  }
}
