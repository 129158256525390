import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const fsInvoiceFrameConfig = {
  name: 'fsinvoice',
  title: 'Facture(s) fournisseur',
  typeFrame: 'list',
  fields: [
    {
      name: 'supplier',
      title: 'Fournisseur',
    },
    {
      name: 'invoiceNumber',
      title: 'N° de facture',
    },
    {
      name: 'invoiceDate',
      title: 'Date',
    },
    {
      name: 'invoiceAmount',
      title: 'Montant',
    },
  ],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
  ],
};

export default BaseConfiguration (fsInvoiceFrameConfig);
