import BaseBackScreen from '../../../base-back-screen';
import config from './config';
import SingleProductionRender
  from './../../../Productions/_layouts/single/index';

export default class ProductionScreen extends BaseBackScreen {
  name = 'production';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleProductionRender,
  };
}
