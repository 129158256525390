import DefaultQuery from '../../vendor/bases/default.query';

export default class AccountingcategoriesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'accountingcategories',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'accounting-category'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'accounting-category'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (
      this.buildPath (`${id}`, 'accounting-category')
    );
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'accounting-category'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'accounting-category'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (
      this.buildPath (`${id}`, 'accounting-category')
    );
  }
}
