import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const stockOutputsFrameConfig = {
  name: 'stockoutputs',
  title: 'Sortie(s) Divers de stock',
  typeFrame: 'list',
  fields: [
    {
      name: 'stockOutputNumber',
      title: 'N°',
    },
    {
      name: 'outputDate',
      title: 'Date de sortie',
      type: 'date',
    },
    {
      name: 'depotId',
      title: 'dépôt',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      col: '6',
      dataServiceSource: 'depots',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'depots',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'depot',
      title: 'dépôt',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'depots',
      isObject: true,
      fieldsSelected: ['name'],
    },
    {
      name: 'article',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'articles',
      isObject: true,
      fieldsSelected: ['name'],
    },
    {
      name: 'articleId',
      title: 'article',
      htmlElement: 'select',
      type: 'objectFetcher',
      col: '6',
      options: [],
      dataServiceSource: 'articles',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'articles',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'qty',
      title: 'quantité',
      type: 'number',
      col: '6',
    },
    {
      name: 'reason',
      title: 'Motif',
      col: '6',
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validée'},
        2: {badgeColor: '#DC143C', text: 'Annulée'},
      },
      type: 'state-multiple',
    },
  ],
  excludeToHeaders: [
    'depotId',
    'articleId',
    'reason',
    'depot',
    'article',
    'qty',
  ],
  excludeToForms: ['depot', 'article', 'stockOutputNumber', 'state'],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Validé commande',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'danger',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  filterOptions: [
    {
      name: 'outPutNumber',
      title: 'N° de sortie',
    },
    {
      name: 'date',
      type: 'interval',
    },
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Validée',
          value: 1,
        },
        {
          label: 'Annulée',
          value: 2,
        },
      ],
    },
    /*{
      name: 'articleId',
      title: 'article',
      type: 'select',
      optionsTarget: 'articles',
    },*/
    /*
    {
      name: 'depotId',
      title: 'depot',
      type: 'select',
      optionsTarget: 'depots',
    },
    {
      name: 'userId',
      title: 'Responsable',
      type: 'select',
      optionsTarget: 'users',
    },*/
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (stockOutputsFrameConfig);
