import BaseBackScreen from '../../../base-back-screen';
import SingleDeliver from '../../../Supplierdeliveries/_layouts/single';
import config from './config';

export default class SupplierDeliverScreenScreen extends BaseBackScreen {
  name = 'supplier-deliver';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleDeliver,
  };
}
