import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const depotsFrameConfig = {
  name: 'depots',
  title: 'Dépôts',
  typeFrame: 'list',
  fields: [
    {
      name: 'articleFamilyIdList',
      title: "Famille d'article",
      multiple: true,
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articlefamillies',
      selectDataValue: {
        name: 'articleFamily',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'articleFamily',
      title: "Famille d'article",
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'articlefamillies',
      isObject: true,
      fieldsSelected: ['name'],
    },
    {
      name: 'localisation',
    },
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'tel',
      title: 'téléphone',
      type: 'phone',
    },
    {
      name: 'isActive',
      title: 'Actif?',
      type: 'checkbox',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
    },
    {
      name: 'inventory',
      title: 'Stock du dépôt',
      color: 'warning',
      icon: 'boxes-stacked',
    },
    {
      name: 'inventoryPaper',
      title: 'Fiche inventaire',
      color: 'dark',
      icon: 'note-sticky',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
    },
  ],
  excludeToHeaders: ['articleFamilyIdList', 'articleFamily'],
  excludeToForms: ['articleFamily'],
  filterOptions: [
    {
      name: 'name',
      title: 'nom',
    },
    {
      name: 'localisation',
    },
    {
      name: 'isActive',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'Actif',
          value: true,
        },
        {
          label: 'Inactif',
          value: false,
        },
      ],
    },
    /*{
      name: 'familyId',
      title: 'Famille',
      type: 'select',
      optionsTarget: 'articlefamillies',
    },*/
  ],
  dialogsConfig: {
    inventoryDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (depotsFrameConfig);
