import BaseBackComponent from '../base-back-component';
import DepotsService from './../../../external-communication/services/depots.service';

export default class StockoutputsComponent extends BaseBackComponent {
  name = 'Stockoutputs';

  options = [
    {
      title: 'articles',
      keys: ['name'],
    },
    {
      title: 'depots',
      keys: ['name'],
    },
    {
      title: 'users',
      keys: ['firstName', 'lastName'],
    },
  ];

  state = {
    ...this.state,
    articlesInDepot : [],
    searchOptions: {
      ...this.state.searchOptions,
      outPutNumber: null,
      date: null,
      depotId: null,
      articleId: null,
      userId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      depotId : {value : data?.depot?.value?.id},      
      articleId : {value : data?.article?.value?.id},      
      qty : {value : data?.qty?.value, maxValue : data?.qty?.value},      
    } 
    return data;
  }

  async otherHandleFieldTreatment (name, value) {
    if(name === "depotId"){
      let queryDepotArticles = {};
      let articles = [];
      if(value && value !== ""){
        queryDepotArticles =  await new DepotsService().getArticles(value);
        if(queryDepotArticles?.success){
          articles = this.loadDepotArticles(queryDepotArticles?.data?.articleStock);
        }
      }
      let newOptions  = this.state.options; 
      
      const indexOfArticleOptions = newOptions?.findIndex(item => item.title === "articles");

      if(indexOfArticleOptions !== -1){
        newOptions.splice(indexOfArticleOptions,1);
      }

      newOptions.push({
        title : "articles",
        options : articles
      })
      
      
      await this.setState({
        articlesInDepot : queryDepotArticles?.data?.articleStock || [],
        options: newOptions,
        formPayload : {
          ...this.state.formPayload,
        }
      })
    }

    if(name === "articleId"){
      this.setPayloadData(value);
    }
  }

  setPayloadData(value){
    let articleQty = this.state.articlesInDepot.find(item => item.id === value)?.qty || 0; 

    this.setState({
      formPayload : {
        ...this.state.formPayload,
        qty : {value : articleQty,maxValue : articleQty}
      }
    })
  }



  loadDepotArticles(articles){
    return articles.map (item => {
      return {
        value: item.id,
        label: item.articleName,
      };
    });
  }

}
