import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const sellsEvolutionFrameConfig = {
  name: 'sellsevolution',
  title: 'EVOLUTION C.A. PAR AN',
  typeFrame: 'list',
  fields: [],
  filterOptions: [
    {
      name: 'departementId',
      title: 'departement',
      type: 'select',
      optionsTarget: 'departements',
    },
  ],
  hideBar: true,
};

export default BaseConfiguration (sellsEvolutionFrameConfig);
