import BaseBackComponent from '../base-back-component';

export default class ProductsComponent extends BaseBackComponent {
  name = 'Products';

  options = [
    {
      title: 'articles',
      keys: ['name'],
    },
    {
      title: 'finishedProducts',
      keys: ['name'],
      source : 'articles',
      method : 'onlyFinishedProducts'
    },
    {
      title: 'excludeFinishedProducts',
      keys: ['name'],
      source : 'articles',
      method : 'onlyFinishedProductsAndRawMaterials'
    },
    {
      title: 'rawMaterials',
      keys: ['name'],
      source : 'articles',
      method : 'onlyRawMaterials'
    },
  ];

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      finishProductId : null
    }
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    const rawList = data?.recipeList.value.map(
      raw => {
        return {
          rawMaterialId : {value : raw.rawMaterial.id},
          qty : {value : raw.qty}    
        }
      }
    ) 

    
    data = {
      ...data,
      productId : {value : data?.product?.value?.id},
      rawMaterialList : {value : rawList}
    };
    return data;
  }

}
