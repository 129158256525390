import './App.css';
import Bootstrap from './root/bootstrap';

import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

function App () {
  return <Bootstrap />;
}
library.add (far, fas, fab);

export default App;
