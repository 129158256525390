import './single.css';
import {useReactToPrint} from 'react-to-print';
import { useRef, useState} from 'react';
import {Button, Card, CardContent} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import profile from '../../../../../assets/images/profile.jpg';
import mainTheme from '../../../../../configs/mainTheme';
import ClientInvoices from '../invoices';
import Fees from '../fees';
import FrameTitleLinked from '../../../../../vendor/builders/Frame/_layouts/title/title-linked';

const SingleClientRender = props => {
  const clientInformation = props.data.rows;

  const componentRef = useRef ();
  const onPrint = useReactToPrint ({
    content: () => componentRef.current,
  });

  const [currentComponent,setCurrentComponent] = useState("invoices");
  
  const [currentSubParams,setCurrentSubParams] = useState({
    clientId : null
  });

  

  const [currentActiveBtnName,setCurrentActiveBtnName]= useState("invoices");
  const [currentComponentName,setCurrentComponentName] = useState("invoices");
  const [currentComponentTitle,setCurrentComponentTitle] = useState("Facture(s)");
  

  
  const handleChangeSubComponent = (e, value) => {

    if(value === "invoices"){
        setCurrentComponent("invoices");
        setCurrentSubParams({
            clientId : clientInformation.id
        });
        setCurrentActiveBtnName("invoices");
        setCurrentComponentName("invoices");
        setCurrentComponentTitle("Facture(s)");
    }else{
        setCurrentComponent("fees");
        setCurrentSubParams({
            exonerationsList : clientInformation?.category?.exemptionLIst,
            majList : clientInformation?.category?.majorationList
        });
        setCurrentActiveBtnName("fees");
        setCurrentComponentName("fees");
        setCurrentComponentTitle("Majoration(s) & exonération(s)");
    }
  }

  const CurrentComponent = name => {
    switch(name){
        case "invoices" : return  <ClientInvoices params={{clientId : clientInformation?.id }}/>;
        case "fees" : return  <Fees params={currentSubParams}/>;
        default : 
            return null;
    }
  }

  return (
    <div className="client-card-information">
        <div className="d-flex">
            <Button
                variant="outlined"
                sx={{color: 'white', bgcolor: 'green', mr: 1}}
                startIcon={<FontAwesomeIcon icon="print" />}
                onClick={onPrint}
            >
                Imprimer
            </Button>

        </div>
        <div ref={componentRef} className="mt-2 p-2">
            <div className="client-content">
                <div className="p-2 text-center text-white client-title">
                    <h4>FICHE CLIENT #{clientInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={profile} alt="logo" className="rounded" width="100" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{clientInformation?.firstName +" "+ clientInformation?.lastName}</h5>
                            <small>Client PIKADO</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">

                                    <span className="articles">N° de téléphone</span>
                                    <span className="number1">
                                    {clientInformation?.tel} 
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                    <FrameTitleLinked
                        activeBtnName={currentActiveBtnName}
                        name={currentComponentName}
                        title={currentComponentTitle}
                        handleActionClick = {handleChangeSubComponent}
                        actions={
                            [
                                {
                                    name : "invoices",
                                    title: "Facture(s)",
                                },
                                {
                                    name : "fees",
                                    title: "Majoration(s) & exonération(s)",
                                }
                            ]
                        }
                    />
                    <Card sx={{width: '100%'}}>
                    <CardContent>
                        { CurrentComponent(currentComponent)}
                        
                    </CardContent>
                    </Card>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};




export default SingleClientRender;
