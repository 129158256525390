import DefaultQuery from '../../vendor/bases/default.query';

export default class StockmovesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'stockmoves',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'stock-movements'),
      searchOptions
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'stock-movements'));
  }
}
