import BaseBackComponent from '../base-back-component';

export default class LogsComponent extends BaseBackComponent {
  name = 'Logs';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      exercise: null,
      size: 10000,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
