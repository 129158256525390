import ClientsService from '../../../external-communication/services/clients.service';
import BaseBackComponent from '../base-back-component';

export default class CustomerpaymentsComponent extends BaseBackComponent {
  name = 'Customerpayments';

  options = [
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    }
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      customerId: null,
      data: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async otherHandleFieldTreatment (name, value) {
      if (name === 'customerId') {
        
        const queryInvoices = await new ClientsService ().unpaidInvoice (
          value
        );

        let invoices = [];

        if(queryInvoices?.success){
          invoices = this.loadInvoices(queryInvoices?.data);
        }

        let newOptions  = this.state.options; 
        
        const indexOfArticleOptions = newOptions?.findIndex(item => item.title === "sells");

        if(indexOfArticleOptions !== -1){
          newOptions.splice(indexOfArticleOptions,1);
        }

        newOptions.push({
          title : "sells",
          options : invoices
        })

        await this.setState({
          options: newOptions,
          formPayload : {
            ...this.state.formPayload,
            amount : {
              value : 0 
            }
          }
        })
        

      }

      if(name === 'invoiceList'){
        
        let amount = 0;

        if(value && value?.length > 0){
          
          const invoices =  this.state.options.find(option => option.title === "sells").options;

          value?.forEach(element => {
              const sValue =  invoices.find(sale => sale.value === element)?.originalObject

              if(sValue){
                amount += sValue?.invoiceCost
              }
          });
          
        }

        await this.setState({
          formPayload : {
            ...this.state.formPayload,
            amount : {
              value : amount 
            }
          }
        });
      }
  }


  loadInvoices(data){
    return data.map (invoice => {
      return {
        value: invoice.id,
        label: invoice.invoiceNumber,
        originalObject : invoice
      };
    });
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      customerId : {value : data?.customer?.value?.id},
      payementMode : {value : data?.payementMode?.value},
      invoiceList : {value : data?.invoiceList?.value}
    };
    return data;
  }
}
