import React from 'react';
import ChargeEvolution from './charge-evolution';
import ChargeProductEvolution from './charge-product-evolution';
import ChargeProductResume from './charge-or-product-resume';
import StatsTemplate from '../stats.template';

export default function StatsByDebt () {
  return <StatsTemplate components={components} />;
}

const components = [
  {
    title: 'chargeEvolution',
    label: 'Evolution des charges',
    renderComponent: ChargeEvolution,
    isVisible: true,
  },
  {
    title: 'chargeProductEvolution',
    label: 'SYNTHESE',
    renderComponent: ChargeProductEvolution,
    isVisible: true,
  },
  {
    title: 'chargeProductResume',
    label: 'Résumé de charge ou produit',
    renderComponent: ChargeProductResume,
    isVisible: true,
  },
];
