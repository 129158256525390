import DefaultQuery from '../../vendor/bases/default.query';

export default class CashregistersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'cashregisters',
    });
  }

  async readAll (searchOptions = null) {
    return await this.getMethod (
      this.buildPath ('list', 'cash-registers'),
      searchOptions
    );
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'cash-registers'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'cash-registers'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'cash-registers'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'cash-registers'),
      credentials
    );
  }

  async close (id) {
    return await this.updateMethod (
      this.buildPath (`close/${id}`, 'cash-registers'),
      {
        id: id,
      }
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'cash-registers'));
  }
}
