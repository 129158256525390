import './single.css';
import mainTheme from '../../../../configs/mainTheme';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const SingleCustomerCategoryRender = props => {
  const categoryCustomerInformation = props.data.rows;

  return (
    <div className="client-card-information">
       
        <div className="mt-2 p-2">
            <div className="client-content">
                <div className="p-2 text-center text-white client-title">
                    <h4>Catégorie client #{categoryCustomerInformation?.libelle}</h4>
                </div>
            <div className="invoice-body mt-2">
              {/* Row start */}
              <div className="row gutters">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="p-2 text-left fst-italic border-bottom">
                        <h5>Liste des majorations</h5>
                    </div>  
                  <div className="table-responsive">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                          <TableHead sx={{ bgcolor:mainTheme.color }}>
                              <TableRow>
                                  <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Département</TableCell>
                                  <TableCell sx={{ fontWeight:"bold" }}>Pourcentage</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  categoryCustomerInformation?.majorationList?.length > 0 ?  categoryCustomerInformation?.majorationList?.map((row,index) => (
                                      <TableRow
                                          key={row.id}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                          <TableCell component="td" scope="index">
                                              {index+1}
                                          </TableCell>
                                          <TableCell component="td" scope="departementName">
                                              {row?.departement?.name}
                                          </TableCell>
                                          <TableCell>
                                            {row?.majorationAmount} %
                                          </TableCell>
                                      </TableRow>
                                  )) : 
                                      <TableRow>
                                          <TableCell sx={{ textAlign:"center" }} colSpan={3} component="td" scope="row">
                                              Aucune majoration appliquée à cette catégorie :)
                                          </TableCell>
                                      </TableRow>
                              }
                              
                          </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                <hr/>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="p-2 text-left fst-italic border-bottom">
                        <h5>Liste des exonérations</h5>
                    </div>  
                    <div className="table-responsive">
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                            <TableHead sx={{ bgcolor:mainTheme.color }}>
                                <TableRow>
                                    <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                    <TableCell sx={{ fontWeight:"bold" }}>Département</TableCell>
                                    <TableCell sx={{ fontWeight:"bold" }}>Pourcentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    categoryCustomerInformation?.exemptionLIst?.length > 0 ?  categoryCustomerInformation?.exemptionLIst?.map((row,index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="td" scope="index">
                                                {index+1}
                                            </TableCell>
                                            <TableCell component="td" scope="departementName">
                                                {row?.departement?.name} 

                                            </TableCell>
                                            <TableCell>
                                            {row?.exemptionRate} %
                                            </TableCell>
                                        </TableRow>
                                    )) : 
                                        <TableRow>
                                            <TableCell sx={{ textAlign:"center" }} colSpan={3} component="td" scope="row">
                                                Aucune exonération appliquée à cette catégorie :)
                                            </TableCell>
                                        </TableRow>
                                }
                                
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
                </div>              
              </div>
              
              {/* Row end */}
            </div>

            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleCustomerCategoryRender;
