import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const statsBySubFamilyFrameConfig = {
  name: 'statsbydepartment',
  title: 'VENTES PAR SOUS FAMILLE',
  typeFrame: 'list',
  fields: [],
  filterOptions: [],
};

export default BaseConfiguration (statsBySubFamilyFrameConfig);
