import Service from '../../vendor/bases/service';

export default class AnalysmarginService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'analysmargin',
    });
  }
}
