import BaseBackScreen from '../base-back-screen';
import DeliverForm from './_layouts/deliver';
import QuittanceRender from './_layouts/quittance';

export default class ClientordersScreen extends BaseBackScreen {
      name = 'Clientorders';

    loadOtherDialogContent(actionName,title=null,dataId=null){
        let otherContent = {};
        const config = this.loadFrameContent();
        if(actionName === 'deliver'){
            let data = {
                deliverPayload : this.props.data.deliverPayload,
                submitDeliver : this.props.data.submitDeliver,
                handleDeliverPayload : this.props.data.handleDeliverPayload,
            };  
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : DeliverForm,
                data : data,
                size : config?.dialogsConfig?.deliverDialog?.size || "sm"
            }
        }else if(actionName === "quittance"){
            this.loadEditData(dataId);
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : QuittanceRender,
                data : this.props.data.singleData,
                size : config?.dialogsConfig?.quittanceDialog?.size || "sm"
            }
        }else{
            otherContent = {
                actionName : actionName,
                componentName :  title,
                dataReference : dataId,
                validAction :this.props.data?.screenActions?.validOtherAction 
            }
        }
        
        return otherContent;
    }
}
