import moment from 'moment';
import BaseBackComponent from '../base-back-component';

const date =
  moment ().format ('YYYY-MM-DD') + ',' + moment ().format ('YYYY-MM-DD');

export default class LoopbackComponent extends BaseBackComponent {
  name = 'Loopback';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: date,
      articleId: null,
      articleFamilyId: null,
      departementId: null,
    },
  };

  options = [
    {
      title: 'articles',
      keys: ['name'],
    },
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
    {
      title: 'departements',
      keys: ['name'],
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
