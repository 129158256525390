import {Autocomplete, TextField} from '@mui/material';
import {Fragment, React, useState} from 'react';

const byRowOptions = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
];


export default function StatsTemplate (props) {
    const [currentRowOption,setCurrentRowOption]  =  useState({label : 1, value : 1});
    const [currentColSize,setCurrentColSize]  =  useState(12);
    const [currentVisibleComponent,setCurrentVisibleComponent]  =  useState(null);
    const [currentRenderComponents,setCurrentRenderComponents]  =  useState(props.components || []);

    
    const changeVisibleComponent = (e,value) => {
      
        let components =  props.components.map(
            component => {
                component.isVisible = true;
                return component;
            }
        );
        
        
        if(value != null){
            components =  props.components.map(
                component => {
                    if(component?.title !== value?.value){
                        component.isVisible = false;
                    }else {
                        component.isVisible = true;
                    }
                    return component;
            });
        }
        

        setCurrentVisibleComponent(value);
        setCurrentRenderComponents(components);
    }

    const changeCurrentRowOption = (e,value) => {
        const currentRowOption = value || {label : 1, value : 1};
        const currentColSize = 12 / (value?.value || 1) ;

        setCurrentRowOption(currentRowOption);
        setCurrentColSize(currentColSize);
        
    }

  return (<Fragment>
    <div className="col-12 d-flex justify-content-start my-3">
            <Autocomplete
                id="rowOption"
                options={byRowOptions}
                onChange={(e,value) => {
                    changeCurrentRowOption(e,value)
                }}
                getOptionLabel={option => option.label+''}
                isOptionEqualToValue={option => option?.value === currentRowOption?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Affichage par ligne`} />}
                value={currentRowOption}
            />
            <Autocomplete
                id="renderOption"
                options={currentRenderComponents.map(
                component => {
                    return {
                    label : component?.label,
                    value : component?.title,
                    }
                }
                )}
                onChange={(e,value) => {
                    changeVisibleComponent(e,value)
                }}
                getOptionLabel={option => option.label+''}
                isOptionEqualToValue={option => option?.value === currentVisibleComponent?.value}
                size="small"
                sx={{ width:200,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Voir seulement`} />}
                value={currentVisibleComponent}
            />
    </div>
    <div className="row">
        {
            currentRenderComponents?.map(
                component => {
                    const RenderComponent = component?.renderComponent
                    return  <Fragment key={component?.title}>
                            {
                                component?.isVisible ? 
                                <div className={`col-12 col-lg-${currentColSize} col-xl-${currentColSize} col-md-${currentColSize} border-3 border-black border-bottom border-start border-end my-2`}> 
                                    <RenderComponent/> 
                                </div> : null
                            }
                            </Fragment>
                }
            )
        }
    
    </div>
</Fragment>
);
}
