import DefaultQuery from '../../vendor/bases/default.query';

export default class RolesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'roles',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list', 'role'), params);
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('active-list', 'role'));
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('add', 'role'),
      credentials
    );
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`update/${id}`, 'role'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`get-detail/${id}`, 'role'));
  }

  async setAuthorities (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`set-authorities/${id}`, 'role'),
      {
        role: credentials.role_id,
        authorities: credentials.authorities,
      }
    );
  }

  async loadAuthoritiesResourceId (id) {
    return await this.getMethod (this.buildPath (`list/${id}`, 'authorities'));
  }

  async loadAuthorities (id) {
    return await this.getMethod (this.buildPath (``, 'authorities'));
  }

  async getRoleAuthorities (id) {
    return await this.getMethod (
      this.buildPath (`get-authority-ids/${id}`, 'role')
    );
  }
}
