import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const chargeProductEvolutionFrameConfig = {
  name: 'chargeproductevolution',
  title: 'SYNTHESE DU RESULTAT',
  typeFrame: 'list',
  fields: [],
  filterOptions: [],
  hideBar: true,
};

export default BaseConfiguration (chargeProductEvolutionFrameConfig);
