import React,{Fragment} from 'react';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./inventory.css";
import mainTheme from '../../../../configs/mainTheme';

export default class InventoryDialog extends React.Component{
    constructor(props){
        super(props);
        this.searchAction = this.searchAction.bind(this);
        this.filterArticleArray = this.filterArticleArray.bind(this);
        this.getTotalStockValue = this.getTotalStockValue.bind(this);

        this.state = {
            depotName : "",
            searchValue : "",
            articles : [],
            totalStockValue : 0
        }
    }

    searchAction = async event => {
        await this.setState({searchValue : event.target.value});
        this.filterArticleArray(event.target.value);
    }

    async componentDidMount(){
        await this.setState({
            depotName : this.props.data?.data?.depotName || '',
            articles : this.props.data?.data?.articles || [],
        })
    }

    async componentDidUpdate(prevProps,prevState){
        if(this.props !== prevProps){
            let totalStockValue = 0;
            
            if(this.props.data?.data?.articles && this.props.data?.data?.articles.length > 0){
               totalStockValue = this.getTotalStockValue(this.props.data?.data?.articles);
            }
            
            await this.setState({
                depotName : this.props.data?.data?.depotName || '',
                articles : this.props.data?.data?.articles || [],
                totalStockValue : totalStockValue
            })
        }

        
    }

    getTotalStockValue(articles = []){
        let totalStockValue = 0;
        articles.forEach(article => {
            totalStockValue = totalStockValue  + (article.qty * article.cump);
        });

        return totalStockValue;
    }

    
    filterArticleArray = value  => {
        let newArray = this.props.data.data.articles;
        if(value && value !== ""){
        newArray = this.state.articles.filter(
            article => article.articleName.toLowerCase().includes(value.toLowerCase())
        )
        }
        let newStockValue = this.getTotalStockValue(newArray);

        this.setState({articles : newArray,totalStockValue : newStockValue});
    }

    render(){
            return <Fragment>
                        <div className="depot-content">
                            <div className="p-3 depot-title text-white fw-bold">
                                ARTICLES EN STOCK : {this.props.data?.data?.depotName?.toUpperCase() || ''}
                            </div>
                            <div className="form-group my-3 p-2 bg-light">
                                <TextField
                                label="Rechercher article"
                                value={this.state.searchValue}
                                onChange={this.searchAction}  
                                sx={{ width:'100%' }}
                                />
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                            <TableCell sx={{ fontWeight:"bold" }}>Article</TableCell>
                                            <TableCell sx={{ fontWeight:"bold" }}>CUMP (F.CFA)</TableCell>
                                            <TableCell sx={{ fontWeight:"bold" }}>Quantité</TableCell>
                                            <TableCell sx={{ fontWeight:"bold" }}>Valeur stock</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.articles?.length > 0 ?  this.state.articles.map((row,index) => (
                                                <TableRow
                                                    key={row.articleName}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" scope="index">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="td" scope="articleName">
                                                        {row.articleName}
                                                    </TableCell>
                                                    <TableCell>{row.cump} F.CFA</TableCell>
                                                    <TableCell>{row.qty +" "+ row.articleUnit}</TableCell>
                                                    <TableCell>{row.qty * row.cump}</TableCell>
                                                </TableRow>
                                            )) : 
                                                <TableRow>
                                                    <TableCell sx={{ textAlign:"center" }} colSpan={4} component="td" scope="row">
                                                        Aucun article en stock dans ce dépôt :)
                                                    </TableCell>
                                                </TableRow>
                                        }
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="mt-2 border-top">
                                <div className="d-flex justify-content-end p-3 bg-light border-top">
                                <h5 className="me-3">Total valeur stock :</h5>
                                <h5 className="fw-bold">{ this.state.totalStockValue}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center image-text border-top">
                            <span className="image">
                                <img src={mainTheme.appLogo} alt="logo" className="logo" />
                            </span>

                            <div className="text logo-text">
                                <span className="name"> PIKADO</span>
                            </div>
                        </div>
                    </Fragment>;
    }
}
