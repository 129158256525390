import BaseBackComponent from '../base-back-component';
import DepotsService from './../../../external-communication/services/depots.service';

export default class DepotsComponent extends BaseBackComponent {
  name = 'Depots';

  options = [
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      name : null,
      localisation : null,
      isActive : null,
      familyId : null,
    }
  }
  
  constructor (props) {
    super (props);
    this.loadDepotArticles = this.loadDepotArticles.bind (this);

    this.state = {
      ...this.state,
      loadDepotArticles : this.loadDepotArticles,
      articles : [],
      depotName : null
    }
  }
  
  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      articleFamilyIdList : {value : data?.articleFamilies?.value?.map(family => family.id)}
    };
    return data;
  }

  async loadDepotArticles(depotId){
    const articlesResponse = await new DepotsService().getArticles(depotId);

    if(articlesResponse.success){
      this.setState({
        articles : articlesResponse?.data?.articleStock,
        depotName : articlesResponse?.data?.depotName,
      })
    }

  }
}
