import BaseBackComponent from '../../../base-back-component';
import SupplierOrderScreen from './screen';
import SupplierordersService
  from './../../../../../external-communication/services/supplierorders.service';

export default class SupplierOrderComponent extends BaseBackComponent {
  name = 'supplier-order';
  options = [
    {
      title: 'analytiqueplans',
      keys: ['analyticalCode'],
    },
    {
      title: 'accountingtypes',
      keys: ['libelle'],
    },
    {
      title: 'accountingcategories',
      keys: ['libelle'],
    },
    {
      title: 'suppliers',
      keys: ['firstName'],
    },
    {
      title: 'articles',
      keys: ['name'],
      method: 'excludeFinishedProducts',
    },
  ];

  state = {
    ...this.state,
    screen: SupplierOrderScreen,
    searchOptions: {
      ...this.state.searchOptions,
      state: null,
      supplierId: null,
      orderNumber: null,
      date: null,
    },
  };

  async componentDidMount () {
    let status = '';

    let state = this.props.params.state;

    if (state === 'in wait') {
      status = 0;
    } else if (state === 'cancel') {
      status = 3;
    }

    await this.setState ({
      service: new SupplierordersService (),
    });

    const searchOptions = {
      ...this.state.searchOptions,
      state: status,
    };

    this.loadComponentDataFromDatabase (null, searchOptions);
    this.loadOptions ();
  }
}
