import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const stockMovesFrameConfig = {
  name: 'stockmoves',
  title: 'Mouvements de stock',
  typeFrame: 'list',
  frameActions: {
    tableActions: [
      {
        frameType: 'list',
        actions: [
          {
            name: 'export',
            title: 'Exporter',
            icon: null,
            target: null,
            color: 'primary',
          },
        ],
      },
    ],
  },
  fields: [
    {
      name: 'movementNumber',
      title: 'N°',
    },
    {
      name: 'dateMovement',
      title: 'Date',
      type: 'date',
    },
    {
      name: 'typeMovement',
      title: 'type',
      options: {
        0: {badgeColor: '#5F9EA0', text: 'Réception commande'},
        1: {badgeColor: '#5F9EA0', text: 'Entrée divers'},
        2: {
          badgeColor: '#5F9EA0',
          text: 'Ecart inventaire positif',
          textColor: 'white',
        },
        3: {
          badgeColor: '#5F9EA0',
          text: 'Entrée production',
          textColor: 'white',
        },
        4: {
          badgeColor: '#DC143C',
          text: 'Transfert -',
          textColor: 'white',
        },
        5: {badgeColor: '#5F9EA0', text: 'Entrée refaite', textColor: 'white'},
        6: {badgeColor: '#DC143C', text: 'Sortie diverse', textColor: 'white'},
        7: {
          badgeColor: '#DC143C',
          text: 'Ecart inventaire négatif',
          textColor: 'white',
        },
        8: {
          badgeColor: '#DC143C',
          text: 'Sortie waste',
          textColor: 'white',
        },
        9: {
          badgeColor: '#5F9EA0',
          text: 'Correction entrée',
          textColor: 'white',
        },
        10: {badgeColor: '#DC143C', text: 'Sortie refaite', textColor: 'white'},
        11: {badgeColor: '#DC143C', text: 'Sortie vente', textColor: 'white'},
        12: {
          badgeColor: '#DC143C',
          text: 'Sortie refaite',
          textColor: 'white',
        },
        13: {
          badgeColor: '#5F9EA0',
          text: 'Entrée refaite',
          textColor: 'white',
        },
        14: {
          badgeColor: '#5F9EA0',
          text: 'Transfert +',
          textColor: 'white',
        },
      },
      type: 'state-multiple',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
    },
    {
      name: 'article',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'articles',
      selectDataValue: ['name'],
      fieldToTake: ['supplier'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'previousStock',
      title: 'Stock avant',
    },
    {
      name: 'qtyMovement',
      title: 'Quantité',
    },
    {
      name: 'nextStock',
      title: 'Quantité restante',
    },
    {
      name: 'createdBy',
      title: 'Responsable',
    },
    {
      name: 'costCenter',
      title: 'cout',
    },
    {
      name: 'origin',
      title: 'Provenance',
    },
    {
      name: 'totalAmount',
      title: 'Cout total',
    },
    {
      name: 'unitPrice',
      title: 'Prix unitaire',
    },

    {
      name: 'depot',
      title: 'emplacement',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'depots',
      selectDataValue: ['name'],
      fieldToTake: ['depot'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
    {
      name: 'departement',
      htmlElement: 'select',
      options: [],
      dataServiceSource: 'departements',
      selectDataValue: ['name'],
      fieldToTake: ['departement'],
      type: 'objectFetcher',
      fieldsSelected: ['name'],
    },
  ],
  excludeToHeaders: [
    'departement',
    'origin',
    'unitPrice',
    'costCenter',
    'createdBy',
    'libelle',
    'totalAmount',
  ],

  filterOptions: [
    {
      name: 'movementNumber',
      title: 'N°',
    },
    {
      name: 'articleId',
      title: 'Article',
      type: 'select',
      optionsTarget: 'articles',
    },
    {
      name: 'depotId',
      title: 'Emplacement',
      type: 'select',
      optionsTarget: 'depots',
    },

    {
      name: 'type',
      title: 'Type mouvement',
      type: 'select',
      options: [
        {value: 0, label: 'Réception commande'},
        {value: 2, label: 'Ecart inventaire positif'},
        {value: 7, label: 'Ecart inventaire négatif'},
        {value: 3, label: 'Entrée production'},
        {value: 4, label: 'Transfert -'},
        //{value : 5 , label: 'Entrée refaite'},
        // {value : 10 , label: 'Sortie refaite'},
        {value: 1, label: 'Entrée divers'},
        {value: 6, label: 'Sortie diverse'},
        {value: 8, label: 'Sortie waste'},
        {value: 9, label: 'Correction entrée'},
        {value: 11, label: 'Sortie vente'},
        {value: 12, label: 'Sortie refaite'},
        {value: 13, label: 'Entrée refaite'},
        {value: 14, label: 'Transfert +'},
      ],
    },

    {name: 'date', type: 'interval'},
  ],

  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
  ],

  dialogsConfig: {
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (stockMovesFrameConfig);
