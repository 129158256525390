import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const supplierDebtFrameConfig = {
  name: 'supplierdebt',
  title: 'Dette(s) fournisseur',
  typeFrame: 'list',
  fields: [
    {
      name: 'supplierName',
      title: 'Fournisseur',
    },
    {
      name: 'debt',
      title: 'Montant',
      type: 'money',
    },
  ],
  filterOptions: [],
  actions: [],
  hideBar: true,
};

export default BaseConfiguration (supplierDebtFrameConfig);
