import BaseBackComponent from '../base-back-component';

export default class ClientsComponent extends BaseBackComponent {
  name = 'Clients';

  options = [
    {
      title: 'customercategories',
      keys: ['libelle'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      firstName: null,
      lastName: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      customerCategoryId : {value : data?.category?.value?.id},
    };
    return data;
  }
}
