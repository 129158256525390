import Service from '../../vendor/bases/service';

export default class CustomerdebtService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'customerdebt',
    });
  }
}
