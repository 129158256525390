import "./style.css";
import { BuildTableAction } from "../../../../../vendor/builders/Table";
import appParams from "../../../../../configs/app-params";
import mainTheme from "../../../../../configs/mainTheme";
import register from '../../../../../assets/images/cash-register.png';
import moment from "moment";

export default function SingleCashierCard (props) {
    const cashier = props.information;
    
    const actionsProperties = {
        actions : props.actions,
        dataId: cashier.id,
        data : cashier,
        featureName : 'cashregisters'
    };

    
    
    return     <div className="cashRegister-card-information col-12 col-lg-6 col-xl-6 col-md-6 my-2">
                <div className="cashRegister-content">
                    <div className="p-2 text-center text-white cashRegister-title">
                        <h4>
                            {cashier?.reference?.toUpperCase() + " : " +  moment(cashier?.openingDate).format("DD/MM/YYYY")} 
                        </h4>
                    </div>
                    <div className="card p-1 m-1">
                        <div className="d-flex justify-content-end badge">
                            {
                                cashier?.state ? 
                                    <span className="badge-soft-success">OUVERTE</span> : 
                                    <span className="badge-soft-danger">FERMEE</span>
                            }
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="image bg-light p-2 mr-3">
                                <img src={register} alt="logo" className="rounded" width="120" />
                            </div>
                            <div className="p-2 w-100"> 
                                <small>Solde</small>
                                <h5 className="mb-0 mt-0 text-danger">
                                    {
                                        Intl.NumberFormat(appParams.language,{ style: 'currency',
                                            currency: appParams.currency,
                                            
                                        }).format(cashier?.balance)    
                                    }
                                </h5>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                    <div className="d-flex flex-column">
                                        <span className="cashRegisters">Solde à l'ouverture</span>
                                        <span className="number1">
                                            {
                                                Intl.NumberFormat(appParams.language,{ style: 'currency',
                                                    currency: appParams.currency,
                                                    
                                                        }).format(cashier?.openingBalance)    
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="cashRegisters">Responsable</span>
                                        <span className="number1">
                                            {cashier?.user?.firstName +" "+ cashier?.user?.lastName}
                                        </span>
                                    </div>
                                </div>
                            </div>     
                        </div>
                        <div className="d-flex gap-2 justify-content-end">
                            {BuildTableAction(actionsProperties,props.openDialog)}
                        </div>  
                    </div>
                    <div className="bg-white d-flex justify-content-center image-text border-top">
                        <span className="image">
                            <img src={mainTheme.appLogo} alt="logo" className="logo" />
                        </span>

                        <div className="text logo-text">
                            <span className="name"> PIKADO</span>
                        </div>
                    </div>
                </div>               
            </div>
           

            
}
