import BaseBackComponent from './../../../base-back-component';
import CustomerOrderScreen from './screen';
import ClientordersService
  from './../../../../../external-communication/services/clientorders.service';

export default class CustomerOrderComponent extends BaseBackComponent {
  name = 'customer-order';

  options = [
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    },
    {
      title: 'products',
      keys: ['articleName'],
      optionValue: 'articleId',
      dataToGetForGroupTitle: ['subFamilyName'],
      imgTarget: 'image',
      source: 'articles',
      method: 'articlesWithPricing',
    },
  ];

  state = {
    ...this.state,
    screen: CustomerOrderScreen,
    searchOptions: {
      ...this.state.searchOptions,
      state: null,
      customerId: null,
      orderNumber: null,
      date: null,
    },
  };

  async componentDidMount () {
    let status = '';

    let state = this.props.params.state;

    if (state === 'in wait') {
      status = 0;
    } else if (state === 'cancel') {
      status = 3;
    }
    await this.setState ({
      service: new ClientordersService (),
      searchOptions: {
        ...this.state.searchOptions,
        state: status,
      },
    });
    this.initDataLoading ();
    this.loadOptions ();
  }
}
