import DefaultQuery from '../../vendor/bases/default.query';

export default class ClientsQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'clients',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list', 'customers'), params);
  }

  async create (credentials) {
    return await this.createMethod (
      this.buildPath ('', 'customers'),
      credentials
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`${id}`, 'customers'));
  }

  async loadList () {
    return await this.getMethod (this.buildPath ('', 'customers'));
  }

  async update (id, credentials) {
    return await this.updateMethod (
      this.buildPath (`${id}`, 'customers'),
      credentials
    );
  }

  async delete (id) {
    return await this.deleteMethod (this.buildPath (`${id}`, 'customers'));
  }

  async getUnpaidInvoices (customerId) {
    return await this.getMethod (this.buildPath (`invoice-unpaid/${customerId}`, 'customers'));
  }


  async invoicesList(options){
    return await this.getMethod (this.buildPath ('invoice-list', 'customers'), options);
  }

  async invoiceDetail (invoiceId) {
    return await this.getMethod (this.buildPath (`invoice/${invoiceId}`, 'customers'));
  }
}
