import RolesService
  from '../../../external-communication/services/roles.service';
import BaseBackComponent from '../base-back-component';
import ResourcesService
  from './../../../external-communication/services/resources.service';

export default class RolesComponent extends BaseBackComponent {
  name = 'Roles';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      isActive: null,
      name: null,
    },
  };

  constructor (props) {
    super (props);
    this.loadAuthorities = this.loadAuthorities.bind (this);
    this.loadResources = this.loadResources.bind (this);
    this.callAuthoritiesResources = this.callAuthoritiesResources.bind (this);
    this.saveAuthoritiesChanges = this.saveAuthoritiesChanges.bind (this);
    this.handleAuthorities = this.handleAuthorities.bind (this);
    this.globalCheck = this.globalCheck.bind (this);

    this.state = {
      ...this.state,
      authorities: [],
      loadRoleAuthorities: this.loadAuthorities,
      resources: [],
      callAuthoritiesResources: this.callAuthoritiesResources,
      authoritiesByResource: [],
      authoritiesCredentials: {},
      saveAuthoritiesChanges: this.saveAuthoritiesChanges,
      handleAuthorities: this.handleAuthorities,
      checkAll: this.globalCheck,
    };
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadResources ();
  }

  async loadAuthorities (id) {
    const service = new RolesService ();

    const response = await service.loadAuthorities (id);

    const roleAuthorities = await service.getRoleAuthorities (id);
    this.setState ({
      authorities: response.data,
      authoritiesCredentials: {
        role_id: id,
        authorities: Array.isArray (roleAuthorities.data)
          ? roleAuthorities.data
          : [],
      },
    });
  }

  async loadResources () {
    const service = new ResourcesService ();
    const response = await service.all ();

    this.setState ({
      resources: response.data,
    });
  }

  async callAuthoritiesResources (value) {
    await this.loadAuthoritiesByResourceId (value);
  }

  async loadAuthoritiesByResourceId (id) {
    const service = new ResourcesService ();

    const response = await service.resourceAuthorities (id);
    this.setState ({
      authorities: response.data,
    });
  }

  async handleAuthorities (value) {
    const currentIndex = this.state.authoritiesCredentials.authorities.indexOf (
      value
    );

    const newChecked = [...this.state.authoritiesCredentials.authorities];

    if (currentIndex === -1) {
      newChecked.push (value);
    } else {
      newChecked.splice (currentIndex, 1);
    }

    await this.setState ({
      authoritiesCredentials: {
        ...this.state.authoritiesCredentials,
        authorities: newChecked,
      },
    });
  }

  async globalCheck (status, values = []) {
    let currentAuthorities = this.state.authoritiesCredentials.authorities;

    if (status === 'check') {
      currentAuthorities = currentAuthorities.concat (values);
    } else {
      values.forEach (val => {
        const index = currentAuthorities.findIndex (
          authoritiesId => authoritiesId === val
        );
        if (index !== -1) currentAuthorities.splice (index, 1);
      });
    }
    await this.setState ({
      authoritiesCredentials: {
        ...this.state.authoritiesCredentials,
        authorities: currentAuthorities,
      },
    });
  }

  async saveAuthoritiesChanges (e) {
    e.preventDefault ();
    const response = await new RolesService ().setAuthorities (
      this.state.authoritiesCredentials
    );

    if (response.success) {
      this.loadFormMessage (
        'success',
        response.message || 'Succès enregistrement des droits'
      );
      this.setState ({closeDialog: !this.state.closeDialog});
    } else {
      this.loadFormMessage (
        'error',
        response.message || 'Echec enregistrement des droits'
      );
    }
  }
}
