import Service from '../../vendor/bases/service';

export default class ClientsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'clients',
    });
  }

  async unpaidInvoice (customerId = null) {
    return await this.treatQuery (this.query.getUnpaidInvoices (customerId));
  }

  async invoicesList(options){
    return await this.treatQuery (this.query.invoicesList (options));
  }

  async invoiceDetail(invoiceId){
    return await this.treatQuery (this.query.invoiceDetail (invoiceId));
  }
}
