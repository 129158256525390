import BaseBackScreen from '../base-back-screen';
import AttributePassword from './_layouts/PPasswordDialog';

export default class UsersScreen extends BaseBackScreen {
    name = 'Users';

    loadOtherDialogContent(actionName,title=null,dataId=null){
        let otherContent = {};
        if(actionName === 'access'){
            let data = {
                userId : dataId,
                onHandlePasswordChange : this.props.data.changePassword,
                onSubmitPasswordChange : this.props.data.onSubmitChanges,
            };
            
            otherContent = {
                actionName : actionName,
                componentName :  title,
                pDialogRender : AttributePassword,
                data : data
            }
        } else{
            otherContent = {
                actionName : actionName,
                componentName :  title,
                dataReference : dataId,
                validAction :this.props.data?.screenActions?.validOtherAction 
            }
        } 
        return otherContent;
    }

    async otherUpdate(prevProps){
        if(this.props.data !== prevProps?.data){
            if(this.props.data.formPayload !== prevProps.data.formPayload){
                if(this.state.dialogInformation.dialogContent.actionName === "access"){
                    await this.setState({
                        dialogInformation : {
                            ...this.state.dialogInformation,
                            dialogContent : {
                                ...this.state.dialogInformation.dialogContent,
                                data : {
                                ...this.state.dialogInformation.dialogContent.data,
                                 field : this.props.data?.formPayload
                                }
                            }
                        },
                    });
                }
                
            }
        }
    }

}
