import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const configInventoryCash = {
  name: 'inventorycash',
  title: 'Inventaire de caisse',
  typeFrame: 'list',
  fields: [
    {
      name: 'startDate',
      title: 'Date début',
      type: 'datetime',
    },
    {
      name: 'endDate',
      title: 'Date fin',
      type: 'datetime',
      col: 6,
    },
    {
      name: 'theoreticalBalance',
      title: 'Solde théorique',
      type: 'money',
    },
    {
      name: 'amountCounted',
      type: 'money',
      title: 'Montant recensé',
    },
    {
      name: 'defference',
      type: 'money',
      title: 'Ecart',
    },
    {
      name: 'consolidateDate',
      title: 'Date consolidation',
      type: 'datetime',
    },
    {
      name: 'date',
      title: 'Date',
      type: 'dateTime-interval',
    },
    {
      name: 'state',
      title: 'Etat',
      options: {
        0: {
          badgeColor: 'orange',
          text: 'En attente',
          textColor: 'white',
        },
        1: {
          badgeColor: '#5F9EA0',
          text: 'Consolidé',
        },
        2: {
          badgeColor: '#DC143C',
          text: 'Annulé',
        },
      },
      type: 'state-multiple',
    },
    {
      name: 'amountCounntedByType',
      title: 'Montant compté par moyen de paiement',
      htmlElement: 'dynamicUnChangeForm',
      fields: [
        /*{
          name: 'type',
          title: 'Moyen de paiement',
          htmlElement: 'select',
          options: [
            {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
            {value: 'TPE', label: 'TPE'},
            {value: 'BANQUE', label: 'BANQUE'},
            {value: 'CASH', label: 'CASH'},
          ],
        },*/
        {
          name: 'type',
          title: 'Moyen de paiement',
          htmlElement: 'disableInput',
          editableDisable: true,
        },
        {
          name: 'amountCounted',
          title: 'Montant compté',
          type: 'number',
        },
      ],
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Consolidé',
      color: 'primary',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler commande',
      color: 'warning',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    /*{
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },*/
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  excludeToForms: [
    'state',
    'startDate',
    'endDate',
    'theoreticalBalance',
    'amountCounted',
    'defference',
    'consolidateDate',
  ],
  excludeToHeaders: ['amountCounntedByType'],
  filterOptions: [
    {
      name: 'state',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'En attente',
          value: 0,
        },
        {
          label: 'Consolidé',
          value: 1,
        },
      ],
    },
    {
      name: 'date',
      type: 'interval-time',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (configInventoryCash);
