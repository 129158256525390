import RecapComponent from './recap';

import {
  BaseConfiguration,
} from '../../../../../vendor/base-component-class/config.class';

const supplierDeliveriesFrameConfig = {
  name: 'supplierdeliveries',
  title: 'Facture(s) fournisseur',
  componentBeforeRenderList: RecapComponent,
  hideBar: true,
  typeFrame: 'list',
  fields: [
    {
      name: 'date',
      title: 'Date facture',
      type: 'date',
      col: 4,
    },
    {
      name: 'invoiceNumber',
      title: 'N° facture',
    },
    {
      name: 'supplierOrderId',
      title: 'N° de commande',
      col: 4,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'supplierorders',
      isObject: true,
      fieldsSelected: ['supplierorders'],
      selectDataValue: {
        name: 'supplierorder',
        options: {
          valueOptions: ['id'],
          labelOptions: ['orderingNumber'],
        },
      },
    },
    {
      name: 'invoiceCost',
      title: 'Montant facture'
    },
    {
      name: 'paid',
      title: 'Etat',
      options: {
        0: {badgeColor: '#DC143C', text: 'Impayée', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Payée'},
      },
      type: 'checkBox',
    },
  ],
  excludeToHeaders: ['depotId', 'supplierOrderId', 'rawMaterialTodelivery'],
  excludeToForms: ['depot', 'state', 'supplierOrder', 'deliveryNumber'],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
  ],
  filterOptions: [
    {
      name: 'paid',
      title: 'Statut',
      type: 'select',
      options: [
        {
          label: 'Impayée',
          value: 0,
        },
        {
          label: 'Payée',
          value: 1,
        },
      ],
    },
    
    {
      name: 'invoiceNumber',
      title: 'N° de facture',
    },
    {
      name: 'date',
      type: 'interval',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (supplierDeliveriesFrameConfig);
