
export default function CoordinatesComponent (props) {
    const supplierInformation = props.params?.supplierInfo || null;

  return (
    <div className="card h-100 m-1">
        <div className="card-body">
            <div>
                <h6 className="d-flex align-items-center p-2 text-white  supplier-title">
                COORDONNEES
                </h6>
            <hr/>

            </div>
            <div className="row">
                <div className="col-sm-3">
                    <h6 className="mb-0">Email</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                    {supplierInformation?.email}        
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-sm-3">
                <h6 className="mb-0">Téléphone</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                    {supplierInformation?.tel}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-sm-3">
                <h6 className="mb-0">Adresse</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                {supplierInformation?.addresse}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-sm-3">
                <h6 className="mb-0">Solde Plafond</h6>
                </div>
                <div className="col-sm-9 text-secondary">
                {
                    Intl.NumberFormat('fr-FR',{ style: 'currency',
                    currency: 'XOF',
                    maximumSignificantDigits: 3,
                        }).format(supplierInformation?.ceilingBalance)
                }
                </div>
            </div>
        </div>
    </div>
  );
}
