import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const summaryChargeAndProductFrameConfig = {
  name: 'summarychargeandproduct',
  title: 'RESUME DES CHARGES ET PRODUITS',
  typeFrame: 'list',
  fields: [],
  filterOptions: [],
  hideBar: true,
};

export default BaseConfiguration (summaryChargeAndProductFrameConfig);
