import DefaultQuery from '../../vendor/bases/default.query';

export default class ResourcesQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'resources',
    });
  }

  async readAll () {
    return await this.getMethod (this.buildPath (''));
  }

  async resourceAuthorities (resourceId) {
    return await this.getMethod (
      this.buildPath (`list/${resourceId}`, 'authorities')
    );
  }
}
