import {Fragment,useState} from 'react';
import TextField from '@mui/material/TextField';
import {
    IconButton,
    InputAdornment, 
  } from '@mui/material';
  import {Visibility, VisibilityOff} from '@mui/icons-material';


const AttributePassword = props => {

  const [showPassword, setShowPassword] = useState (false);

  const handleClickShowPassword = () =>
    setShowPassword (showPassword => !showPassword);

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  return (
    <Fragment>
      <div className="p-3 my-2 text-center bg-primary text-white fw-bold">
        ATTRIBUTION DE MOT DE PASSE
      </div>
      <div className="mt-3 mb-1">
        <div className="col-12">
            <TextField
              error={props.data.data?.field?.password?.errors ? true : false}    
              required
              autoComplete="false"
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              onChange={props.data.data.onHandlePasswordChange}
              value={props.data.data?.field?.password?.value || ""}
              helperText={props.data.data?.field?.password?.errors || null}
              InputProps={{
                endAdornment:   <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>,
            }}
            fullWidth
            label="Mot de passe"
            />
        </div>    
      </div>

      <div className="mt-2 d-flex justify-content-end">
        <button id={props.data.data.userId} onClick={props.data.data.onSubmitPasswordChange} type="button" className="btn  btn-block btn-primary p-2">
          Sauvegarder
        </button>
      </div>
    </Fragment>
  );
};

export default AttributePassword;
