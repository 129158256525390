import BaseBackComponent from '../base-back-component';

export default class FsinvoiceComponent extends BaseBackComponent {
  name = 'Fsinvoice';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      name: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
