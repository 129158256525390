import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const salesByWeekFrameConfig = {
  name: 'salesbyweek',
  title: 'VENTES PAR SEMAINE',
  typeFrame: 'list',
  fields: [],
  filterOptions: [],
};

export default BaseConfiguration (salesByWeekFrameConfig);
