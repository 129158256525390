import { useRef} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import mainTheme from '../../../../configs/mainTheme';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import { capitalizeFirstLetter } from './../../../../bootstrap/Helpers/utils';
import './single.css'
import { useReactToPrint } from 'react-to-print';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style';

const SingleProduct = props => {
  const recipeInformation  = props.data.rows;
  const componentRef = useRef();
  const onPrint =   useReactToPrint({
      content : () => componentRef.current
  });

  const formatToExportList = data => {
    return data.map(
      item => {
        return {
          nom : item?.rawMaterial?.name,
          quantite : item?.qty +" "+item?.rawMaterial?.unit?.libelle
        }
      }
    );
  }
  
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = '.xlsx';

  const onExport = (e,fileName) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(formatToExportList(recipeInformation?.recipeList));
    const wb = {Sheets:{'data' : ws}, SheetNames:['data']};
    const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'})
    const data = new Blob([excelBuffer],{type:fileType})
    FileSaver.saveAs(data,fileName+fileExtension);
  }

  
  return (
    <div className="recipe-card-content">
      <div className="d-flex">
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: 'green', mr: 1}}
          startIcon={<FontAwesomeIcon icon="print" />}
          onClick={onPrint}
        >
          Imprimer
        </Button>
        <Button
          variant="outlined"
          sx={{color: 'white', bgcolor: mainTheme.mainColor}}
          startIcon={<FontAwesomeIcon icon="file-export" />}
          onClick={(e) => onExport(e,"recette "+recipeInformation?.qty +" "+recipeInformation?.product?.name)}
        >
          Exporter
        </Button>
      </div>
      <div ref={componentRef} className="mt-2 p-2">
        <div className="text-center mt-4 mb-2">
          <h6 className="recipe-number">FICHE RECETTE #{recipeInformation?.id}</h6>
        </div>
        <div className="recipe-content">
          <div className="p-2 text-center text-white recipe-title">
            <h4>{recipeInformation?.qty +" "+recipeInformation?.product?.name}</h4>
          </div>

          <div className="my-2 p-2">
              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead>
                          <TableRow>
                              <TableCell sx={{ fontWeight:"bold" }}>COMPOSANT(S)</TableCell>
                              <TableCell sx={{ fontWeight:"bold" }}></TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              recipeInformation?.recipeList?.map(
                                  (recipeItem,key) => {
                                      return <TableRow
                                                  key={recipeItem?.wayTitle?.toLowerCase()}
                                              >
                                                  <TableCell component="td" scope="name">
                                                    {capitalizeFirstLetter(recipeItem?.rawMaterial?.name)}
                                                  </TableCell>
                                                  <TableCell>
                                                  { recipeItem?.qty?.toLocaleString("fr-FR") +" "+recipeItem?.rawMaterial?.unit?.libelle?.toUpperCase() }
                                                  </TableCell>
                                              </TableRow>  
                                  }
                              )
                          }
                          
                      </TableBody>
                  </Table>
              </TableContainer>
          </div>
        </div>  
        <div className="d-flex justify-content-center image-text">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
