import StyledTable from "../../../../../vendor/builders/Table/custom-table";

export default function Fees (props) {

    const majList = props?.params?.majList?.map(
        (row,index) => {
            return {
                department: row?.departement?.name,
                id : index,
                majRate : row?.majorationAmount + "%" 
            }
        }
      );
    
      const exList = props?.params?.exonerationsList?.map(
        (row,index) => {
            return {
                department: row?.departement?.name,
                id : index,
                exemptionRate : row?.exemptionRate + "%" 
            }
        }
      );

  return (
    <div className="invoice-body mt-2">
    {/* Row start */}
    <div className="row gutters">
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="p-2 text-left fst-italic border-1 border-dark border-bottom">
                <h6>Majoration(s)</h6>
            </div>  
        <div className="table-responsive">
           <StyledTable 
                tableParams={
                    {  
                        headers : [
                            {
                                name : "department",
                                title : "Département"
                            },
                            {
                                name : "majRate",
                                title : "Pourcentage"
                            }
                        ],
                        rows : majList,
                    }
                } 
            />
        </div>
        </div>
        <hr/>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="p-2 text-left fst-italic border-bottom">
                <h6>Exonération(s)</h6>
            </div>  
            <div className="table-responsive">
                <StyledTable 
                    tableParams={
                        {  
                            headers : [
                                {
                                    name : "department",
                                    title : "Département"
                                },
                                {
                                    name : "exemptionRate",
                                    title : "Pourcentage"
                                }
                            ],
                            rows : exList,
                        }
                    } 
                />
            </div>
        </div>              
    </div>                     
    {/* Row end */}
</div>
  );
}
