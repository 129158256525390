import { Card, CardContent, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import 
  DateRangePickerValue from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import mainTheme from '../../../configs/mainTheme';
import { capitalizeFirstLetter } from '../../../bootstrap/Helpers/utils';

export default function RenderSummaryChargeAndProduct (props) {
  let renderConfigs = props.renderFrameConfigs;

  const changeDateValue = (e, value = null) => {
    renderConfigs.handleSearchOption (e, value, 'date');
  };

    let charges = buildRenderingData("charges",renderConfigs?.fetchData?.chargeList);
    
    let products = buildRenderingData("produits",renderConfigs?.fetchData?.produitList);

  return (
    <div className="">
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />
      <div className="my-2 p-2">
        <DateRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>
      <Card sx={{width: '100%',my:2}}>
        <FrameTitle
          name={renderConfigs?.name}
          title={"LISTING DES PRODUITS"}
          openDialog={(e) => {}}
          actions={[]}
        />
        <CardContent>
        <div className="table-responsive">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead sx={{ bgcolor:mainTheme.color }}>
                      <TableRow>
                          {
                            products?.headers.map(
                              (key) =>  <StyledTableCell  key={key.title} sx={{ fontWeight:"bold" }}>
                                          {capitalizeFirstLetter(key.title || "")}
                                        </StyledTableCell>    
                            )
                          }
                          
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          {
                              products?.data?.length > 0 ?  
                              products?.data?.map(
                                (product) => {
                                  return <TableRow
                                                key={product?.produitsTitle}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                             { 
                                                products?.headers.map(
                                                  header => {
                                                    return <TableCell key={header.name} component="td" scope={header.name}>
                                                              {product?.[header.name]}
                                                           </TableCell>
                                                  }
                                                )
                                             }
                                              
                                          </TableRow>
                                   }
                              )
                            
                            :   <TableRow>
                                    <TableCell sx={{ textAlign:"center" }} colSpan={products?.headers?.length} component="td" scope="row">
                                        Aucun produit  :)
                                    </TableCell>
                                </TableRow>
                          }
                         
                  </TableBody>
                </Table>
            </TableContainer>
        </div>
        </CardContent>
    </Card>
    <Card sx={{width: '100%'}}>
        <FrameTitle
          name={renderConfigs?.name}
          title={"LISTING DES CHARGES"}
          openDialog={(e) => {}}
          actions={[]}
        />
        <CardContent>
        <div className="table-responsive">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead sx={{ bgcolor:mainTheme.color }}>
                      <TableRow>
                          {
                            charges?.headers.map(
                              (key) =>  <StyledTableCell  key={key.title} sx={{ fontWeight:"bold" }}> {capitalizeFirstLetter(key.title || "")}</StyledTableCell>    
                            )
                          }
                          
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          {
                              charges?.data?.length > 0 ?  
                              charges?.data?.map(
                                (charge) => {
                                  return <TableRow
                                                key={charge?.chargesTitle}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                             { 
                                                charges?.headers.map(
                                                  header => {
                                                    return <TableCell key={header.name} component="td" scope={header.name}>
                                                              {charge?.[header.name]}
                                                           </TableCell>
                                                  }
                                                )
                                             }
                                              
                                          </TableRow>
                                   }
                              )
                            
                            :   <TableRow>
                                    <TableCell sx={{ textAlign:"center" }} colSpan={charges?.headers?.length} component="td" scope="row">
                                        Aucune charge  :)
                                    </TableCell>
                                </TableRow>
                          }
                         
                  </TableBody>
              </Table>
            </TableContainer>
        </div>
        </CardContent>
    </Card>
     
    </div>
  )
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


function buildRenderingData(name, dataToFormat = {}){
  let otherHeaders = []; 
  let dataToReturn = [];


  for(let data in dataToFormat){
    let newObject = {};
    let total = 0;
    newObject[name+"Title"] = data.toUpperCase();


    Object.keys(dataToFormat[data]).forEach(
      key => {
        if(!otherHeaders.find(header => header.name === key)){
          otherHeaders.push({
            title : key,
            name : key
          });   
        }
       
        total += dataToFormat[data][key];
        newObject = {
          ...newObject,
          [key] : Intl.NumberFormat('fr-FR',{ style: 'currency',
          currency: 'XOF',
          
            }).format( dataToFormat[data][key]) 
        }
      }
    )
    
    newObject.total = Intl.NumberFormat('fr-FR',{ style: 'currency',
      currency: 'XOF',
      
        }).format( total);
    


    dataToReturn.push(newObject)
  }

  
  return {
    headers : [
      {title : "Liste des "+name , name : name+"Title"},
      ...otherHeaders,
      {title : "Total", name : "total"}
      ],
    data : dataToReturn
  }
}