import { Dialog, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import {Fragment, useState} from 'react';
import  {DatePicker}  from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';
import mainTheme from '../../../configs/mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleSellRow from './_layouts/single';
import React from 'react';
import moment from 'moment';



export default class ResumeRender extends React.Component{
  
  constructor(props){
    super(props);

    this.state = {
      sales : []
    }
  }

  componentDidUpdate(prevProps,prevState){
    if(this.props !== prevProps){
      if(this.props.renderFrameConfigs.fetchData !== prevProps.renderFrameConfigs.fetchData){
        console.log(this.props.renderFrameConfigs.fetchData)
        this.setState({
          sales : this.props.renderFrameConfigs.fetchData
        })
      }
    }
  }
  
  
  render(){
    return <SellresumepersubfamilyRender {...this.props} sales = {this.state.sales} />
  }
}



function SellresumepersubfamilyRender (props) {
    let renderConfigs = props.renderFrameConfigs
    ;
    let [date,setDate] = useState(null);

    const [openModal,setOpenModal] = useState(false);
    const [row,setRow] = useState(null);

    const changeDateValue = (e, value = null) => {
      setDate(e.target.value);
      renderConfigs?.handleSearchOption (e, value, 'date');
    };

    const data = props.sales || [];


    let totalSold = data.length > 0 ? data?.map(line =>  line.subFamilyCost).reduce((accumulator, current) => accumulator + current) : 0;

    const openOrCloseDetail = (e,row = null) => {
      setOpenModal(!openModal);
      setRow(row);
      
    }

  return (
    <Fragment>
      <div className="title text-white p-2">
        RESUME DE VENTE PAR SOUS-FAMILLE
      </div>
      <div className="my-2 p-2">
        <DatePicker
          size="small"
          properties={{
            name: 'date',
            value : date,
            changeDate: (e) => {changeDateValue(e)}
          }}
        />
      </div>

      <div className="row p-2 my-2">
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-info">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Total chiffre d'affaire du {date ? moment(date).format('DD/MM/YYYY') : ""}</p>
                  <h5 className="my-1 text-info">  
                      { Intl.NumberFormat('fr-FR',{ style: 'currency',
                                currency: 'XOF',
                        }).format(totalSold)
                      } </h5>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                  <FontAwesomeIcon icon="wallet" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

        <div className="table-responsive">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead sx={{ bgcolor:mainTheme.color }}>
                      <TableRow>
                          <StyledTableCell  sx={{ fontWeight:"bold" }}>
                             SOUS-FAMILLE
                          </StyledTableCell>  
                          <StyledTableCell  sx={{ fontWeight:"bold" }}>
                              Quantite vendue
                          </StyledTableCell>  
                          <StyledTableCell  sx={{ fontWeight:"bold" }}>
                             Montant total vente
                          </StyledTableCell>  
                          <StyledTableCell  sx={{ fontWeight:"bold" }}>
                             
                          </StyledTableCell>  
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          {
                              data?.length > 0 ?  
                              data?.map(
                                (row) => {
                                  return <TableRow
                                                key={row?.payementType}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                              <TableCell component="td" scope={"subFamily"}>
                                                {row?.subFamily?.toUpperCase()}
                                              </TableCell>
                                              <TableCell component="td" scope={"qtySelled"}>
                                                { row?.qtySelled}
                                              </TableCell>
                                              <TableCell component="td" scope={"subFamilyCost"}>
                                                {Intl.NumberFormat('fr-FR',{ style: 'currency',
                                                currency: 'XOF',
                                              
                                                  }).format(  row?.subFamilyCost)}
                                              </TableCell>
                                              <TableCell component="td" scope={"actions"}>
                                                  <button onClick={(e) => {openOrCloseDetail(e,row)}} className='btn btn-sm btn-primary'>
                                                      <FontAwesomeIcon icon={'eye'} />
                                                  </button>
                                              </TableCell>
                                              
                                          </TableRow>
                                   }
                              )
                            
                            :   <TableRow>
                                    <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                        Aucune ligne trouvée :)
                                    </TableCell>
                                </TableRow>
                          }
                         
                  </TableBody>
                </Table>
            </TableContainer>
            <BootstrapDialog
              onClose={openOrCloseDetail}
              aria-labelledby="customized-dialog-title"
              open={openModal}
            >
              <SingleSellRow row={row}/>
            </BootstrapDialog>  
        </div>
    </Fragment>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

