import React from 'react';
import CashflowLoopbackComponent from './by-cashflow-loopback';
import CAStatsByDepartmentComponent from './by-department';
import CAStatsBySubfamilyComponent from './by-subfamily';
import CAStatsBySalesWeekComponent from './by-week-sales';
import StatsByCAEvolutionComponent from './by-ca-evolution';
import StatsTemplate from '../stats.template';

export default function StatsByCA () {
  return <StatsTemplate components={components} />;
}

const components = [
  {
    title: 'byDepartment',
    label: 'CA Par département',
    renderComponent: CAStatsByDepartmentComponent,
    isVisible: true,
  },
  {
    title: 'bySubfamily',
    label: 'CA par sous famille',
    renderComponent: CAStatsBySubfamilyComponent,
    isVisible: true,
  },
  {
    title: 'byWeekSales',
    label: 'CA par vente en semaine',
    renderComponent: CAStatsBySalesWeekComponent,
    isVisible: true,
  },
  {
    title: 'byCashflowLoopback',
    label: 'Flux de trésorerie',
    renderComponent: CashflowLoopbackComponent,
    isVisible: true,
  },
  {
    title: 'byCaEvolution',
    label: "Evolution chiffre d'affaire",
    renderComponent: StatsByCAEvolutionComponent,
    isVisible: true,
  },
];
