import   DateRangePickerValue
 from '../../../vendor/builders/Frame/_layouts/search/_layouts/dates/simple-date';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import ChartBuilder from '../../../vendor/builders/Chart';
import { Fragment } from 'react';

export default function RenderChargeProductEvolution (props) {
  let renderConfigs = props.renderFrameConfigs;

  const changeDateValue = (e, value = null) => {
    renderConfigs.handleSearchOption (e, value, 'date');
  };

  let xLabels = [];


  let resultSeries = [];
  let chargesSeries = [];
  let productSeries = [];

  if (renderConfigs.fetchData) {
   
    
    for (let data in renderConfigs.fetchData) {
      xLabels.push(renderConfigs.fetchData[data]?.departementName);
      chargesSeries.push(renderConfigs.fetchData[data]?.totalCharge)
      productSeries.push(renderConfigs.fetchData[data]?.totalProduct);
      resultSeries.push(renderConfigs.fetchData[data]?.result);
    }

   

  }

  return (
    <Fragment >
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />
      <div className="my-2 p-2">
        <DateRangePickerValue
          size="small"
          changeSearchValue={changeDateValue}
          data={{
            name: 'date',
          }}
        />
      </div>

      <div>
          <div className="title text-white p-2">
            SYNTHÈSE PRODUITS/CHARGES 
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <ChartBuilder
                chartType="bar"
                series={[  { data: chargesSeries },
                  { data: productSeries }]} 
                xAxis={xLabels}
            />
        
          </div>
      </div>    
      
      <div className='my-2'>
          <div className="title text-white p-2">
            RESULTAT PAR DÉPARTEMENT 
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <ChartBuilder
                chartType="line"
                series={[
                  { data: resultSeries,type: 'line' },
                ]} 
                xAxis={xLabels}
            />
          </div>
      </div> 
    </Fragment>
  );
}
