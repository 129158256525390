import BaseBackComponent from '../base-back-component';

export default class AnalytiqueplansComponent extends BaseBackComponent {
  options = [
    {
      title: 'departements',
      keys: ['name'],
    },
  ];

  name = "Analytiqueplans";

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      libelle : null,
      code : null,
      acronym : null,
      chargeCategory : null,
      departementId : null,
      analyticalCode : null
    }
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      departementId : {value : data?.departement?.value?.id}
    };
    return data;
  }
}
