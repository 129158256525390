import DepotsService from '../../../external-communication/services/depots.service';
import BaseBackComponent from '../base-back-component';

export default class WithdrawsComponent extends BaseBackComponent {
  name = 'Withdraws';

  options = [
    {
      title: 'initialDepots',
      keys: ['name'],
      source : "depots"
    },
    {
      title: 'finalDepots',
      keys: ['name'],
      source : "depots"
    },
    
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      transferNumber: null,
      date: null,
      initialDepotId: null,
      finalDepotId: null,
      state: null,
    },
    depotArticles : []
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    const articleList = data?.articleTransferredList.value.map(
      article => {
        return {
          articleId : {value : article.article.id},
          qtyTransferred : {value : article.qtyTransferred},
        }
      }
    ) 
    
   let depots = this.state.options.find(option => option.title === "depots")?.options || [];
   
    data = {
      ...data,
      initialDepotId : {value : depots?.find(depot => depot.label?.toLowerCase().includes(data?.initialDepotName?.value?.toLowerCase()))?.value},
      finalDepotId : {value : depots?.find(depot => depot.label?.toLowerCase().includes(data?.finalDepotName?.value?.toLowerCase()))?.value},
      articleTransfered : {value : articleList}
    };
    return data;
  }

  async otherHandleFieldTreatment(name,value){
    if(name === "initialDepotId"){
      const finalDepots = this.initFinalDepotArray(value);
      const newArticles = await this.loadInitialDepotArticles(value);

      let newOptions = this.state.options;
      
      const finalDepotOptionsIndex = newOptions.findIndex(
        option => option.title === "finalDepots"
      );

      if(finalDepotOptionsIndex !== -1){
        newOptions.splice(finalDepotOptionsIndex,1);
      }
      newOptions.push(finalDepots);


      const articlesOptionsIndex = newOptions.findIndex(
        option => option.title === "articles"
      );
      
      if(articlesOptionsIndex !== -1){
        newOptions.splice(articlesOptionsIndex,1);
      }

      newOptions.push({title : "articles",options : newArticles});
      await this.setState({options : newOptions})
    }

  }

  initFinalDepotArray(depotId){ 
    
    const newFinalDepots = this.state.options.find(opt => opt.title === "initialDepots")
                                    .options.filter(item => item.value !== depotId);

    return {title : 'finalDepots',options : newFinalDepots}
  }

  async loadInitialDepotArticles(depotId){
    let queryDepotArticles = {};
    let articles  = [];
    if(depotId && depotId !== ""){
      queryDepotArticles =  await new DepotsService().getArticles(depotId);
      if(queryDepotArticles?.success){
        articles = this.loadDepotArticles(queryDepotArticles?.data?.articleStock);
        await this.setState({depotArticles : queryDepotArticles?.data?.articleStock});
      }
    }
    return articles;
  }

  loadDepotArticles(articles){
    return articles.filter(article => article.qtyTransferred !== 0).map (item => {
      return {
        value: item.id,
        label: item.articleName,
      };
    });
  }
  
  async handleSubForm(index,name,value,currentPayLoad = null){

    if(name === "articleId"){
      let qty = null;
      if(value && value !== ""){
       const article = this.state.depotArticles.find(item => item.id === value);
       qty = article?.qty;
      }

      currentPayLoad[index].qtyTransferred = {value : qty,maxValue : qty,minValue : 0};

      
      
      await this.setState({
        formPayload : {
          ...this.state.formPayload,
          articleTransfered : {value : currentPayLoad} 
        }
      });


    }
  }
}
