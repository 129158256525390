import StateRender from './_layouts/state-render';

import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const cashflowFrameConfig = {
  name: 'cashflowmovement',
  title: 'Flux de Trésorerie',
  typeFrame: 'list',
  fields: [
    {
      name: 'date',
      title: 'date',
      type: 'date',
    },
    {
      name: 'libelle',
      title: 'Libéllé',
    },
    {
      name: 'accountingType',
      title: 'Type de comptabilité',
    },
    {
      name: 'amount',
      title: 'Montant',
      type: 'money',
    },
    {
      name: 'typeTiers',
      title: 'Type tiers',
    },
    {
      name: 'auxiliaryAccount',
      title: 'Auxiliarisation',
    },
    {
      name: 'cashFlowMovement',
      title: 'Mouvement trésorerie',
    },
    {
      name: 'paymentMode',
      title: 'Moyen de paiement',
    },
    {
      name: 'accountTiers',
      title: 'Nom tiers',
    },
  ],
  excludeToForms: [
    'type',
    'date',
    'paymentMode',
    'accountingType',
    'amount',
    'typeTiers',
    'date',
    'auxiliaryAccount',
    'cashFlowMovement',
    'accountTiers',
  ],
  actions: [],
  filterOptions: [
    {
      name: 'paymentMode',
      title: 'Mode de paiement',
      type: 'select',
      options: [
        {value: 'MOBILE MONEY', label: 'MOBILE MONEY'},
        {value: 'CREDIT', label: 'CREDIT'},
        {value: 'BANQUE', label: 'BANQUE'},
        {value: 'CASH', label: 'CASH'},
      ],
    },
    {
      name: 'date',
      title: 'date',
      type: 'interval',
    },
  ],
  componentBeforeRenderList: StateRender,
};

export default BaseConfiguration (cashflowFrameConfig);
