import UsersService from '../../../external-communication/services/users.service';
import BaseBackComponent from '../base-back-component';

export default class UsersComponent extends BaseBackComponent {
  name = 'Users';

  options = [
    {
      title: 'roles',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      firstName: null,
      tel: null,
      lastName: null,
      depot: null,
    },
  };

  constructor(props){
    super(props);
    this.handleLoginField = this.handleLoginField.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
    this.state = {
      ...this.state,
      changePassword : this.handleLoginField,
      onSubmitChanges :  this.submitChangePassword
    };
   
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      roles : {value : data?.roleIds?.value?.split(',')}
    };
    return data;
  }

  async handleLoginField(e){
    let name = e.target.name;
    await this.setState(
      (state) => ({
        ...state,
        formPayload : {
          ...state.formPayload,
          [name] : {
            ...state.formPayload?.[name],
            value : e.target.value
          }
        }
      })
    );
  }

  async submitChangePassword(e){
    e.preventDefault();
    const changePwdQuery = await new UsersService().changePassword(
      e.target.id,
      this.state.formPayload
    );

    if(changePwdQuery?.success){
      this.setState({closeDialog : !this.state.closeDialog});
      this.loadFormMessage('success',"Mot de passe changé avec succès");
      this.resetPayload();
      this.initDataLoading();
    }else{
      this.loadFormMessage('error',changePwdQuery?.message || 'Echec de modification mot de passe',changePwdQuery.errors);
    }
  }

}
