import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const chargeEvolutionFrameConfig = {
  name: 'chargeevolution',
  title: 'EVOLUTION DES CHARGES',
  typeFrame: 'list',
  fields: [],
  filterOptions: [
    {
      name: 'departementId',
      title: 'departement',
      type: 'select',
      optionsTarget: 'departements',
    },
    {
      name: 'categoryChargeId',
      title: 'Catégorie de charge',
      type: 'select',
      optionsTarget: 'charges',
    },
  ],
  hideBar: true,
};

export default BaseConfiguration (chargeEvolutionFrameConfig);
