import moment from 'moment';
import BaseBackComponent from '../base-back-component';
import SellandwastebysubfamilyService
  from '../../../external-communication/services/sellandwastebysubfamily.service';

export default class SellsandwastebysubfamilyComponent
  extends BaseBackComponent {
  name = 'Sellsandwastebysubfamily';

  options = [
    {
      title: 'articlesubfamillies',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    service: new SellandwastebysubfamilyService (),
    searchOptions: {
      ...this.state.searchOptions,
      year: moment (new Date ()).format ('YYYY'),
      subFamilyId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
