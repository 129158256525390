import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/images/logo.png';
import './login.css';
import {useState} from 'react';
import Button from '@mui/material/Button';

import {
  IconButton,
  InputAdornment, 
  TextField,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';

const defaultTheme = createTheme ();

export default function LoginRender (props) {

  const [showPassword, setShowPassword] = useState (false);

  const handleClickShowPassword = () =>
    setShowPassword (showPassword => !showPassword);

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  return (
    <div className="login-body">
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginY: 0,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <div className="form-container">
           
            <Box
              component="form"
              onSubmit={props.frameConfigs.onSubmitForm}
              noValidate
              sx={{my: 2}}
            >
                <div className="form-icon">
                <img
                  className="rounded-circle logo"
                  alt="user-avatar"
                  src={logo}
                />
              </div>
                  

              <div className="form-card my-2">
                <Typography component="h1" sx={{color: 'black'}} variant="h5">
                  ESPACE CONNEXION
                </Typography>
              
                <TextField
                  error={props?.dialogInformation?.dialogContent?.fieldsData?.username?.errors ? true : false}
                  sx={{ my:2 }}
                  required
                  id="email"
                  label="Email ou nom d'utilisateur"
                  fullWidth
                  name="username"
                  onChange={props.frameConfigs.handleFormField}
                  autoComplete="false"
                  value={props?.dialogInformation?.dialogContent?.fieldsData?.username?.value || ""}
                  helperText={props?.dialogInformation?.dialogContent?.fieldsData?.username?.errors || null}
                />

                 <TextField
                    error={props?.dialogInformation?.dialogContent?.fieldsData?.password?.errors ? true : false}    
                    required
                    autoComplete="false"
                    onChange={props.frameConfigs.handleFormField}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={props?.dialogInformation?.dialogContent?.fieldsData?.password?.value || ""}
                    helperText={props?.dialogInformation?.dialogContent?.fieldsData?.password?.errors || null}
                    InputProps={{
                      endAdornment:   <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>,
                    }}
                    fullWidth
                    label="Mot de passe"
                  />
              

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    my: 2,
                    backgroundColor: '#000039',
                    borderColor: '#000039',
                    color: '#fff',
                    p: 1,
                  }}
                >
                  CONNEXION
                </Button>
              </div>

            </Box>

          </div>

        </Box>
      </Container>
    </ThemeProvider>
    </div>
  );
}
