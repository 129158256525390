import {Fragment} from 'react';
import BaseBackComponent from '../base-back-component';
import QuittanceDirectPrintingRender
  from './_layouts/quittance/direct-printing';

export default class SellsComponent extends BaseBackComponent {
  name = 'Sells';

  options = [
    {
      title: 'users',
      keys: ['firstName', 'lastName'],
    },
    {
      title: 'clients',
      keys: ['firstName', 'lastName'],
    },
    {
      title: 'registers',
      source: 'cashregisters',
      keys: ['reference'],
    },
    {
      title: 'products',
      keys: ['articleName'],
      optionValue: 'articleId',
      dataToGetForGroupTitle: ['subFamilyName'],
      imgTarget: 'image',
      source: 'articles',
      method: 'articlesWithPricing',
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async afterSubmitAction (data) {
    const service =
      this.state.service || this.loadExternalCommunicationService ();

    const toPrintData = await service.one (data.id);
    this.setState ({launchPrint: true, sellToPrint: toPrintData.data});
    setTimeout (async () => {
      await this.setState ({launchPrint: false});
    }, 1000);
  }

  render () {
    const RenderScreen = this.loadScreen ();

    return (
      <Fragment>
        {this.state.launchPrint
          ? <QuittanceDirectPrintingRender sell={this.state.sellToPrint} />
          : null}
        <RenderScreen data={this.state} />;
      </Fragment>
    );
  }
}
