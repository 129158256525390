import moment from 'moment';
import BaseBackComponent from '../base-back-component';

export default class AnalysmarginComponent extends BaseBackComponent {
  name = 'Analysmargin';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: moment (new Date ()).format ('YYYY-MM-DD') +
        ',' +
        moment (new Date ()).format ('YYYY-MM-DD'),
      departementId: '',
      familyId: '',
      subFamilyId: '',
    },
  };

  options = [
    {
      title: 'departements',
      keys: ['name'],
    },
    {
      title: 'articlefamillies',
      keys: ['name'],
    },
    {
      title: 'articlesubfamillies',
      keys: ['name'],
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }
}
