import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';
import {fieldsCredentials} from './credentials';

const userFrameConfig = {
  name: 'users',
  title: 'utilisateur(s)',
  typeFrame: 'list',
  excludeToHeaders: ['roles', 'tel', 'email', 'isActive'],
  excludeToForms: ['role', 'active'],
  fields: fieldsCredentials,
  filterOptions: [
    {
      name: 'firstName',
      title: 'nom',
    },
    {
      name: 'lastName',
      title: 'prénom(s)',
    },
  ],
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'info',
      icon: 'eye',
    },
    {
      name: 'edit',
      title: 'Changer état',
      color: 'success',
      icon: 'pen',
    },
    {
      name: 'access',
      title: 'Changer accès',
      color: 'warning',
      icon: 'cog',
    },
    {
      name: 'delete',
      title: 'supprimer',
      color: 'danger',
      icon: 'trash',
    },
  ],
};

export default BaseConfiguration (userFrameConfig);
