import moment from 'moment';
import BaseBackComponent from '../base-back-component';

export default class StatsbydepartementComponent extends BaseBackComponent {
  name = 'Statsbydepartement';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: moment (new Date ()).format ('YYYY-MM-DD HH:mm:ss') +
        ',' +
        moment (new Date ()).format ('YYYY-MM-DD HH:mm:ss'),
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }
}
