import BaseBackComponent from './../base-back-component';

export default class InventorycashComponent extends BaseBackComponent {
  name = 'Inventorycash';

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
      date: null,
      inventoryNumber: null,
      state: null,
      depotId: null,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
  }

  async initPayload () {
    await this.setState ({
      formPayload: {
        amountCounntedByType: {
          value: [
            {type: {value: 'CASH'}, amountCounted: {value: '0'}},
            {type: {value: 'MOBILE MONEY'}, amountCounted: {value: '0'}},
            {type: {value: 'BANQUE'}, amountCounted: {value: '0'}},
          ],
        },
      },
    });
  }

  async resetPayload () {
    await this.setState ({
      singleData: {},
    });
    await this.initPayload ();
  }
}
