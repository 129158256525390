import React,{ useRef} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./style.css";
import mainTheme from '../../../../../configs/mainTheme';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';


export default function SingleInventory(props){
    const componentRef = useRef();
    const onPrint =   useReactToPrint({
        content : () => componentRef.current
    });

    let articles = []
    const inventoryInformation = props?.data?.rows;

    if(inventoryInformation){
        articles = inventoryInformation?.inventoryArticleList;
    }

    return  <div className="depot-content">
                 <div className="d-flex mt-2 mx-2">
                    <Button
                        variant="outlined"
                        sx={{color: 'white', bgcolor: 'green', mr: 1}}
                        startIcon={<FontAwesomeIcon icon="print" />}
                        onClick={onPrint}
                    >
                            Imprimer
                        </Button>  
                </div>
                <div ref={componentRef} className="mt-2 p-2">
                    <div className="depot-content">
                        <div className="p-3 depot-title text-white fw-bold">
                            FICHE INVENTAIRE : {inventoryInformation?.depot?.name?.toUpperCase() || ''} du 
                            {` ${ moment(inventoryInformation?.inventoryDate).format("DD/MM/YYYY") }`}
                        </div>
                    
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight:"bold" }}>#</TableCell>
                                        <TableCell sx={{ fontWeight:"bold" }}>Article</TableCell>
                                        <TableCell sx={{ fontWeight:"bold" }}>Quantité Théorique</TableCell>
                                        <TableCell sx={{ fontWeight:"bold" }}>Quantité comptée</TableCell>
                                        <TableCell sx={{ fontWeight:"bold" }}>Ecart</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        articles?.map((row,index) => (
                                            <TableRow
                                                key={row.article?.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="td" scope="index">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="td" scope="articleName">
                                                    {row.article?.name}
                                                </TableCell>
                                                <TableCell>{row?.qtyDepot}</TableCell>
                                                <TableCell>{row?.qtyCount}</TableCell>
                                                <TableCell>{row?.difference}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="d-flex justify-content-center image-text border-top">
                        <span className="image">
                            <img src={mainTheme.appLogo} alt="logo" className="logo" />
                        </span>

                        <div className="text logo-text">
                            <span className="name"> PIKADO</span>
                        </div>
                    </div>
                </div>
            </div>
}