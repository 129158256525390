import BaseBackComponent from '../base-back-component';

export default class AccountingcategoriesComponent extends BaseBackComponent {
  options = [
    {
      title: 'accountingtypes',
      keys: ['libelle'],
    },
  ];

  name = "Accountingcategories";

  state = {
    ...this.state,
    searchOptions : {
      ...this.state.searchOptions,
      libelle : null,
      accountingTypeId : null,
    }
  }

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    data = {
      ...data,
      accountingTpeId : {value : data?.accountingType?.value?.id}
    };
    return data;
  }
}
