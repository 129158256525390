import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const loopbackCashFlowFrameConfig = {
  name: 'loopbackcashflow',
  title: 'Bouclage trésorerie',
  typeFrame: 'list',
  fields: [],
  filterOptions: [],
};

export default BaseConfiguration (loopbackCashFlowFrameConfig);
