import DefaultQuery from '../../vendor/bases/default.query';

export default class CltinvoiceQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'client-invoices',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (
      this.buildPath ('invoice-list', 'customers'),
      params
    );
  }

  async readOne (id) {
    return await this.getMethod (this.buildPath (`invoice/${id}`, 'customers'));
  }
}
