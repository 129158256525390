import BaseBackScreen from '../../../base-back-screen';
import config from './config';
import SingleSellRender from './../../../Sells/_layouts/single/index';

export default class SalesScreen extends BaseBackScreen {
  name = 'sales';

  state = {
    ...this.state,
    config: config,
    singleComponent: SingleSellRender,
  };
}
