import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './frame-title.css';
import {Button, ButtonGroup, styled} from '@mui/material';
import {Fragment} from 'react';

export default function FrameTitleLinked (props) {
  const actions = props.actions || [];
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static">
        <Toolbar className="title">
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            {props.title.toUpperCase ()}
          </Typography>
          <div>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <HeaderActionRender
                activeBtnName={props.activeBtnName} 
                featureName={props.name} 
                handleActionClick={props.handleActionClick} 
                actions={actions} 
              />
            </ButtonGroup>

          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const HeaderActionRender = props => {

  return (
    <Fragment>
      {props.actions?.map (action => {
          if(action?.name !== props?.activeBtnName){
            return <Button
                    key={action.name}
                    variant="outlined"
                    className={`mx-1 text-white border-white`}
                    id={action.name}
                    name={action.name}
                    onClick={(e) => {props.handleActionClick(e,action?.name)}}
                >
                        {action.title}
                </Button>;
          }
          return <ActiveColorButton
                    key={action.name}
                    variant="contained"
                    id={action.name}
                    name={action.name}
                    onClick={(e) => {props.handleActionClick(e,action?.name)}}
                >
                        {action.title}
                </ActiveColorButton>;
       
      })}
    </Fragment>
  );
};

const ActiveColorButton = styled(Button)(({ theme }) => ({
    color: "#000039",
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
    },
}));
