import {Autocomplete, Card, CardContent, TextField} from '@mui/material';
import FrameTitle from '../../../vendor/builders/Frame/_layouts/title';
import { useState } from 'react';
import { LineChart } from '@mui/x-charts';

const currentYear = new Date ().getFullYear ();

const years = () => {
  let yearsCollection = [];
  for (let i = 2010; i <= currentYear; i++) {
    yearsCollection.push ({value: `${i}`,label : `${i}`});
  }
  return yearsCollection;
};


export default function RenderSellsevolution (props) {
  let renderConfigs = props.renderFrameConfigs;

  const elementIndex = years().findIndex(item => item.value  === currentYear)

  const [currentExercise,setCurrentExercise] = useState(elementIndex !== - 1 ? years()[elementIndex] : years()[years().length - 1]);


  const departments = renderConfigs?.options?.find(option => option.title === "departements")?.options || [];

  const changeYear = (e, value = null) => {
    if(value){
        renderConfigs.handleSearchOption(e,value?.value,"year");
    }else{
        renderConfigs.handleSearchOption(e,null,"year");
    }
    setCurrentExercise(value);
  }

  const changeDepartment = (e,value = null) => {
    if(value){
      renderConfigs.handleSearchOption(e,value?.value,"departementId");
    }else{
        renderConfigs.handleSearchOption(e,null,"departementId");
    }
    setDepartment(value);
  }


  const [department,setDepartment] = useState();

  return (
    <div >
       <FrameTitle
          name={renderConfigs?.name}
          title={renderConfigs?.title}
          openDialog={(e) => {}}
          actions={[]}
        />

        <div className="col-12 d-flex justify-content-start my-3">
            <Autocomplete
                id="year"
                options={years()}
                onChange={(e,value) => {
                    changeYear(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === currentExercise?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Exercice`} />}
                value={currentExercise}
            />
            <Autocomplete
                id="departementId"
                options={departments}
                onChange={(e,value) => {
                    changeDepartment(e,value)
                }}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={option => option.value === department?.value}
                size="small"
                sx={{ width:150,mr:1 }}
                renderInput={(params) => <TextField {...params} label={`Departement`} />}
                value={department}
            />
        </div>

        <Card sx={{ width:"100%", p:2 }}>
            <CardContent>
                <GraphArea data = {renderConfigs.fetchData}/>
            </CardContent>    
        </Card>
    </div>
  );
}

const GraphArea = props => {
    let xLabels = [];
    let data = [];  

    if(props.data){
      
      props.data.forEach(evolution => {
        xLabels.push(evolution.month);
        data.push(evolution.sellAmount);
      })
    }



     return  <LineChart
                height={350}
                yAxis={[
                  {
                    max:  Math.max(...data),
                  },
                ]}
                series={[
                  { data: data,type: 'line' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
              />
}