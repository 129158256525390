import './single.css';
import {useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import profile from '../../../../../assets/images/img.png';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';

const SingleArticleRender = props => {
  const articleInformation = props.data.rows;

  const componentRef = useRef ();
  

  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE ANALYSE ARTICLE #{articleInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={articleInformation?.image || profile} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(articleInformation?.article?.name || "")}</h5>
                            <small>Article PIKADO</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Departement</span>
                                    <span className="number1">
                                        {articleInformation?.article?.departement?.name}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                        <span className="articles">Famille</span>
                                        <span className="number2">
                                            {articleInformation?.article?.articleFamily?.name}
                                        </span>

                                </div>
                                <div className="d-flex flex-column">
                                    <span className="followers">Sous-famille</span>
                                    <span className="number3">
                                        {articleInformation?.article?.articleSubFamily?.name}

                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                        <hr/>

                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Code</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {articleInformation?.article?.code}        
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Coût de reviens</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {
                                  Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                                }).format(articleInformation?.purchasePrice)
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Prix de vente</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {
                                  Intl.NumberFormat('fr-FR',{ style: 'currency',
                                              currency: 'XOF',
                                              
                                                }).format(articleInformation?.sellingPrice)
                            }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Poids unitaire</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {
                               articleInformation?.article?.weight
                            }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Marge en valeur</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {
                               articleInformation?.rangeValue
                            }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Marge en pourcentage</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {
                              articleInformation?.rangePercentage
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleArticleRender;
