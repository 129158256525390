import './single.css';
import {useRef} from 'react';
import mainTheme from '../../../../configs/mainTheme';
import fiche from '../../../../assets/images/fiche.jpg';
import { capitalizeFirstLetter } from './../../../../bootstrap/Helpers/utils';
import moment from 'moment';

const SingleMoveRender = props => {
  const moveInformation = props.data.rows;

  let nature = null;

  let qty = moveInformation?.qtyMovement;

  if(qty < 0){
    qty = (-1) * qty;
  }

  switch(moveInformation?.typeMovement){
    case 0 : nature = "entrée"; break;
    case 2 : nature = "sortie"; break;
    case 4 : nature = "transfert"; break;
    case 1 : nature = "vendu"; break;
    case 3 : nature = "production"; break;
    default : break;
  }

  const componentRef = useRef ();
  

  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE DE MOUVEMENT DE STOCK #{moveInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(moveInformation?.movementNumber?.toUpperCase() || "")}</h5>
                            <small>Mouvement de stock</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Nature du mouvement</span>
                                    <span className="number1">
                                        {nature?.toUpperCase() || ""}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Date</span>
                                    <span className="number1">
                                        {moment(moveInformation?.dateMovement).format("DD/MM/YYYY")}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Depot d'origine</span>
                                    <span className="number1">
                                        {moveInformation?.origin?.toUpperCase() || ""}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                        <hr/>

                        </div>
                       
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Article</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {moveInformation?.article?.name}        
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Stock sortie</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {qty +" "+ moveInformation?.article?.unit?.libelle}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Motif</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {moveInformation?.libelle || "Non fourni"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleMoveRender;
