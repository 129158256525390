import {Autocomplete, TextField} from '@mui/material';

const StockAddComponent = props => {


  let depots = props.data?.data?.depots || [];
  let depot = props.data?.data?.stockAddForm?.payLoad?.depotId;   
  let initialQty = props.data?.data?.stockAddForm?.payLoad?.initialQty;   

    const changeDepotValue = (event,value) => {
        props.data?.data?.stockAddForm?.handleForm(event,"depotId",value?.value)
    } 

  return (
    <div className="article-content">
      <div className="p-2 text-center form-title bg-primary text-white">
        <h4>CREATION DE STOCK</h4>
      </div>
      <div className="row mt-3 p-2">
        <div className="col-12 col-md-6 col-xl-6 col-lg-6  my-lg-2 my-xl-2 my-md-2">
          <Autocomplete
            id="depotId"
            name ="depotId"
            options={depots}
            sx={{width: '100%'}}
            onChange={changeDepotValue}
            renderInput={params => <TextField {...params} label="Depot" />}
            error = {depot?.errors && depot?.errors.length > 0}
            helperText={depot?.errors || null}
            value={depot?.value}
          />
        </div>
        <div className="col-12 col-md-6 col-xl-6 col-lg-6  my-lg-2 my-xl-2 my-md-2">
          <TextField
            error = {initialQty?.errors && initialQty?.errors?.length > 0}
            id="initialQty"
            name ="initialQty"
            label="Quantité initial"
            defaultValue = {initialQty?.value}
            helperText={initialQty?.errors || null}
            sx={{width: '100%'}}
            type="number"
            onChange={props.data?.data?.stockAddForm?.handleForm}
          />
        </div>
      </div>
      <div className="container d-flex justify-content-end p-2">
        <button
          onClick={e => {
            props.data?.data?.stockAddForm?.submitForm (e, props.data?.data?.articleId);
          }}
          type="button"
          className="btn btn-block btn-primary"
        >
          Créer stock
        </button>
      </div>
    </div>
  );
};

export default StockAddComponent;
