const fieldsCredentials = [
  {
    name: 'profileImageUrl',
    type: 'user-avatar',
    title: 'Photo de profile',
  },
  {
    name: 'role',
    htmlElement: 'select',
    options: [],
    multiple: true,
    dataServiceSource: 'roles',
    selectDataValue: ['name'],
  },
  {
    name: 'roles',
    title: 'rôle(s)',
    htmlElement: 'select',
    fieldToTake: 'roleIds',
    options: [],
    multiple: true,
    dataServiceSource: 'roles',
    selectDataValue: {
      name: 'roles',
      options: {
        valueOptions: ['id'],
        labelOptions: ['name'],
      },
    },
  },
  {
    name: 'firstName',
    title: 'nom(s)',
    col: 6,
  },
  {
    name: 'lastName',
    title: 'prenom(s)',
    col: 6,
  },
  {
    name: 'email',
    type: 'email',
    col: 6,
  },
  {
    name: 'tel',
    title: 'Contact',
    type: 'phone',
    col: 6,
  },
  {
    name: 'isActive',
    title: 'Est actif?',
    type: 'checkbox',
    options: {
      0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
      1: {badgeColor: '#5F9EA0', text: 'Actif'},
    },
  },
  {
    name: 'active',
    title: 'Est actif?',
    type: 'checkbox',
    options: {
      0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
      1: {badgeColor: '#5F9EA0', text: 'Actif'},
    },
  },
];

export {fieldsCredentials};
