import { Fragment } from 'react';
import BaseBackComponent from '../base-back-component';

export default class OldSellsComponent extends BaseBackComponent {
  name = 'Sells';

  options = [
    {
      title: 'users',
      keys: ['firstName', 'lastName'],
    },
    {
      title : 'clients',
      keys: ['firstName', 'lastName'],
    },
    {
      title : 'registers',
      source : "cashregisters",
      keys : ["reference"]
    },
    {
      title: 'products',
      keys: ['articleName'],
      optionValue: 'articleId',
      dataToGetForGroupTitle: ['subFamilyName'],
      imgTarget: 'image',
      source : "articles",
      method: 'articlesWithPricing',
    },
  ];

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  async initPayload () {

    const clients  = this.state.options.find(option => option.title === "clients");

    const clientDivers = clients.options.find(client => client.label.toLowerCase().includes("divers"));

    await this.setState ({
      formPayload: {
        currency: {value: "0"},
        totalDeliverCost: {value: "0"},
        totalCost: {value: "0"},
       // tvaValue: {value: "0",maxValue : "100"},
       // discount: {value: "0"},
        totalProductsCost : {value : "0"},
        paymentType : {value : "CASH"},
        waiting:{value : "0"},
        customerId : {value : clientDivers.value}
      },
    });
  }

  async resetPayload () {
    await this.setState ({
      singleData: {},
    });
    await this.initPayload ();
  }

  async otherHandleFieldTreatment(name,value){
    if(name === "discount" || name === "tvaValue" || name === "amountPaid"){
      this.amountActualize({[name] : value});
    }

    if(name === "paymentType"){

      await this.setState(
        (state) => ({
          formPayload : {
            ...state.formPayload,
            amountPaid : {
              ...state.formPayload.amountPaid,
              disable : value?.toUpperCase() === "CREDIT"
            },
          }
      }));
    }
  }

  async amountActualize(params){


    let amountPaid = params?.amountPaid || this.state.formPayload.amountPaid?.value;
    //let tvaValue = params?.tvaValue || this.state.formPayload.tvaValue?.value;
    //let discount = params?.discount || this.state.formPayload.discount?.value;
    let totalProductsCost = params?.totalProductCost || this.getAllArticleCost();

  

   // let totalCost = parseFloat(totalProductsCost) + parseFloat(totalProductsCost) * parseFloat(tvaValue) / 100 - parseFloat(discount);
    
   let totalCost = parseFloat(totalProductsCost);
   
    let currency = amountPaid - totalCost;

    

    await this.setState({
      formPayload : {
        ...this.state.formPayload,
        currency : {value : currency > 0 ? currency : "0"},
        totalSellCost : {value : `${totalCost}`},
      }
    });

  }

  getAllArticleCost(){
    let oldCost = 0;
    this.state.formPayload?.articleSoldList?.value.forEach(
      item => {
        oldCost += item?.cost?.value
      }
    )
    return oldCost;
  }

  async handleSubForm (index, name, value, currentPayLoad = null) {


    let totalCost = 0;
    if (name === 'articleId') {
      if(value && value !== ""){
       
        const singleArticle = this.state.originalOptionsData?.articles?.find(article => article.articleId === value);

        currentPayLoad[index].qty = {value : 1};
        currentPayLoad[index].cost = {value : singleArticle?.sellingPrice ?? 0};
        currentPayLoad[index].unitPrice = {value : singleArticle?.sellingPrice ?? 0};
      
      }
    }
    
    if(name === 'qty'){
     const newCost  = value * currentPayLoad[index]?.unitPrice?.value;
     currentPayLoad[index].cost = {value : newCost};
    }

    currentPayLoad.forEach(
      item => {
        totalCost += item?.cost?.value;
      }
    )


    await this.setState(
      (state) => ({
        totalCost : totalCost,
        formPayload : {
          ...state.formPayload,
          articleSoldList : {value : currentPayLoad},
          totalCost : {value : totalCost } 
        }
    }));

   
    this.amountActualize({totalProductCost : totalCost});
  }

  otherSingleDataInit(data){

    const articleList = data?.articleSoldList.value.map(
      article => {
        return {
          articleId : {value : article.article.id},
          qty : {value : article.qty},
          cost : {value : article?.unitPrice * article?.qty},
        }
      }
    )   

    data = {
      ...data,
      customerId : {value : data?.customer
        ?.value?.id},
      depotId : {value : data?.depot?.value?.id},
      articleSoldList : {value : articleList},
      waiting:{value : "1"},

    };


    return data;
  }

  
  afterSubmitAction(data){}

  render () {
    const RenderScreen = this.loadScreen ();

    return <Fragment> 
              <RenderScreen data={this.state} />;
          </Fragment>
  }
}
