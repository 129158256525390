import DefaultQuery from '../../vendor/bases/default.query';

export default class SuppliersQuery extends DefaultQuery {
  constructor (params = null) {
    super ({
      ...params,
      path: 'suppliers',
    });
  }

  async readAll (params = null) {
    return await this.getMethod (this.buildPath ('list'), params);
  }

  async suppliersWithBalance () {
    return await this.getMethod (this.buildPath ('with-balance'));
  }

  async getUnpaidInvoices (supplierId) {
    return await this.getMethod (this.buildPath (`invoice-unpaid-list/${supplierId}`));
  }


  async invoicesList(options){
    return await this.getMethod (this.buildPath ('invoice-list'), options);
  }

  async invoiceDetail (invoiceId) {
    return await this.getMethod (this.buildPath (`invoice/${invoiceId}`));
  }
}
