import Service from '../../vendor/bases/service';

export default class DepotsService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'depots',
    });
  }
  getArticles = async depotId => {
    return await this.treatQuery (this.query.getArticles (depotId));
  };

  async finishedProductDepots () {
    return await this.treatQuery (this.query.finishedProductDepots ());
  }

  async rawMaterialDepots () {
    return await this.treatQuery (this.query.rawMaterialDepots ());
  }

  async loadDepotArticleQtyAvailable (articleId) {
    return await this.treatQuery (
      this.query.loadDepotArticleQtyAvailable (articleId)
    );
  }
}
