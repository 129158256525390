import './single.css';
import {useRef} from 'react';
import mainTheme from '../../../../../configs/mainTheme';
import profile from '../../../../../assets/images/img.png';
import { capitalizeFirstLetter } from './../../../../../bootstrap/Helpers/utils';

const SingleArticleRender = props => {


  const articleInformation = props.data.rows;

  const componentRef = useRef ();
  

  return (
    <div className="article-card-information">
      
        <div ref={componentRef} className="mt-2">
            <div className="article-content">
                <div className="p-2 text-center text-white article-title">
                    <h4>FICHE ARTICLE #{articleInformation?.id}</h4>
                </div>
                <div className="card p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={articleInformation?.image || profile} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{capitalizeFirstLetter(articleInformation?.name || "")}</h5>
                            <small>Article PIKADO</small>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Departement</span>
                                    <span className="number1">
                                        {articleInformation?.departement?.name}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                        <span className="articles">Famille</span>
                                        <span className="number2">
                                            {articleInformation?.articleFamily?.name}
                                        </span>

                                </div>
                                <div className="d-flex flex-column">
                                    <span className="followers">Sous-famille</span>
                                    <span className="number3">
                                        {articleInformation?.articleSubFamily?.name}

                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                        <hr/>

                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Code</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {articleInformation?.code}        
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Stock alerte</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                                {articleInformation?.alertStock +" "+ articleInformation?.unit?.libelle}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Stockage maximum</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {articleInformation?.maxToStock + " "+ articleInformation?.unit?.libelle}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                            <h6 className="mb-0">Unité de mesure</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                            {
                               articleInformation?.unit?.libelle
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center image-text border-top">
              <span className="image">
                <img src={mainTheme.appLogo} alt="logo" className="logo" />
              </span>

              <div className="text logo-text">
                <span className="name"> PIKADO</span>
              </div>
            </div>
        </div>
    </div>
  );
};

export default SingleArticleRender;
